import { CommonService, OmsService } from "@sk/services";
import {
  AppCard,
  DateFormatter,
  HighlightText,
  InfoBlk,
  PageInfo,
  PageLoader,
  Tabs,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import OrderDetailsBasic from "./components/OrderDetailsBasic";
import OrderDetailsItems from "./components/OrderDetailsItems";
import OrderDetailSummary from "./components/OrderDetailSummary";

const OrderDetails = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("id");

  const [data, setData] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const loadOrderDetails = async () => {
    setLoadingData(true);
    const response = await getOrderDetails(orderId);

    const formattedItems = response.resp?.subOrders.map((item) => ({
      _id: item._id || "",
      allowReserveOrder: item.allowReserveOrder || false,
      name: item.name || "",
      id: item.id || "",
      quantity: item.quantity || 0,
      mrp: item.mrp || 0,
      b2bPrice: item.b2bPrice || 0,
      couponDisc: item.couponDisc || 0,
      commission: {
        amount: item.commission?.amount || 0,
      },
      price: item.price || 0,
      statusType: item.statusType || "success",
      status: item.status || "",
      reserveShippingDate: item.reserveShippingDate || null,
      invoiceNo: item.invoiceNo || "",
      products: item.products || [
        {
          name: "",
          id: "",
        },
      ],
      category: item.category || {},
      brand: item.brand || {},
      reservationStatus: item.reservationStatus || "",
    }));

    setData({
      ...response.resp,
      formattedItems,
      basicData: {
        totalItems: response.resp?.subOrders?.length,
        totalAmount: response.resp?.orderAmount,
        orderAmount: response.resp?.orderAmount,
        orderType: response.resp?.orderType,
        typeOfOrder: response.resp?.typeOfOrder,
        platformType: response.resp?.platformType,
        paymentMode: response.resp?.paymentMode,
        paymentStatus: response.resp?.paymentStatus,
        fulfilledBy: response.resp?.fulfilledBy,
        providedBy: response.resp?.providedBy,
        allocationStatus: response.resp?.allocationStatus,
        commission: response.resp?.commission || {},
        franchise: response.resp?.franchise || {},
        billingAddress: response.resp?.billingAddress || {},
        warehouseAddress: response.resp?.warehouseAddress || {},
        stockAllocation: response.resp?.stockAllocation || {},
        walletType: response.resp?.walletType || "",
      },
    });
    setLoadingData(false);
  };

  const downloadItems = () => {
    const csvData =
      data?.formattedItems.map((item) => ({
        id: item._id,
        name: item.name,
        quantity: item.quantity,
        mrp: item.mrp,
        b2bPrice: item.b2bPrice,
        couponDisc: item.couponDisc,
        commission: item.commission?.amount,
        priceAfterDisc: item.price - item.couponDisc,
        total: item.price * item.quantity,
        status: item.status,
        reservationStatus: item.reservationStatus,
        reserveShippingDate: item.reserveShippingDate,
        invoiceNo: item.invoiceNo,
        productName: item.products[0]?.name,
        productId: item.products[0]?.id,
        category: item.category?.name,
        brand: item.brand?.name,
      })) || [];

    const header = [
      { name: "ID", value: "id" },
      { name: "Name", value: "name" },
      { name: "Quantity", value: "quantity" },
      { name: "MRP", value: "mrp" },
      { name: "B2B Price", value: "b2bPrice" },
      { name: "Coupon Disc.", value: "couponDisc" },
      { name: "Commission", value: "commission" },
      { name: "B2B Price After Disc.", value: "priceAfterDisc" },
      { name: "Total", value: "total" },
      { name: "Status", value: "status" },
      { name: "Stock Allocated", value: "reservationStatus" },
      { name: "Reserve Shipping Date", value: "reserveShippingDate" },
      { name: "Invoice No", value: "invoiceNo" },
      { name: "Product Name", value: "productName" },
      { name: "Product ID", value: "productId" },
      { name: "Category", value: "category" },
      { name: "Brand", value: "brand" },
    ];

    CommonService.generateCsv(csvData, header, "Order_Items");
  };

  useEffect(() => {
    if (orderId) {
      loadOrderDetails();
    } else {
      setLoadingData(false);
    }
  }, [orderId]);

  const tabCb = (data) => {
    setActiveTab(data.value);
  };

  if (loadingData) {
    return <PageLoader />;
  }

  if (!data?._id) {
    return <InfoBlk variant="danger">No data found</InfoBlk>;
  }

  return (
    <>
      <div className="my-3">
        <PageInfo
          title="Order Details"
          noMargin={true}
          breadcrumbs={breadcrumb}
          navigate={navigate}
        />
      </div>
      <AppCard>
        <div className="p-2">
          <div className="row bg-light py-2 px-3 mb-3 border-bottom">
            <div className="col">
              <div className="mb-1">
                <span className="fs-val-xl fw-bold">#{data?._id}</span>
              </div>
              <div className="fs-val-sm row gap-2">
                <div className="col-auto">
                  <span className="text-muted">Order Date: </span>
                  <DateFormatter date={data?.createdAt} />
                </div>
                <div className="col-auto">
                  <span className="text-muted">Total Items: </span>
                  {data?.formattedItems?.length}
                </div>
                <div className="col-auto">
                  <HighlightText type="info" template={2}>
                    <span className="text-uppercase">{data?.typeOfOrder}</span>
                  </HighlightText>
                </div>
              </div>
            </div>
            <div className="col-auto align-self-center">
              <span className="text-muted">Order Status: </span>
              <HighlightText type={data?.statusType} template={2}>
                <span className="text-uppercase fw-bold">{data?.status}</span>
              </HighlightText>
            </div>
          </div>
          <div className="border-bottom mb-4">
            <Tabs
              data={tabs}
              activeTab={activeTab}
              template={4}
              callback={tabCb}
            />
          </div>

          {activeTab?.key === "basic" && (
            <OrderDetailsBasic data={data?.basicData} />
          )}
          {activeTab?.key === "items" && (
            <div>
              <div className="row mb-3">
                <div className="col align-self-end">
                  <span className="fw-bold">Order Summary</span>
                </div>
                <div className="col text-end">
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={downloadItems}
                  >
                    Download Items
                  </button>
                </div>
              </div>

              <OrderDetailSummary summary={data?.statusSummary} />

              <OrderDetailsItems data={data?.formattedItems} />
            </div>
          )}
        </div>
      </AppCard>
    </>
  );
};

const breadcrumb = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Orders",
    link: "/oms/orders",
  },
  {
    name: "Order Details",
    link: "/oms/orders/details",
  },
];

const tabs = [
  {
    key: "basic",
    tabName: "Basic",
  },
  {
    key: "items",
    tabName: "Items",
  },
];

const getOrderDetails = (id) => {
  return OmsService.getOrderDetails(id);
};

export default OrderDetails;
