import React from "react";
import { Amount, AppCard, TableHeader } from "@sk/uis";

const headers = [
  { label: "0-7 Days", key: "0-7", width: "25%", isCentered: true },
  { label: "8-15 Days", key: "8-15", width: "25%", isCentered: true },
  { label: "16-30 Days", key: "16-30", width: "25%", isCentered: true },
  { label: "30+ Days", key: "30+", width: "25%", isCentered: true },
];

const OrderReserveAgeing = ({ ageingData, loading }) => {
  const renderLoadingPlaceholder = () => (
    <div className="placeholder-glow">
      <span className="placeholder col-8"></span>
    </div>
  );

  return (
    <AppCard title="Ageing Summary">
      <div className="tbl-scroll-container custom-scrollbar thin-scrollbar">
        <table className="table table-sm table-bordered mb-0 bg-white">
          <TableHeader data={headers} fw="medium" />
          <tbody>
            <tr>
              {/* Order Count Row */}
              {headers.map((header) => (
                <td key={header.key} className="text-center">
                  <div className="d-flex flex-column">
                    <span className="fs-val-md fw-medium">
                      {loading
                        ? renderLoadingPlaceholder()
                        : ageingData[header.key]?.count || 0}
                    </span>
                    <div className="text-muted fs-val-sm">Orders</div>
                  </div>
                </td>
              ))}
            </tr>
            <tr>
              {/* Order Value Row */}
              {headers.map((header) => (
                <td key={header.key} className="text-center">
                  <div className="d-flex flex-column">
                    <span
                      className={`fs-val-md fw-medium ${getValueColor(
                        header.key
                      )}`}
                    >
                      {loading ? (
                        renderLoadingPlaceholder()
                      ) : (
                        <Amount value={ageingData[header.key]?.value || 0} />
                      )}
                    </span>
                    <div className="text-muted fs-val-sm">Value</div>
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

const getValueColor = (key) => {
  switch (key) {
    case "0-7":
      return "text-info";
    case "8-15":
      return "text-warning";
    case "16-30":
      return "text-danger";
    case "30+":
      return "text-dark";
    default:
      return "text-dark";
  }
};

export default OrderReserveAgeing;
