import { CommonService, OmsService } from "@sk/services";
import { Amount, AppCard } from "@sk/uis";
import { cloneDeep, merge } from "lodash";
import { memo, useEffect, useRef, useState } from "react";
import { defaultStatusSummary } from "../constants";

const OrderSummary = ({ filter }) => {
  const abortCtrl = useRef(new AbortController());

  const [summaryObj, setSummaryObj] = useState({});

  useEffect(() => {
    const initialSummary = {};
    defaultStatusSummary.forEach((item) => {
      initialSummary[item.label] = {
        loading: true,
        skuCount: 0,
        totalUnits: 0,
        totalValue: 0,
      };
    });
    setSummaryObj(initialSummary);
  }, []);

  useEffect(() => {
    abortCtrl.current.abort();

    abortCtrl.current = new AbortController();

    const fetchData = async () => {
      const totalResp = await OmsService.getOrderAnalyticsSummary(
        {
          ...getParams(filter),
          bytotal: true,
        },
        {
          signal: abortCtrl.current.signal,
        }
      );

      const res = await OmsService.getOrderAnalyticsSummary(getParams(filter), {
        signal: abortCtrl.current.signal,
      });

      setSummaryObj((prevSummary) => {
        console.log("prevSummary", prevSummary);
        const updatedSummary = cloneDeep(prevSummary);
        Object.keys(updatedSummary).forEach((key) => {
          if (key === "All") {
            const allData = totalResp?.resp?.[0] || {};
            updatedSummary[key].skuCount = allData.totalItems || 0;
            updatedSummary[key].totalUnits = allData.totalUnits || 0;
            updatedSummary[key].totalValue = allData.total || 0;
            updatedSummary[key].loading = false;
          } else {
            const matchingItems = res?.resp.filter((item) => {
              if (key === "Allocated") {
                return (
                  item._id.status === "Not Packed" &&
                  (item._id.stockStatus === "Allocated" ||
                    item._id.stockStatus === "Partially Allocated")
                );
              } else if (key === "Not Allocated") {
                return (
                  item._id.status === "Not Packed" &&
                  item._id.stockStatus === "Not Allocated"
                );
              } else {
                return item._id.status === key;
              }
            });

            const totals = {
              total: 0,
              totalItems: 0,
              totalRemainingQty: 0,
              totalRemainingValue: 0,
              totalUnits: 0,
            };

            matchingItems.forEach((item) => {
              totals.total += item.total || 0;
              totals.totalItems += item.totalItems || 0;
              totals.totalRemainingQty += item.totalRemainingQty || 0;
              totals.totalRemainingValue += item.totalRemainingValue || 0;
              totals.totalUnits += item.totalUnits || 0;
            });

            if (key === "Not Allocated") {
              updatedSummary[key].skuCount = totals.totalItems;
              updatedSummary[key].totalUnits = totals.totalRemainingQty;
              updatedSummary[key].totalValue = totals.totalRemainingValue;
            } else {
              updatedSummary[key].skuCount = totals.totalItems;
              updatedSummary[key].totalUnits = totals.totalUnits;
              updatedSummary[key].totalValue = totals.total;
            }
            updatedSummary[key].loading = false;
          }
        });
        return updatedSummary;
      });
    };

    if (Object.keys(filter).length) {
      fetchData();
    }
  }, [filter]);

  return (
    <AppCard title="Order Summary">
      <table className="table table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th></th>
            <th className="text-center">Total</th>
            <th className="text-center">Allocated</th>
            <th className="text-center">Not Allocated</th>
            <th className="text-center">Packed</th>
            <th className="text-center">Not Packed</th>
            <th className="text-center">Shipped</th>
            <th className="text-center">Delivered</th>
            <th className="text-center">Cancelled</th>
            <th className="text-center">Returned</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Items</td>
            <td className="text-center">
              {summaryObj["All"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(summaryObj["All"]?.skuCount ?? 0)}
            </td>
            <td className="text-center">
              {summaryObj["Allocated"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Allocated"]?.skuCount ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Not Allocated"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Not Allocated"]?.skuCount ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Packed"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Packed"]?.skuCount ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Not Packed"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Not Packed"]?.skuCount ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Shipped"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Shipped"]?.skuCount ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Delivered"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Delivered"]?.skuCount ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Cancelled"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Cancelled"]?.skuCount ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Returned"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Returned"]?.skuCount ?? 0
                  )}
            </td>
          </tr>
          <tr>
            <td>Qty</td>
            <td className="text-center">
              {summaryObj["All"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["All"]?.totalUnits ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Allocated"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Allocated"]?.totalUnits ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Not Allocated"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Not Allocated"]?.totalUnits ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Packed"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Packed"]?.totalUnits ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Not Packed"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Not Packed"]?.totalUnits ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Shipped"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Shipped"]?.totalUnits ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Delivered"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Delivered"]?.totalUnits ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Cancelled"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Cancelled"]?.totalUnits ?? 0
                  )}
            </td>
            <td className="text-center">
              {summaryObj["Returned"]?.loading
                ? "Loading..."
                : CommonService.commaSeparate(
                    summaryObj["Returned"]?.totalUnits ?? 0
                  )}
            </td>
          </tr>
          <tr>
            <td>Value</td>
            <td className="text-center">
              {summaryObj["All"]?.loading ? (
                "Loading..."
              ) : (
                <Amount value={summaryObj["All"]?.totalValue ?? 0} />
              )}
            </td>
            <td className="text-center">
              {summaryObj["Allocated"]?.loading ? (
                "Loading..."
              ) : (
                <Amount value={summaryObj["Allocated"]?.totalValue ?? 0} />
              )}
            </td>
            <td className="text-center">
              {summaryObj["Not Allocated"]?.loading ? (
                "Loading..."
              ) : (
                <Amount value={summaryObj["Not Allocated"]?.totalValue ?? 0} />
              )}
            </td>
            <td className="text-center">
              {summaryObj["Packed"]?.loading ? (
                "Loading..."
              ) : (
                <Amount value={summaryObj["Packed"]?.totalValue ?? 0} />
              )}
            </td>
            <td className="text-center">
              {summaryObj["Not Packed"]?.loading ? (
                "Loading..."
              ) : (
                <Amount value={summaryObj["Not Packed"]?.totalValue ?? 0} />
              )}
            </td>
            <td className="text-center">
              {summaryObj["Shipped"]?.loading ? (
                "Loading..."
              ) : (
                <Amount value={summaryObj["Shipped"]?.totalValue ?? 0} />
              )}
            </td>
            <td className="text-center">
              {summaryObj["Delivered"]?.loading ? (
                "Loading..."
              ) : (
                <Amount value={summaryObj["Delivered"]?.totalValue ?? 0} />
              )}
            </td>
            <td className="text-center">
              {summaryObj["Cancelled"]?.loading ? (
                "Loading..."
              ) : (
                <Amount value={summaryObj["Cancelled"]?.totalValue ?? 0} />
              )}
            </td>
            <td className="text-center">
              {summaryObj["Returned"]?.loading ? (
                "Loading..."
              ) : (
                <Amount value={summaryObj["Returned"]?.totalValue ?? 0} />
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </AppCard>
  );
};

const getParams = (filter) => {
  let p = merge({}, filter, { outputType: "count" });
  delete p.page;
  delete p.count;
  delete p.sort;
  return p;
};

export default memo(OrderSummary);
