import { useFetchUrlQueryString } from "@sk/hooks";

import { OmsService, ProductService } from "@sk/services";

import produce from "immer";

import { useCallback, useEffect, useMemo, useState } from "react";

import {
  Amount,
  DateFormatter,
  NoDataFound,
  PageInfo,
  PageLoader,
  Toaster,
  Alert,
} from "@sk/uis";

import { useNavigate, useSearchParams } from "react-router-dom";

import {
  OmsPrintInvoiceTemplate,
  OmsPrintOrderInvoiceTemplate,
  OmsPrintProductWisePackageList,
} from "@sk/features";

import { invoiceView } from "../constantService";
import { BusyLoader } from "@sk/uis";

const getInvoiceData = async (invoiceId) => {
  let params = {
    filter: {
      _id: invoiceId,
    },
  };
  const r = await OmsService.getInvoiceList(params);
  let t = r.resp[0];

  let packages = t?.deals?.reduce((acc, deal) => {
    return [...acc, ...deal?.products.flatMap((product) => product?.packages)];
  }, []);

  let panNo = t?.soldBy?.gstno.slice(2, 12); // pan is  10 digits after first 2 of GST

  packages = Array.from(new Set(packages));

  return Array.isArray(r.resp)
    ? { ...t, _packages: packages, _panNo: panNo }
    : {};
};

const doPrint = (barcode) => {
  const w = window.open("", "_blank");
  let template = `
  <html>
    <body>
      <div style="text-align:center;" ><img src=${barcode}  /></div>
    </body>
  </html>`;
  w.document.title = "Print Barcode";

  w.document.write(template);

  const t = setTimeout(() => {
    clearTimeout(t);
    w.print();
    w.close();
  }, 2000);
};

const getOrderData = async (orderId) => {
  let params = {
    filter: {
      _id: orderId,
    },
  };
  const r = await OmsService.getOrderList(params);
  return Array.isArray(r.resp) ? r.resp[0] : {};
};

const getInvoiceBarcode = async (invoiceId, dimensions = {}) => {
  const r = await ProductService.getInvoiceBarcode(invoiceId, dimensions);
  return r.statusCode != 200 ? {} : { _id: r.resp.data };
};

const TaxTemplateRow = ({ taxDetails }) => {
  let tdContent = `Total ${taxDetails?.taxType} collected `;

  if (taxDetails?.taxPerc) {
    tdContent += `@${taxDetails?.taxPerc} on `;
  }
  return (
    <tr>
      <td>
        {tdContent}
        {taxDetails?.taxPerc ? (
          <Amount value={taxDetails?.taxableOnAmount} decimalPlace={2} />
        ) : (
          ""
        )}
      </td>
      <td className="text-center">
        <Amount value={taxDetails?.taxableAmount} decimalPlace={2} />
      </td>
    </tr>
  );
};

const breadcrumbs = invoiceView.breadcrumbs;

const ViewInvoice = () => {
  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const [pageData, setPageData] = useState({
    invoice: {},
    order: {},
    barcodeImage: "",
  });

  const navigate = useNavigate();

  const [display, setDisplay] = useState("loading");

  const [busyLoader, setShowBusyLoader] = useState(false);

  const address = useMemo(() => {
    const { billingAddress, shippingAddress } = pageData?.invoice;

    let address = [
      { value: billingAddress, key: "billingAddress" },
      { value: shippingAddress, key: "shippingAddress" },
    ].reduce((acc, x) => {
      let t = {
        landmark: x?.value?.landmark,
        line1: x?.value?.line1,
        line2: x?.value?.line2,
        fullAddress: x?.value?.fullAddress,
        pincode: x?.value?.pincode,
      };

      let tmpValues = Object.values(t);

      acc[x.key] = tmpValues.filter((p) => p).join(",");

      return acc;
    }, {});

    return address;
  }, [pageData]);

  useEffect(() => {
    if (query.id) {
      loadDetails(query.id);
    } else {
      setDisplay("NoDataFound");
    }
  }, [query.id, loadDetails]);

  const isValidResp = useCallback((resp, msg) => {
    if (!resp._id) {
      Toaster.error(msg);
      return false;
    }
    return true;
  }, []);

  const printBarcode = useCallback(async () => {
    setShowBusyLoader(true);
    let dimensions = {
      length: 128,
      breath: 50,
      height: 1,
    };
    const r = await getInvoiceBarcode(query.id, dimensions);

    setShowBusyLoader(false);

    let errorMsg = "Cannot get Barcode at this moment";

    if (!isValidResp(r, errorMsg)) return;

    let barcode = r._id;

    doPrint(barcode);
  }, [query.id, isValidResp]);

  const updateStatus = useCallback(
    async (status = "New") => {
      const r = await Alert.confirm({
        title: "Remove Product",
        text: `Do you want to mark invoice #${query.id} as ${status}? `,
        okText: "Yes",
        cancelText: "No",
      });

      if (!r.isConfirmed) {
        return;
      }
      setShowBusyLoader(true);

      let p = {};

      if (status == "New") {
        p = await OmsService.revertInvoice(query.id);
      } else {
        p = await OmsService.updateInvoice(query.id);
      }

      if (p.statusCode == 200) {
        setPageData(
          produce((draft) => {
            draft.invoice.status = status;
          })
        );
        Toaster.success(p.resp.message);
      } else {
        Toaster.error(p.message);
      }
      setShowBusyLoader(false);
    },
    [query.id]
  );

  const printInvoice = useCallback(
    (type = "invoice", withMrp = false) => {
      const data = {
        address,
        invoice: pageData.invoice,
        order: pageData.order,
        images: {
          barcode: pageData.barcodeImage,
          logo: invoiceView.logo,
          airavatImg: invoiceView.img,
        },
      };
      if (type == "invoice") {
        OmsPrintInvoiceTemplate.doPrint(data, withMrp);
      }

      if (type == "orderInvoice") {
        OmsPrintOrderInvoiceTemplate.doPrint(data);
      }

      if (type == "package") {
        OmsPrintProductWisePackageList.doPrint(data);
      }
    },
    [pageData, address]
  );

  const loadDetails = useCallback(
    async (invoiceId) => {
      setDisplay("loading");

      let invoiceErrorMsg = "No Invoice found, Please try again";
      const barcodeDimension = {
        length: 400,
        breath: 60,
        height: 2,
      };
      const promises = [
        getInvoiceData(invoiceId),
        getInvoiceBarcode(invoiceId, barcodeDimension),
      ];

      const [r, br] = await Promise.all(promises);

      if (
        !(isValidResp(r, invoiceErrorMsg) && isValidResp(br, invoiceErrorMsg))
      )
        return setDisplay("NoDataFound");

      const orderId = r.orderId;

      const or = await getOrderData(orderId);

      if (!isValidResp(or, "No Order Found")) return setDisplay("noDataFound");

      setPageData(
        produce((draft) => {
          draft.invoice = r;
          draft.order = or;
          draft.barcodeImage = br._id;
        })
      );

      setDisplay("details");
    },
    [isValidResp]
  );

  const billingDetails = pageData?.invoice?.billingAddress;

  const shippingDetails = pageData?.invoice?.shippingAddress;

  const govInfo = pageData?.invoice?.govInfo;

  return (
    <>
      <PageInfo
        title={`View Invoice #${query.id}`}
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      {display == "loading" && <PageLoader />}

      {display == "noDataFound" && <NoDataFound> No Invoice Found</NoDataFound>}

      {display == "details" && (
        <>
          <div className="row g-2 justify-content-end mb-4">
            <div className="col-auto">
              <button
                className="btn btn-primary fs-val-md btn-xs fw-semibold"
                onClick={() => printInvoice("orderInvoice")}
              >
                Print Order V/s Invoice
              </button>
            </div>

            <div className="col-auto">
              <button
                className="btn btn-primary fs-val-md btn-xs fw-semibold"
                onClick={() => printInvoice("invoice")}
              >
                Print Invoice
              </button>
            </div>

            <div className="col-auto">
              <button
                className="btn btn-primary fs-val-md btn-xs fw-semibold"
                onClick={() => printInvoice("package")}
              >
                Print Consolidated Package List
              </button>
            </div>

            <div className="col-auto">
              <button
                className="btn btn-primary fs-val-md btn-xs fw-semibold"
                onClick={() => printInvoice("invoice", true)}
              >
                Print With MRP Invoice
              </button>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary fs-val-md btn-xs fw-semibold"
                onClick={printBarcode}
              >
                Print Barcode
              </button>
            </div>

            <div className="col-auto">
              {pageData.invoice.status == "New" ? (
                <button
                  className="btn btn-primary fs-val-md btn-xs fw-semibold"
                  onClick={() => updateStatus("Packed")}
                >
                  Mark as Packed
                </button>
              ) : (
                <button
                  className="btn btn-primary fs-val-md btn-xs fw-semibold"
                  onClick={() => updateStatus("New")}
                >
                  Mark as New
                </button>
              )}
            </div>
          </div>

          <div className="bg-white p-5">
            <div className="row border border-1 justify-content-between align-items-center p-3">
              <div className="col-3">
                <img src={invoiceView.logo} className="w-100" />
              </div>
              <div className="col-9 h1 text-end">Tax Invoice</div>
            </div>

            <div className="row border border-1 border-top-0 justify-content-between align-items-center">
              <div className="col-8">
                <div className="mb-2">
                  <span className="fs-val-md fw-bold me-1">Sold By</span>
                  <span className="fs-val-md fw-bold">
                    {pageData.invoice?.soldBy?.name}
                  </span>
                </div>

                <div>
                  <span className="fs-val-md fw-bold me-1">Address</span>
                  <span className="fs-val-md ">
                    {pageData.invoice?.soldBy?.address}
                  </span>
                </div>
              </div>

              <div className="col-4 text-end p-2">
                <div>
                  <span className="fs-val-md fw-bold me-1">GST No :</span>
                  <span className="fs-val-md ">
                    {pageData.invoice?.soldBy?.gstno}
                  </span>
                </div>

                <div>
                  <span className="fs-val-md me-1 fw-bold">Pan No :</span>
                  <span className="fs-val-md">{pageData.invoice?._panNo}</span>
                </div>
              </div>
            </div>

            <div className="row border border-1 border-top-0 justify-content-between align-items-center">
              <div className="col-3 border-end text-center fs-val-md">
                <div className="p-3">
                  <span>Invoice Number : </span>
                  <span className="fs-val-md fw-bold">
                    {pageData.invoice?._id}
                  </span>
                </div>
              </div>
              <div className="col-3 border-end text-center fs-val-md">
                <div className="p-3">
                  <span>Invoice Date :</span>
                  <span className="fs-val-md fw-bold">
                    <DateFormatter
                      date={pageData.invoice?.createdAt}
                      format="dd MMM yyyy"
                    />
                  </span>
                </div>
              </div>
              <div className="col-3 border-end text-center fs-val-md">
                <div className="p-3">
                  <span>Order ID : </span>
                  <span className="fs-val-md fw-bold">
                    {pageData.order._id}
                  </span>
                </div>
              </div>
              <div className="col-3  text-center fs-val-md">
                <div className="p-3">
                  <span>Order Date :</span>
                  <span className="fs-val-md fw-bold">
                    <DateFormatter
                      date={pageData.order?.createdAt}
                      format="dd MMM yyyy"
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="row border border-1 border-top-0 border-bottom-0 align-items-center">
              <div className="col-6 border-end py-4">
                <div className="col-12 mb-4">
                  <div className="fw-bold">Billing Address :</div>
                  <div className="mb-2">{address.billingAddress}</div>
                  <div className="row">
                    {billingDetails?.contact_name && (
                      <div className="col-6">
                        <div>
                          Name :
                          <span className="fw-bold">
                            {billingDetails?.contact_name}
                          </span>
                        </div>
                      </div>
                    )}
                    {billingDetails?.contact_no && (
                      <div className="col-6">
                        <div>
                          Mobile Number :
                          <span className="fw-bold">
                            {billingDetails?.contact_no}
                          </span>
                        </div>
                      </div>
                    )}

                    {billingDetails?.panNo && (
                      <div className="col-6">
                        <div>
                          Pan Number :
                          <span className="fw-bold">
                            {billingDetails?.panNo}
                          </span>
                        </div>
                      </div>
                    )}
                    {billingDetails?.gstNo && (
                      <div className="col-6">
                        <div>
                          GST Number :
                          <span className="fw-bold">
                            {billingDetails?.gstNo}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <div className="fw-bold">Shipping Address :</div>
                  <div className="mb-2">{address.shippingAddress}</div>
                  <div className="row">
                    {shippingDetails?.contact_name && (
                      <div className="col-6">
                        <div>
                          Name :
                          <span className="fw-bold">
                            {shippingDetails?.contact_name}
                          </span>
                        </div>
                      </div>
                    )}
                    {shippingDetails?.contact_no && (
                      <div className="col-6">
                        <div>
                          Mobile Number :
                          <span className="fw-bold">
                            {shippingDetails?.contact_no}
                          </span>
                        </div>
                      </div>
                    )}

                    {shippingDetails?.panNo && (
                      <div className="col-6">
                        <div>
                          Pan Number :
                          <span className="fw-bold">
                            {shippingDetails?.panNo}
                          </span>
                        </div>
                      </div>
                    )}
                    {shippingDetails?.gstNo && (
                      <div className="col-6">
                        <div>
                          Gst Number :
                          <span className="fw-bold">
                            {shippingDetails?.gstNo}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-6 text-center py-4">
                <img src={pageData.barcodeImage} alt="barcode" />

                {pageData.invoice?.qrCode ? (
                  <img src={pageData.invoice?.qrCode} alt="Qr Code" />
                ) : (
                  ""
                )}
              </div>
            </div>

            {govInfo?.irn && (
              <div className="row border border-1 border-top-0 border-bottom-0 align-items-center">
                <span className="p-2 border-end">IRN Number</span>
                {govInfo?.irn}
              </div>
            )}

            <div className="row">
              <table className="table table-bordered mb-0">
                <thead className="bg-light">
                  <tr className="fs-val-md">
                    <th className="p-2 text-center">HSN</th>
                    <th className="p-2 text-center">Product Name</th>
                    <th className="p-2 text-center">Qty</th>
                    <th className="p-2 text-center">Price</th>
                    <th className="p-2 text-center">SGST</th>
                    <th className="p-2 text-center">CGST</th>
                    <th className="p-2 text-center">CESS</th>
                    <th className="p-2 text-center">Additional CESS</th>
                    <th className="p-2 text-center">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {(pageData?.invoice?.items || []).map((x, i) => (
                    <tr key={i}>
                      <td className="text-center">{x.HSNNumber}</td>
                      <td className="text-center">{x.name}</td>
                      <td className="text-center">{x.quantity}</td>
                      <td className="text-center">
                        <Amount value={x.price} decimalPlace={2} />
                      </td>
                      <td className="text-center">{x.sgst}</td>
                      <td className="text-center">{x.cgst}</td>
                      <td className="text-center">{x.cessOthers}</td>
                      <td className="text-center">{x.additionalCessValue}</td>
                      <td className="text-center">
                        <Amount value={x.total} decimalPlace={2} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="row border border-1 border-top-0 align-items-center p-2">
              <div className="col-6">
                <div>
                  Total Products :
                  <span className=" fw-bold">
                    {pageData.invoice?.items?.length}
                  </span>
                </div>
              </div>
              <div className="col-6 text-end">
                <div className="mb-2">
                  Sub Totals :
                  <span className=" fw-bold">
                    <Amount
                      value={pageData.invoice?.subTotal}
                      decimalPlace={2}
                    />
                  </span>
                </div>
                <div>
                  Total King Sale Discount :
                  <span className="fw-bold">
                    <Amount
                      value={pageData.invoice?.totalKingsaleDiscount}
                      decimalPlace={2}
                    />
                  </span>
                </div>
              </div>
            </div>

            <div className="row border border-1 border-top-0">
              <div className="col-6 py-2">
                <div className="h5 fw-semibold text-light">
                  Terms & Conditions
                </div>
                <ul>
                  <li className="mb-1">
                    All Disputes Subject to Bangalore Jurisdiction
                  </li>
                  <li className="mb-1">
                    Goods once sold will not be taken back or exchanged
                  </li>
                  <li className="mb-1">
                    Guarantee / Warranty should be claimed from the Brand Only
                  </li>
                  <li className="mb-1">
                    Prices Mentioned above are After Discount/Offer If any
                  </li>
                </ul>
              </div>
              <div className="col-6 p-3">
                <table className="table table-bordered bg-light">
                  <tbody>
                    {pageData.invoice?.taxGroupDet?.map((x, i) => (
                      <TaxTemplateRow taxDetails={x} key={i} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row border border-1 border-top-0">
              <div className="col-6 py-3">
                <h4 className="fw-bold">
                  Packaging Box : {pageData.invoice?.items?.length}
                </h4>
              </div>
              <div className="col-6 py-3 text-end">
                <h4 className="fw-bold">
                  Total Amount :
                  <Amount
                    value={
                      pageData.invoice?.invoiceValue +
                      (pageData.invoice?.tcsTax?.value || 0)
                    }
                    decimalPlace={2}
                  />
                </h4>
              </div>
            </div>

            <div className="row border border-1 border-top-0 align-items-center">
              <div className="col-10 py-3">
                <div className="row g-2">
                  {pageData.invoice?._packages?.map((x, i) => (
                    <div className="col-auto me-2" key={i}>
                      <div className="border border-2 p-2 fs-val-md">
                        {x.packageNo}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-2 py-3 text-end">
                <img src={invoiceView.img} />

                <div className="text-center">
                  <DateFormatter
                    date={pageData.invoice?.pdpDate}
                    format="dd MMM yyyy"
                  />
                </div>
              </div>
            </div>

            <div className="row border border-1 border-top-0">
              <div className="col-12 fw-bold p-2">
                *This is a computer generated or electronic invoice as per IT
                Act 2000 and not required to bear a signature or digital
                signature as per GST Notification No. 74/2018-Central tax dated
                31st December 2018*
              </div>
            </div>
          </div>
        </>
      )}

      <BusyLoader show={busyLoader} />
    </>
  );
};

export default ViewInvoice;
