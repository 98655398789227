import classNames from "classnames";

const PackScanStatusBlock = ({ data }) => {
  return (
    <>
      <div className="row  align-items-center mb-2">
        {data.map((x, i) => (
          <div className="col-2" key={i}>
            <div
              className={classNames("border rounded p-3 mb-2")}
              style={{ backgroundColor: x.bgColor, height: "100px" }}
            >
              <div className="fs-val-lg fw-semibold text-white mb-3">
                {x.label}
              </div>
              <div className={classNames(" fs-val-lg fw-bold mb-3")}>
                <span className="text-white">{x.packageNo}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PackScanStatusBlock;

/* deprecated Code 

<div className={"card rounded mx-0"} style={style.cardStyle}>
        <div className="card-header">
          <h4 className="card-title">
            <div className="row align-items-center pt-1 pb-2">
              <div className="col-auto">Scanned Packages Status </div>
            </div>
          </h4>
        </div>
        <div
          className={classNames("card-body p-0 py-2")}
          style={style.tableStyle}
        >
          <table className="table">
            <TableHeader data={header} />
            <tbody>
              {scannedPackages?.length == 0 && (
                <tr className="fs-val-md">
                  <td colSpan={header.length} className="text-center p-0">
                    No Package Found
                  </td>
                </tr>
              )}

              {scannedPackages?.length
                ? scannedPackages.map((x, i) => (
                    <tr key={i}>
                      <td className="text-center">
                        {paginationConfigRef.current.startSlNo + i}
                      </td>
                      <td>{x.packageNo}</td>
                      <td>
                        <HighlightText status={x.label} />
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
        <PaginationBlock
          paginationConfig={paginationConfigRef.current}
          paginationCb={paginationCb}
        />
      </div>

      // pagination deprecation 

  const paginationConfigRef = useRef({
    ...paginationConfig,
    totalRecords: data.length,
  });

  const paginationCb = useCallback(
    (d) => {
      paginationConfigRef.current.activePage = d.activePage;
      paginationConfigRef.current.startSlNo = d.startSlNo;
      paginationConfigRef.current.endSlNo = d.endSlNo;
      setScannedPackages(
        data.slice(
          paginationConfigRef.current.startSlNo - 1,
          paginationConfigRef.current.endSlNo
        )
      );
    },
    [data]
  );

  const paginationConfig = {
  totalRecords: 0,
  rowsPerPage: 10,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 10,
};


*/
