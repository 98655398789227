export const packScanView = {
  summaryCard: [
    {
      label: "Total",
      value: 0,
      color: "Primary",
      status: "",
      bgColor: "#808080",
    },
    {
      label: "Valid",
      value: 0,
      status: "New",
      color: "success",
      bgColor: "#008000",
    },
    {
      label: "Already Shipped",
      value: 0,
      color: "primary",
      bgColor: "#0000ff",
      status: "Shipped",
    },
    {
      label: "Delivered",
      value: 0,
      color: "primary",
      bgColor: "#0000c1",
      status: "Delivered",
    },
    {
      label: "Already Packed",
      value: 0,
      color: "primary",
      status: "Packed",
      bgColor: "#ffa500",
    },
    {
      label: "Returned",
      value: 0,
      color: "warning",
      bgColor: "#800080",
      status: "Returned",
    },
    {
      label: "Wrong Warehouse",
      value: 0,
      color: "danger",
      bgColor: "#fe9000",
    },
    {
      label: "Cancelled",
      value: 0,
      color: "warning",
      bgColor: "#ee82ee",
      status: "Cancelled",
    },
    {
      label: "Not Found",
      value: 0,
      color: "danger",
      status: "NotFound",
      bgColor: "#ff0000",
    },
  ],
  measurementBoxDetails: {
    height: "",
    length: "",
    width: "",
    weight: "",
    isDetailsVisible: false,
    mode: "manual",
  },
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Pack Scan",
    },
  ],
};

export const packScanInputView = {
  statusLabels: {
    New: {
      label: "Valid",
      bgColor: "#008000",
    },
    Packed: {
      label: "Already Packed",
      bgColor: "#ffa500",
    },
    Shipped: {
      label: "Already Shipped",
      bgColor: "#0000ff",
    },
    Cancelled: {
      label: "Cancelled",
      bgColor: "#ee82ee",
    },
    NotFound: {
      label: "NotFound",
      bgColor: "#ff0000",
    },
    Delivered: {
      label: "Delivered",
      bgColor: "#0000c1",
    },
    Returned: {
      label: "Returned",
      bgColor: "#800080",
    },
  },
};
