import { NavService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  Spinner,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { memo, useState } from "react";

const rbac = {};

const defaultHeaders = [
  { label: "Sl No", width: "5%", isCentered: true },
  { label: "Warehouse", width: "17%" },
  { label: "Batch ID" },
  { label: "Pending", width: "17%" },
  { label: "Total Invoice" },
  { label: "Created On" },
  { label: "Remarks" },
  { label: "Status" },
  { label: "Action" },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const [headers, setHeaders] = useState([...defaultHeaders]);

  const ViewBatch = (id) => {
    NavService.openInNewTab("/oms/view-batch", {
      id,
    });
  };

  return (
    <>
      <table className="table table-bordered bg-white">
        <TableHeader data={headers} sort={sort} sortCb={sortCb} />

        {/* Table Body */}
        <tbody>
          {/*  When Table is Loading  */}
          {loading && (
            <TableSkeletonLoader rows={10} cols={headers.length} height={40} />
          )}

          {/* When No loading */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={headers.length}>
                <NoDataFound>No Brand Found </NoDataFound>
              </td>
            </tr>
          ) : null}
          {/* When Not Loading and Data is there  */}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                {/* Serial Number */}

                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                <td>
                  {x.warehouseLoading ? (
                    <Spinner type="dots" />
                  ) : (
                    x._warehouse.name
                  )}
                </td>
                <td>
                  <div className="text-primary">{x?._id}</div>
                  <div className=" badge badge-soft-primary">{x.batchType}</div>
                </td>

                <td>{x?._invoicePending}</td>
                <td>{x.invoiced}</td>

                {/* ID */}
                {/* Created At */}
                <td>
                  <DateFormatter date={x.createdAt} />
                  <div>
                    By :
                    {x.userLoading ? (
                      <Spinner type="dots" />
                    ) : (
                      x._user?.name || x.createdBy || "N/A"
                    )}
                  </div>
                </td>

                <td>{x.remarks || "N/A"}</td>
                <td>
                  <HighlightText status={x.status} />
                </td>

                <td>
                  <button
                    className="btn btn-sm btn-outline-primary fs-val-sm"
                    onClick={() => ViewBatch(x._id)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Pagination Block */}
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />
    </>
  );
};

export default memo(Table);
