/* eslint-disable jsx-a11y/img-redundant-alt */
import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";
import {
  OmsService,
  ProductService,
  WarehouseService,
  appConfigs,
} from "@sk/services";
import {
  Amount,
  HighlightText,
  Loader,
  NoDataFound,
  PageInfo,
  PageLoader,
  TableHeader,
  Toaster,
  BusyLoader,
} from "@sk/uis";

import produce from "immer";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useForm } from "react-hook-form";

import { useNavigate, useSearchParams } from "react-router-dom";

import { listView } from "./constantService";

import cloneDeep from "lodash/cloneDeep";

import BarcodeDetailsModal from "./modals/BarcodeDetailsModal";

import PackageDetailsModal from "./modals/PackageDetailsModal";

const style = {
  barcodeBoxStyle: {
    right: "15px",
    left: "106px",
  },
  alreadyInvoiceStyle: { height: "80vh" },
};

const getData = async (id) => {
  const r = await OmsService.getBatchById(id);
  return r.statusCode == 200
    ? { ...r.resp, userLoading: true, warehouseLoading: true }
    : {};
};

const createPackage = (id, packagePayload) => {
  return OmsService.createPackage(id, { products: packagePayload });
};

const getBarcodeData = async (barcode, warehouse, proformaId) => {
  let p = {
    serial: "barcode",
    barcode: barcode,
    performaId: proformaId,
    select: "productId",
  };

  const r = await WarehouseService.getStockByBarcode(warehouse, p);

  return Array.isArray(r.resp) ? r.resp : [];
};

const getCaseBarcodeData = async (caseBarcode) => {
  let p = {
    page: 1,
    count: 100,
    select: "case_quantity,product_barcode,product_id,mrp",
    filter: { case_barcode: caseBarcode },
  };
  const r = await ProductService.getCaseBarcode(p);
  return Array.isArray(r.resp) ? r.resp[0] : {};
};

const getSelectedOrder = (resp, proformaId) => {
  return (
    (resp.performa || []).filter((x) => x.performaId == proformaId)[0] || {}
  );
};

const createInvoice = async (payload) => {
  const r = await OmsService.createInvoice(payload);
  return r;
};

const invoiceValidationRequestStatus = async (payload) => {
  const r = await OmsService.invoiceValidationRequestStatus(payload);
  if (r.statusCode !== 200) {
    return {
      status: "Failed",
      msg: "Unable to check performa invoice status",
      resp: r.resp,
    };
  }
  return { status: r.resp.status, msg: r.resp.message, resp: r.resp };
};

const getOrderProducts = (selectedOrder) => {
  const createImageUrl = (assetId) => {
    return appConfigs.ASSET + "/" + assetId;
  };
  return (selectedOrder.subOrders || []).flatMap((x) =>
    x.products.map((p) => ({
      orderId: x._id, // _id from suborder
      dealName: x.name, // name from suborder
      productName: p.name, // name from suborder.products
      productId: p.id,
      dealId: x.id,
      mrp: x.mrp, // suborder mrp
      images: x.images.map((i) => createImageUrl(i)), // image
      scanQty: 0,
      packageDetails: [], // package details
      warehouseQtyDetails: [], // warehouse qty
      totalQty: p.quantity, // qty suborder.products
      _productDetails: p,
      _subOrderDetails: x,
    }))
  );
};

const breadcrumbs = listView.breadcrumbs;

const attachAdditionalDataConfig = listView.additionalDataConfig;

const headers = listView.tableHeader;

const snapShortTableHeader = listView.snapShortTableHeader;

const packageTableHeader = listView.packageTableHeader;

const ScanInvoiceProforma = () => {
  const [details, setDetails] = useState({
    batchDetails: {},
    selectedOrder: {},
    orderProduct: [],
    isBoxOpen: false,
  });
  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const [display, setDisplay] = useState("loading");

  const [searchParams] = useSearchParams();

  const { register, getValues } = useForm();

  const [showBarcodeDetailsModal, setShowBarcodeDetailModal] = useState(false);

  const [showPackageDetailsModal, setShowPackageDetailsModal] = useState(false);

  const [showBusyLoader, setShowBusyLoader] = useState(false);

  const query = useFetchUrlQueryString(searchParams); // batchId and proformaId

  const navigate = useNavigate();

  const isGenerateInvoiceEnabled = useMemo(() => {
    let isCompletedScanned = false;
    let isPartialScanned = false;

    for (let x of details.orderProduct) {
      if (x.scanQty == x.totalQty) {
        isCompletedScanned = true;
      }
      if (x.scanQty >= 1 && x.scanQty < x.totalQty) {
        isPartialScanned = true;
      }
    }

    return isCompletedScanned && !isPartialScanned;
  }, [details.orderProduct]);

  const scanInvoiceRef = useRef({
    packagePayload: [],
    caseBarcode: {},
    barcode: "",
    closeBoxResp: {},
    generateInvoiceResp: {},
  });

  const barcodeModalRef = useRef({
    barcodeList: [],
    barcode: "",
  });

  useEffect(() => {
    init();
  }, [init]);

  const onInputChange = useCallback((e) => {
    let val = e.target.value;
    e.target.value = val.trim();
  }, []);

  const openBarcodeModal = useCallback((payload) => {
    barcodeModalRef.current.barcodeList = payload.list;
    barcodeModalRef.current.barcode = payload.barcode;
    setShowBarcodeDetailModal(true);
  }, []);

  const packageDetailModalCb = useCallback(({ status }) => {
    if (status == "print") {
      // do some logic
    }
    setShowPackageDetailsModal(false);
  }, []);

  const poolStatusValidation = useCallback(async (payload) => {
    const r = await invoiceValidationRequestStatus(payload);
    if (r.status == "Failed") {
      return Toaster.error(r.msg);
    }

    if (r.status == "Success") {
      scanInvoiceRef.current.generateInvoiceResp = r.resp;
      return Toaster.success(r.msg);
    }
    const t = setTimeout(() => {
      clearTimeout(t);
      poolStatusValidation(payload);
    }, 2000);
  }, []);

  const preparePackagePayload = useCallback((payload, qty) => {
    const list = scanInvoiceRef.current.packagePayload;
    let barcode = scanInvoiceRef.current.barcode;
    const index = list.findIndex(
      (x) => payload.productId == x.id && payload.mrp == x.mrp
    );

    if (index != -1) {
      list[index].quantity += qty;
    } else {
      list.push({
        id: payload.productId,
        barcode,
        quantity: qty,
        serialNo: [null],
        mrp: payload.mrp,
      });
    }
  }, []);

  const cancelScan = useCallback((index, productId) => {
    let list = scanInvoiceRef.current.packagePayload;

    let productIndex = list.findIndex((x) => x.id == productId);

    list.splice(productIndex, 1);

    setDetails(
      produce((draft) => {
        draft.orderProduct[index].packageDetails = []; // warehouse qty
        draft.orderProduct[index].warehouseQtyDetails = [];
        draft.orderProduct[index].scanQty = 0;
      })
    );
  }, []);

  const getWhSnapShotDetails = useCallback((scannedProduct, payload, qty) => {
    const list = cloneDeep(scannedProduct.warehouseQtyDetails);

    const currentSnapShortIndex = list.findIndex((x) => x.id == payload._id);

    if (currentSnapShortIndex != -1) {
      list[currentSnapShortIndex].qty += qty;
    } else {
      list.push({ qty, id: payload._id, mrp: payload.mrp });
    }

    return list;
  }, []);

  const updatedPackageId = useCallback((payload) => {
    setDetails((prevData) => {
      return produce(prevData, (draft) => {
        payload.products.forEach((x) => {
          let orderProductIndex = prevData.orderProduct.findIndex(
            (p) => x.id == p.productId
          );

          let packageDetailIndex = draft.orderProduct[
            orderProductIndex
          ].packageDetails.findIndex((p) => p.packageNo == payload._id);

          if (packageDetailIndex !== -1) {
            // If packageNo already exists, increase the quantity
            draft.orderProduct[orderProductIndex].packageDetails[
              packageDetailIndex
            ].quantity += x.quantity;
          } else {
            // If packageNo does not exist, push a new Package
            draft.orderProduct[orderProductIndex].packageDetails.push({
              packageNo: payload._id,
              quantity: x.quantity,
              mrp: payload.mrp,
              id: x.id,
            });
          }
        });
      });
    });
  }, []);

  const attachAdditionalInfo = useCallback(
    (d) => {
      let tmp = [];
      // Attach User Info
      setAdditionalData([d], attachAdditionalDataConfig, (x) => {
        tmp.push(x);
        if (tmp.length == attachAdditionalDataConfig.length) {
          const t = [...attachAllData([d], tmp)][0];
          setDetails((v) => ({
            ...v,
            batchDetails: { ...v.batchDetails, ...t },
          }));
        }
      });
    },
    [attachAllData, setAdditionalData]
  );

  const prepareInvoicePayload = useCallback(() => {
    let p = {
      asCancelled: true,
      isBatchRetained: true,
      orderId: details.selectedOrder.orderId,
      performaInvoice: query.proformaId,
      subOrderList: details.orderProduct.map((x) => {
        return {
          _id: x.orderId,
          dealId: x.dealId,

          packages: x.packageDetails.map((p) => {
            return {
              PackageNo: p.packageNo,
              mrp: p.mrp,
              quantity: p.quantity,
              _id: p.id,
            };
          }),

          scan: x.warehouseQtyDetails.map((p) => {
            return {
              inventoryIds: [p.id],
              quantity: p.qty,
              productId: x.productId,
              mrp: p.mrp,
            };
          }),
        };
      }),

      totalPackages: details.orderProduct.reduce(
        (acc, cur) => acc + cur.packageDetails.length,
        0
      ),
    };

    return p;
  }, [details, query]);

  const generateInvoice = useCallback(async () => {
    const payload = prepareInvoicePayload();
    const r = await createInvoice(payload);

    if (r.statusCode != 200) {
      return Toaster.error(r.resp.message);
    }
    const refId = r.resp.referenceId;
    let p = { id: refId };

    poolStatusValidation(p);

    Toaster.success(r.resp.message);
  }, [prepareInvoicePayload, poolStatusValidation]);

  const updateScannedQty = useCallback(
    (payload) => {
      // payload is having data of barcode response
      let qty = 1;
      const caseBarcodeDetails = scanInvoiceRef.current.caseBarcode;

      const scannedProductIndex = details.orderProduct.findIndex(
        (x) => x.productId == payload.productId
      );

      const scannedProduct = details.orderProduct[scannedProductIndex];

      const selectedSnap = scannedProduct.warehouseQtyDetails.find((x) => {
        return x.id == payload._id;
      });

      if (scannedProduct.productId == caseBarcodeDetails?.product_id) {
        qty = caseBarcodeDetails?.case_quantity;
      }

      let currentQty = (selectedSnap?.qty || 0) + qty;

      if (currentQty > payload.quantity) {
        return Toaster.error("Insufficient Quantity");
      }
      if (scannedProduct.scanQty + qty > scannedProduct.totalQty) {
        return Toaster.error("Scan Qty is exceeding Total qty");
      }

      preparePackagePayload(payload, qty);

      setDetails(
        produce((draft) => {
          draft.orderProduct[scannedProductIndex].scanQty += qty;
          draft.orderProduct[scannedProductIndex].warehouseQtyDetails =
            getWhSnapShotDetails(scannedProduct, payload, qty);
        })
      );
    },
    [details.orderProduct, getWhSnapShotDetails, preparePackagePayload]
  );

  const updateMultiScanQty = useCallback(
    (payload) => {
      let qty = 1;
      let remaining = 1;

      const caseBarcodeDetails = scanInvoiceRef.current.caseBarcode;

      const scannedProductIndex = details.orderProduct.findIndex(
        (x) => x.productId == payload[0].productId
      );

      const scannedProduct = details.orderProduct[scannedProductIndex];

      const whSnap = cloneDeep(scannedProduct.warehouseQtyDetails);

      let whSnapList = [...whSnap];

      if (scannedProduct.productId == caseBarcodeDetails?.product_id) {
        remaining = qty = caseBarcodeDetails?.case_quantity;
      }

      for (let p of payload) {
        let curWhSnapIndex = whSnap.findIndex((x) => x.id == p._id);

        const curWhSnap = whSnap[curWhSnapIndex];

        if (curWhSnap) {
          let availableQty =
            curWhSnap.qty + remaining <= p.quantity
              ? remaining
              : curWhSnap.qty - p.quantity;
          whSnapList.splice(curWhSnapIndex, 1, {
            ...curWhSnap,
            qty: curWhSnap.qty + availableQty,
          });

          remaining -= availableQty;
        }
        if (!curWhSnap) {
          let q = remaining <= p.quantity ? remaining : p.quantity;
          whSnapList.push({ qty: q, id: p._id, mrp: p.mrp });
          remaining -= q;
        }

        if (remaining <= 0) {
          break;
        }
      }

      if (remaining > 0) {
        return Toaster.error("Insufficient Quantity");
      }
      if (scannedProduct.scanQty + qty > scannedProduct.totalQty) {
        return Toaster.error("Scan Qty is exceeding Total qty");
      }

      preparePackagePayload(payload[0], qty);

      setDetails(
        produce((draft) => {
          draft.orderProduct[scannedProductIndex].scanQty += qty;
          draft.orderProduct[scannedProductIndex].warehouseQtyDetails =
            whSnapList;
        })
      );
    },
    [details.orderProduct, preparePackagePayload]
  );

  const closeBox = useCallback(async () => {
    Loader.showPrgsLoader();
    const id = query.proformaId;
    const payload = scanInvoiceRef.current.packagePayload;

    if (!payload.length) return;

    const r = await createPackage(id, payload);

    Loader.hidePrgsLoader();

    if (r.statusCode == 200) {
      scanInvoiceRef.current.packagePayload = [];

      scanInvoiceRef.current.closeBoxResp = {};

      Toaster.success(r.resp.message);

      scanInvoiceRef.current.closeBoxResp = r.resp.result;

      updatedPackageId(r.resp.result);

      setShowPackageDetailsModal(true);

      setDetails(
        produce((draft) => {
          draft.isBoxOpen = false;
        })
      );
    } else {
      Toaster.error(r.resp.message || "Something When Wrong Please try again");
    }
  }, [query.proformaId, updatedPackageId]);

  const dispatch = useCallback(
    (action) => {
      if (action.type == "OPEN_BOX") {
        setDetails(
          produce((draft) => {
            draft.isBoxOpen = true;
          })
        );
      }

      if (action.type == "ADD_MULTI_SCAN_QTY") {
        updateMultiScanQty(action.payload);
      }
      if (action.type == "ADD_SCAN_QTY") {
        updateScannedQty(action.payload);
      }

      if (action.type == "OPEN_BARCODE_MODAL") {
        openBarcodeModal(action.payload);
      }

      if (action.type == "CLOSE_BOX") {
        closeBox();
      }
    },
    [updateScannedQty, openBarcodeModal, updateMultiScanQty, closeBox]
  );

  const barcodeDetailsModalCb = useCallback(
    ({ status, payload }) => {
      if (status == "success") {
        dispatch({ type: "ADD_MULTI_SCAN_QTY", payload });
      }
      setShowBarcodeDetailModal(false);
    },
    [dispatch]
  );

  const loadBarcodeDetails = useCallback(
    async (code = "") => {
      Loader.showPrgsLoader();

      let barcode = code || getValues("barcode");

      scanInvoiceRef.current.barcode = barcode;

      const warehouse = details.batchDetails.whId;

      const r = await getBarcodeData(barcode, warehouse, query.proformaId);

      Loader.hidePrgsLoader();

      if (!r.length) return Toaster.error("Invalid barcode");

      let productId = r[0]?.productId;

      let product = details.orderProduct.find((x) => x.productId == productId);
      if (!product) {
        return Toaster.error("Wrong Product barcode");
      }
      if (r.length == 1) {
        return dispatch({ type: "ADD_SCAN_QTY", payload: r[0] });
      }

      if (r.length > 1) {
        return dispatch({
          type: "OPEN_BARCODE_MODAL",
          payload: { list: r, barcode },
        });
      }
    },
    [getValues, query.proformaId, details, dispatch]
  );

  const loadCaseBarcode = useCallback(async () => {
    Loader.showPrgsLoader();

    let caseBarcode = getValues("caseBarcode");

    const r = await getCaseBarcodeData(caseBarcode);

    Loader.hidePrgsLoader();

    if (!r?.product_barcode) return Toaster.error("Invalid Case Barcode");

    scanInvoiceRef.current.caseBarcode = r;

    loadBarcodeDetails(r.product_barcode);
  }, [getValues, loadBarcodeDetails]);

  const onInputEnter = useCallback(
    (e, key) => {
      let val = e.target?.value?.trim();

      if (e.key == "Enter" && val && key == "barcode") {
        scanInvoiceRef.current.caseBarcode = {};
        scanInvoiceRef.current.barcode = "";
        return loadBarcodeDetails();
      }

      if (e.key == "Enter" && val && key == "caseBarcode") {
        return loadCaseBarcode();
      }
    },
    [loadBarcodeDetails, loadCaseBarcode]
  );

  const loadDetails = useCallback(
    async (id) => {
      setDisplay("loading");

      const d = await getData(id);

      if (!d._id) return setDisplay("noDataFound");

      attachAdditionalInfo(d);

      const selectedOrder = getSelectedOrder(d, query.proformaId);

      const orderProduct = getOrderProducts(selectedOrder);

      setDetails(
        produce((draft) => {
          draft.batchDetails = d;
          draft.selectedOrder = selectedOrder;
          draft.orderProduct = orderProduct;
        })
      );

      if (selectedOrder._id || orderProduct.length) {
        return setDisplay("details");
      }

      setDisplay("details");
    },
    [query.proformaId, attachAdditionalInfo]
  );

  const init = useCallback(() => {
    if (query.proformaId && query.batchId) {
      loadDetails(query.batchId);
    } else {
      setDisplay("noDataFound");
    }
  }, [query, loadDetails]);

  return (
    <>
      <PageInfo
        title={`Scan Proforma Invoice #${query.proformaId}`}
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      {display == "loading" && <PageLoader />}

      {display == "noDataFound" && <NoDataFound> No Batch Found</NoDataFound>}

      {display == "details" && (
        <>
          {/* Completed */}
          {details.selectedOrder.status == "Completed" && (
            <div
              className="font-24 bg-white d-flex justify-content-center align-items-center"
              style={style.alreadyInvoiceStyle}
            >
              {query.proformaId} is Already Invoiced
            </div>
          )}

          {details.selectedOrder.status == "Pending" && (
            <div>
              <div className="mb-5 ">
                <table className="table table-bordered bg-white ">
                  <TableHeader data={headers} />
                  <tbody>
                    {details.orderProduct.map((x, i) => (
                      <tr className="fs-val-md" key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <img
                            src={x.images[0]}
                            className="w-100"
                            alt="product image"
                          />
                        </td>
                        <td>{x.dealName}</td>
                        <td>{x.productName}</td>
                        <td>
                          <Amount value={x.mrp} decimalPlace={2} />
                        </td>
                        <td>{x.totalQty}</td>
                        <td className="fs-val-lg fw-semi-bold text-primary">
                          {x.scanQty} / {x.totalQty}
                        </td>
                        <td>
                          {x.scanQty != x.totalQty ? (
                            <HighlightText status={"Pending"} />
                          ) : (
                            <HighlightText status={"Completed"} />
                          )}

                          {x.warehouseQtyDetails.length ? (
                            <button
                              className="btn btn-sm btn-outline-danger fs-val-sm mt-2"
                              onClick={() => cancelScan(i, x.productId)}
                            >
                              Cancel
                            </button>
                          ) : null}
                        </td>
                        {/* Snap Short details */}
                        <td>
                          <table className="table table-sm">
                            <TableHeader data={snapShortTableHeader} />
                            <tbody>
                              {!x.warehouseQtyDetails.length ? (
                                <tr>
                                  <td
                                    colSpan={snapShortTableHeader.length}
                                    className="fs-val-sm"
                                  >
                                    <NoDataFound>
                                      <span className="fs-val-sm">
                                        No Snap found
                                      </span>
                                    </NoDataFound>
                                  </td>
                                </tr>
                              ) : null}

                              {x.warehouseQtyDetails.map((x, i) => (
                                <tr className="fs-val-sm" key={x.id}>
                                  <td>{x.id}</td>
                                  <td>
                                    <Amount value={x.mrp} decimalPlace={2} />
                                  </td>
                                  <td> {x.qty} </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>

                        <td>
                          <table className="table table-sm">
                            <TableHeader data={packageTableHeader} />
                            <tbody>
                              {!x.packageDetails.length ? (
                                <tr>
                                  <td
                                    colSpan={snapShortTableHeader.length}
                                    className="fs-val-sm"
                                  >
                                    <NoDataFound>
                                      <span className="fs-val-sm">
                                        No Package found
                                      </span>
                                    </NoDataFound>
                                  </td>
                                </tr>
                              ) : null}

                              {x.packageDetails.map((x, i) => (
                                <tr className="fs-val-sm" key={x.packageNo}>
                                  <td>{i + 1}</td>
                                  <td>{x.packageNo}</td>
                                  <td> {x.quantity} </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Input  Block */}
              <div
                className=" position-fixed bottom-0 bg-white p-3 mb-4 border border-1 rounded rounded-1"
                style={style.barcodeBoxStyle}
              >
                <div className="row w-100">
                  <div className="col-3">
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Barcode here"
                      className="form-control"
                      {...register("barcode", {
                        onChange: onInputChange,
                      })}
                      disabled={!details.isBoxOpen}
                      onKeyDown={(e) => onInputEnter(e, "barcode")}
                    />
                  </div>

                  <div className="col-3">
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Enter Case Barcode here"
                      className="form-control"
                      {...register("caseBarcode", {
                        onChange: onInputChange,
                      })}
                      disabled={!details.isBoxOpen}
                      onKeyDown={(e) => onInputEnter(e, "caseBarcode")}
                    />
                  </div>

                  <div className="col-6">
                    <div className="row">
                      <div className="col-auto me-auto">
                        <button
                          className="btn btn-success fs-val-md"
                          onClick={() =>
                            dispatch({
                              type: details.isBoxOpen
                                ? "CLOSE_BOX"
                                : "OPEN_BOX",
                            })
                          }
                        >
                          {details.isBoxOpen ? "Close Box" : "Open Box"}
                        </button>
                      </div>
                      <div className="col-auto">
                        {!details.isBoxOpen && isGenerateInvoiceEnabled ? (
                          <button
                            className="btn btn-primary fs-val-md"
                            onClick={generateInvoice}
                          >
                            Generate Invoice
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <BarcodeDetailsModal
        show={showBarcodeDetailsModal}
        callback={barcodeDetailsModalCb}
        barcodeList={barcodeModalRef.current.barcodeList}
        barcode={barcodeModalRef.current.barcode}
        products={details.orderProduct}
      />

      <BusyLoader show={showBusyLoader} />

      <PackageDetailsModal
        callback={packageDetailModalCb}
        show={showPackageDetailsModal}
      />
    </>
  );
};

export default ScanInvoiceProforma;
