import {
  AppliedFilterLabel,
  BusyLoader,
  PageInfo,
  Rbac,
  SummaryCard,
  TableColumnBtn,
  Spinner,
  AppCard,
} from "@sk/uis";
import listView from "../constantService";
import { produce } from "immer";
import merge from "lodash/merge";
import { useNavigate } from "react-router-dom";
import { useState, useCallback, useEffect, useRef } from "react";
import ResetConfirmationModal from "./modals/ResetConfirmationModal";
import FilterForm from "./components/FilterForm";
import { CommonService, OmsService, UtilityService } from "@sk/services";
import Table from "./components/Table";
import { set } from "lodash";
import { format } from "date-fns";

const getFilterParams = (paginationData = {}, filterData = {}, sort = {}) => {
  console.log("passing to filter  --> ", filterData);
  let p = {
    page: paginationData.current.activePage,
    count: paginationData.current.rowsPerPage,
    filter: {},
  };

  let d = filterData.current || {};

  const search = d?.search?.trim();
  const searchRegex = { $regex: search, $options: "gi" };
  if (search) {
    // p.filter.$or = [{ _id: searchRegex }];
    // p.filter.orderId = search;
    p.filter = { $or: [{ orderId: search }, { batchId: search }] };
  }

  if (filterData?.current?.createdAt?.length) {
    const formattedStartDate = format(
      new Date(filterData.current.createdAt[0]),
      "yyyy-MM-dd"
    );
    const formattedEndDate = format(
      new Date(filterData.current.createdAt[1]),
      "yyyy-MM-dd"
    );
    p.filter.createdAt = {
      $gte: set(formattedStartDate, {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(formattedEndDate, {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }
  if (filterData?.current?.orderId) {
    p.filter.orderId = filterData.current.orderId.trim();
  }
  if (filterData?.current?.batchId) {
    p.filter.batchId = filterData.current.batchId.trim();
  }
  if (filterData?.current?.active) {
    p.filter.isActive = filterData.current.active === "no" ? false : true;
  }
  if (filterData?.current?.invoiceCreated) {
    p.filter.isInvoiceCreated =
      filterData.current.invoiceCreated === "no" ? false : true;
  }

  if (filterData?.current?.codPaid) {
    p.filter.isCODPaid = filterData.current.codPaid === "no" ? false : true;
  }
  if (filterData?.current?.delivered) {
    p.filter.isDelivered = filterData.current.delivered === "no" ? false : true;
  }
  if (filterData?.current?.shipped) {
    p.filter.isShipped = filterData.current.shipped === "no" ? false : true;
  }
  if (filterData?.current?.packed) {
    p.filter.isPacked = filterData.current.packed === "no" ? false : true;
  }
  if (sort?.current?.key) {
    p.sort = {
      [sort.current.key]: sort.current.value == "desc" ? -1 : 1,
    };
  }
  console.log("sort params --> ", sort);
  console.log("returning p ---> ", p);
  return p;
};

const defaultSortOpt = { key: "", value: "desc" };

const BulkOrderImportTracker = () => {
  // Summary Card for Counter display
  // For Category List data
  const [data, setData] = useState([]);

  // For Category List Data Loader
  const [loadingData, setLoadingData] = useState(true);

  // For Pagination Count Loader
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  // Summary Card for Counter display
  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  // Attach additional Info
  //    const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  // Filter data Label displaying
  const [filterLabels, setFilterLabels] = useState([]);

  const [selecteMoreTblColumns, setSelectedMoreTblColumns] = useState({});

  // Filter Data
  const filterDataRef = useRef({});

  //Pagination Data
  const paginationRef = useRef({ ...listView.pagination });

  //Sorting
  const sortRef = useRef({ ...defaultSortOpt });

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: "", value: "" });
  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const getData = async (params) => {
    const r = await OmsService.getBulkOrders(params);
    return Array.isArray(r.resp) ? r.resp : [];
  };
  // To Get Count
  const getCount = async (params) => {
    try {
      let p = {
        ...params,
        outputType: "count",
      };
      console.log("params while calling api --> ", p);
      const r = await OmsService.getBulkSummary(p);
      return { count: r.statusCode == 200 && r.resp[0] ? r.resp[0].total : 0 };
    } catch (error) {
      return new Promise((resolve) => resolve({ count: 0 }));
    }
  };

  const getListCount = async (params) => {
    try {
      let p = {
        ...params,
        outputType: "count",
      };
      console.log("params while calling api --> ", p);
      const r = await OmsService.getBulkOrders(p);
      return { count: r.statusCode == 200 && r.resp[0] ? r.resp[0].total : 0 };
      // return { count: 15 };
    } catch (error) {
      return new Promise((resolve) => resolve({ count: 0 }));
    }
  };

  const init = useCallback(() => {
    applyFilter();
  }, [applyFilter]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    const p = getFilterParams(paginationRef, filterDataRef, sortRef);
    const d = await getData(p);
    // let tmp = [];
    // setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
    //   tmp.push(x);
    //   if (tmp.length == listView.additionalTableDataConfig.length) {
    //     setData([...attachAllData(d, tmp)]);
    //   }
    // });
    setData(d);
    setLoadingData(false);
  }, []);
  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    console.log("v is --> ", v);
    delete v.search;
    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    console.log("l is---> ", l);
    setFilterLabels(l);
  }, []);

  const applyFilter = useCallback(
    async (isSummaryNeeded = true) => {
      // Category List data
      loadList();
      //load Summary card
      if (isSummaryNeeded) {
        loadSummary();
      }

      // Preparing Filter Label
      prepareFilterLabels();

      // for total Pagination records
      setLoadingTotalRecords(true);
      const p = getFilterParams(paginationRef, filterDataRef, sortRef);
      const c = await getListCount(p);
      paginationRef.current.totalRecords = c.count;
      setLoadingTotalRecords(false);
    },
    [loadList, loadSummary]
  );

  const loadSummary = useCallback(async () => {
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );
    const newFilter = { ...filterDataRef.current, outputType: "count" };
    console.log("summary vcard data --> ", summaryCard);
    const filterParams = getFilterParams(paginationRef, newFilter);
    let promises = [];
    summaryCard.forEach((e) => {
      const filter = { ...filterParams, regex: e.label };
      promises.push(getCount(merge({}, filter, { filter: e.filter })));
    });
    const r = await Promise.all(promises);
    console.log("summary data --> ", r);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [summaryCard]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterFormCb = useCallback(
    (data) => {
      filterDataRef.current = { ...data.formData };
      paginationRef.current = { ...listView.pagination };
      init();
    },
    [init]
  );

  const handleReset = async (title) => {
    setShowModal(false);

    setSummaryCard(
      produce((draft) => {
        const card = draft.find((x) => x.label === title);
        if (card) {
          card.loading = true;
        }
      })
    );

    let p = {
      // ...params,
      regex: title,
    };

    console.log("update params are --> ", p);
    console.log("params while calling api --> ", p);
    const r = await OmsService.updateBulkSummary(p);

    console.log("update resp --> ", r);

    // After the API call, update only the specific summary card
    setSummaryCard(
      produce((draft) => {
        const card = draft.find((x) => x.label === title);
        if (card) {
          card.loading = false;
          card.value = r.resp[0] ? r.resp[0].total : 0;
        }
      })
    );

    setShowModal(false);
  };
  const onResetCb = ({ title, value }) => {
    console.log("reset button fired", title, value);
    setModalData({ title, value });
    setShowModal(true);
  };
  const sortCb = useCallback(
    (data) => {
      sortRef.current = { ...data };
      applyFilter(false);
    },
    [applyFilter]
  );

  return (
    <>
      {/* Page title Info */}{" "}
      <div className="mt-2">
        <PageInfo
          title="MARKET PLACE - BULK ORDER IMPORT LOG"
          breadcrumbs={listView.breadcrumb}
          navigate={navigate}
        />
      </div>
      {/* summary */}
      {/* <div className="row align-self-center mb-2">
        {summaryCard.map((e, k) => (
          <div className="col-3" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              template={3}
              isResetable={true}
              callback={onResetCb}
            />
          </div>
        ))}
      </div> */}
      <div className="row mb-3">
        <div className="col-6">
          <FilterForm callback={filterFormCb} />
        </div>
      </div>
      {filterLabels.length > 0 ? (
        <div className="mb-3">
          <AppliedFilterLabel labels={filterLabels} />
        </div>
      ) : null}
      <AppCard title="summary">
        <div className="row">
          {summaryCard.map((e, k) => (
            <div className="col-6 d-flex mb-2" key={k}>
              <table className="table mb-0">
                <tbody>
                  <tr>
                    <td className="col-4 p-0 text-secondary">{e.label}</td>
                    <td className="col-1 p-0">
                      <span className="ps-3">
                        {e.loading ? (
                          <Spinner isSmall={true} type="dots" />
                        ) : (
                          e.value
                        )}
                      </span>
                    </td>
                    <td className="col-1 p-0">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() =>
                          onResetCb({ title: e.label, value: e.value })
                        }
                        disabled={e.loading}
                      >
                        Reset
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </AppCard>
      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        sort={sortRef.current}
        sortCb={sortCb}
        moreColumns={selecteMoreTblColumns}
      />
      {showModal && (
        <ResetConfirmationModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onConfirm={handleReset}
          modalData={modalData}
        />
      )}
    </>
  );
};
export default BulkOrderImportTracker;
