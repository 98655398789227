import { NavService, OmsService } from "@sk/services";
import {
  Alert,
  BusyLoader,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  SelectInput,
  SummaryCard,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
  Toaster,
} from "@sk/uis";
import produce from "immer";
import { debounce, get, uniqBy } from "lodash";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { batchView } from "../../constantService";
import SubOrderProductModal from "../modal/SubOrderProductModal";

const tableHeader = [
  { label: "Sl.no" },
  { label: "Proforma No" },
  { label: "Franchise Name" },
  { label: "State" },
  { label: "District" },
  { label: "Order ID" },
  { label: "Ordered On" },
  { label: "Invoice No" },
  { label: "Partner ASN" },
  { label: "Status" },
  { label: "Action", width: "8%" },
];

const summaryData = batchView.summaryData;
const defaultFilterData = batchView.filterFormData;

const statusOptions = [
  { label: "Select Status", value: "" },
  { label: "Pending", value: "Pending" },
  { label: "Completed", value: "Completed" },
  { label: "Cancelled", value: "Cancelled" },
];

const Table = ({ data, batchData, callback }) => {
  const [summaryCard, setSummaryCard] = useState([...summaryData]);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [showBusyLoader, setShowBusyLoader] = useState(false);

  const [showProductModal, setShowProductModal] = useState(false);

  const [ProformaData, setProformaData] = useState([]);

  const [selectBoxOptions, setSelectBoxOptions] = useState({
    stateOptions: [{ label: "Select State", value: "" }],
    franchiseOptions: [{ label: "Select Franchise", value: "" }],
  });

  const filterDataRef = useRef({ ...defaultFilterData });

  const paginationRef = useRef({ ...batchView.pagination });

  const productModalRef = useRef({ data: [], id: "" });

  const isCancelledVisible = useMemo(() => {
    return ["Pending", "Processing", "Created"].includes(batchData.status);
  }, [batchData]);

  const { register, getValues } = useForm();

  useEffect(() => {
    init();
  }, [init]);

  const actionConfirmation = useCallback(async (confirmationMsg) => {
    let res = await Alert.confirm({
      title: "Please confirm",
      text: confirmationMsg,
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    return res.isConfirmed;
  }, []);

  const productModalCb = useCallback((status) => {
    productModalRef.current.data = [];
    productModalRef.current.id = "";
    setShowProductModal(false);
  }, []);

  const openProductModal = useCallback((d) => {
    productModalRef.current.id = d._id;

    const subOrders = d?.subOrders;

    const subOrderProducts = subOrders.flatMap((x) =>
      x.products.map((p) => ({
        orderId: x._id, // _id from suborder
        dealName: x.name, // name from suborder
        productName: p.name, // name from suborder.products
        mrp: x.mrp, // suborder mrp
        price: p.mrp * p.quantity, // price suborder.products
        qty: p.quantity, // qty suborder.products
      }))
    );

    productModalRef.current.data = subOrderProducts;
    setShowProductModal(true);
  }, []);

  const getFilterCondition = useCallback(() => {
    const filter = filterDataRef.current;

    const proformaNo = filter?.proformaNo?.trim();

    const p = {
      proformaNo: { key: "performaId", value: proformaNo },
      state: { key: "franchise.state", value: filter.state },
      status: { key: "status", value: filter.status },
      franchise: { key: "franchise.id", value: filter.franchise },
    };

    return p;
  }, []);

  const navToScanInvoiceProforma = useCallback(
    (proformaId) => {
      NavService.openInNewTab("/oms/scan-invoice-proforma", {
        proformaId,
        batchId: batchData._id,
      });
    },
    [batchData]
  );

  const loadOptions = useCallback(() => {
    let states = uniqBy(data, (x) => x.franchise?.state).map((x) => ({
      label: x?.franchise?.state,
      value: x?.franchise?.state,
    }));

    let franchises = uniqBy(data, (x) => x.franchise?.id).map((x) => ({
      label: x?.franchise?.name,
      value: x?.franchise?.id,
    }));

    setSelectBoxOptions(
      produce((draft) => {
        draft.franchiseOptions = [draft.franchiseOptions[0], ...franchises];
        draft.stateOptions = [draft.stateOptions[0], ...states];
      })
    );
  }, [data]);

  const loadSummary = useCallback(() => {
    const statusCount = data.reduce((acc, curr) => {
      acc[curr.status] = (acc[curr.status] || 0) + 1;
      return acc;
    }, {});

    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.value = statusCount[x.status] || 0;
          x.loading = false;
        });
        draft[0].value = data.length;
      })
    );
  }, [data]);

  const loadList = useCallback(() => {
    setLoading(true);
    const p = getFilterCondition();
    let list = Object.keys(p).reduce((acc, curr) => {
      let currentFilter = p[curr];

      if (currentFilter.value) {
        return acc.filter((x) =>
          get(x, currentFilter.key).includes(currentFilter.value)
        );
      }

      return acc;
    }, data);

    paginationRef.current.totalRecords = list.length;

    setProformaData(
      list.slice(
        paginationRef.current.startSlNo - 1,
        paginationRef.current.endSlNo
      )
    );
    setLoading(false);
  }, [data, getFilterCondition]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const applyFilter = useCallback(
    (isSummaryRequired = false) => {
      paginationRef.current = { ...batchView.pagination };

      if (isSummaryRequired) {
        loadSummary();
      }

      loadList();
    },
    [loadSummary, loadList]
  );

  const filterCb = useCallback(
    debounce(() => {
      filterDataRef.current = {
        ...getValues(),
      };
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  const init = useCallback(() => {
    loadOptions();
    applyFilter(true);
  }, [applyFilter, loadOptions]);

  const cancelProforma = useCallback(
    async (id) => {
      let msg = `Do you want to cancel #${id} Proforma ?`;

      const isConfirmed = await actionConfirmation(msg);
      if (!isConfirmed) return;

      setShowBusyLoader(true);
      const p = {
        performaInvoiceNo: id,
      };
      const r = await OmsService.cancelProforma(id, p);
      if (r.statusCode != 200) {
        Toaster.error(r.resp.message);
      } else {
        Toaster.success(r.resp.message);
        const t = setTimeout(() => {
          callback({ status: "Cancelled" });
        }, 1000);
      }
      setShowBusyLoader(false);
    },
    [callback, actionConfirmation]
  );

  const cancelBatch = useCallback(async () => {
    let msg = `Do you want to cancel Batch #${batchData._id} ?`;

    const isConfirmed = await actionConfirmation(msg);

    if (!isConfirmed) return;

    setShowBusyLoader(true);

    const p = {
      batchId: batchData._id,
      whId: batchData.whId,
      status: "Cancelled", // to cancel batch
      isSkWarehouse: true,
    };

    const r = await OmsService.cancelBatch(p);

    if (r.statusCode != 200) {
      Toaster.error(r.resp.message);
    } else {
      Toaster.success(r.resp.message);

      const t = setTimeout(() => {
        NavService.replace(navigate, "/oms/batch-processing");

        clearTimeout(t);
      }, 1000);
    }

    setShowBusyLoader(false);
  }, [actionConfirmation, batchData, navigate]);

  return (
    <>
      <div className="row align-self-center mb-3">
        {summaryCard.map((e, k) => (
          <div className="col-2" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              template={2}
            />
          </div>
        ))}
      </div>

      <div className="row align-items-center">
        <div className="col-12">
          <div className="row">
            {/* Brand Search Input */}
            <div className="col-2 align-self-center">
              <TextInput
                name="proformaNo"
                type="text"
                register={register}
                callback={filterCb}
                placeholder="Search By Proforma ID"
              />
            </div>
            <div className="col-2">
              <SelectInput
                name="state"
                register={register}
                options={selectBoxOptions.stateOptions}
                disabled={!selectBoxOptions.stateOptions.length}
                callback={filterCb}
              />
            </div>
            <div className="col-2">
              <SelectInput
                name="franchise"
                register={register}
                options={selectBoxOptions.franchiseOptions}
                disabled={!selectBoxOptions.franchiseOptions.length}
                callback={filterCb}
              />
            </div>

            <div className="col-2">
              <SelectInput
                name="status"
                register={register}
                options={statusOptions}
                callback={filterCb}
              />
            </div>

            <div className="col-4 text-end">
              {isCancelledVisible && (
                <button
                  className=" btn-sm btn btn-danger fs-val-sm"
                  onClick={cancelBatch}
                >
                  Cancel Batch
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={false}
        />
      </div>

      <table className="table table-bordered">
        <TableHeader data={tableHeader} />
        <tbody>
          {loading && (
            <TableSkeletonLoader
              rows={10}
              cols={tableHeader.length}
              height={40}
            />
          )}

          {!loading && !ProformaData.length ? (
            <tr className="fs-val-md">
              <td colSpan={tableHeader.length}>
                <NoDataFound> No Data Found </NoDataFound>
              </td>
            </tr>
          ) : null}

          {!loading && ProformaData.length
            ? ProformaData.map((x, i) => (
                <tr className="fs-val-md" key={i}>
                  <td>{paginationRef.current.startSlNo + i}</td>

                  <td>{x.performaId}</td>

                  <td className="text-primary">
                    {x.franchise?.name} - {x.franchise?.id}
                  </td>

                  <td>{x.franchise?.state}</td>

                  <td>{x.franchise?.district}</td>

                  <td className="text-primary">{x.orderId}</td>

                  <td>
                    <DateFormatter date={x.OrderedOn} />
                  </td>

                  <td>{x.subOrders?.[0]?.invoiceNo || "N/A"}</td>

                  <td>{x?.partnerAsnNo || "N/A"}</td>

                  <td>
                    <HighlightText status={x.status} />
                  </td>
                  <td>
                    {x.status == "Pending" && (
                      <>
                        <button
                          className="btn btn-sm btn-outline-primary fs-val-xs mb-2 w-100"
                          onClick={() => navToScanInvoiceProforma(x.performaId)}
                        >
                          Prepare Invoice
                        </button>

                        <button
                          className="btn btn-sm btn-outline-danger fs-val-xs mb-2 w-100"
                          onClick={() => cancelProforma(x.performaId)}
                        >
                          Cancel Proforma
                        </button>
                      </>
                    )}

                    <button
                      className="btn btn-sm btn-outline-success fs-val-xs mb-2 w-100"
                      onClick={() => openProductModal(x)}
                    >
                      View Orders
                    </button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>

      <PaginationBlock
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={false}
      />

      <SubOrderProductModal
        batchId={productModalRef.current.id}
        data={productModalRef.current.data}
        show={showProductModal}
        callback={productModalCb}
      />

      <BusyLoader show={showBusyLoader} />
    </>
  );
};

export default memo(Table);
