import { useAttachAdditionalData, useFetchUrlQueryString } from "@sk/hooks";

import { OmsService } from "@sk/services";

import { PageInfo, PageLoader, NoDataFound } from "@sk/uis";

import { useCallback, useEffect, useMemo, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { batchView } from "../constantService";
import BasicDetails from "./components/BasicDetails";
import Table from "./components/Table";

const getData = async (id) => {
  const r = await OmsService.getBatchById(id);
  return r.statusCode == 200
    ? { ...r.resp, userLoading: true, warehouseLoading: true }
    : {};
};

const breadcrumbs = batchView.breadcrumb;
const attachAdditionalDataConfig = batchView.additionalDataConfig;
const ViewBatch = () => {
  const [data, setData] = useState({});

  const [display, setDisplay] = useState("loading");

  const [searchParams] = useSearchParams();

  const query = useFetchUrlQueryString(searchParams);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const batchDetails = useMemo(() => {
    return {
      id: data._id,
      status: data.status,
      date: data.createdAt,
      createdBy: data.createdBy,
    };
  }, [data]);

  const warehouseDetails = useMemo(() => {
    return {
      ...data.warehouseAddress,
      name: data?._warehouse?.name,
      id: data?.whId,
    };
  }, [data]);

  const navigate = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const loadBatchDetails = useCallback(
    async (id) => {
      setDisplay("loading");
      const d = await getData(id);
      if (!d._id) {
        setDisplay("noDataFound");
        return;
      }

      if (d._id) {
        let tmp = [];
        // Attach User Info
        setAdditionalData([d], attachAdditionalDataConfig, (x) => {
          tmp.push(x);
          if (tmp.length == attachAdditionalDataConfig.length) {
            const t = [...attachAllData([d], tmp)][0];
            setData((v) => ({ ...v, ...t }));
          }
        });
      }
      setData(d);
      setDisplay("details");
    },
    [attachAllData, setAdditionalData]
  );

  const init = useCallback(() => {
    if (query.id) {
      loadBatchDetails(query.id);
    } else {
      setDisplay("noDataFound");
    }
  }, [query.id, loadBatchDetails]);

  const proformaTableCb = useCallback(
    (action) => {
      if (action.status == "Cancelled") init();
    },
    [init]
  );

  return (
    <>
      <PageInfo
        title={`View Batch #${query.id}`}
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      {display == "loading" && <PageLoader />}

      {display == "noDataFound" && <NoDataFound> No Batch Found</NoDataFound>}

      {display == "details" && (
        <>
          <BasicDetails
            batchDetails={batchDetails}
            warehouseDetails={warehouseDetails}
          />

          <Table
            data={data.performa}
            key={data.performa.length}
            batchData={data}
            callback={proformaTableCb}
          />
        </>
      )}
    </>
  );
};

export default ViewBatch;
