import { BooleanCheckboxInput, InfoPopover, SelectInput } from "@sk/uis";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const OrderListAdvFilterModal = ({ show, callback }) => {
  const { register, control, reset, getValues } = useForm({
    defaultValues: {
      paymentStatus: "",
      showOnlyNoStock: false,
    },
  });

  const onClose = () => {
    callback(false);
  };

  const onReset = () => {
    reset({
      paymentStatus: "",
      showOnlyNoStock: false,
    });
    callback({
      status: "reset",
      data: {},
    });
  };

  const onApply = () => {
    callback({
      status: "apply",
      data: getValues(),
    });
  };
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0">Advanced Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SelectInput
          label="Payment Status"
          name="paymentStatus"
          register={register}
          options={paymentStatusOptions}
        />

        <div className="d-flex align-items-start gap-2">
          <Controller
            control={control}
            name="showOnlyNoStock"
            render={({ field }) => (
              <BooleanCheckboxInput
                label="Show Only No Stock"
                value={field.value}
                callback={field.onChange}
                info="Order placed with no stock"
              />
            )}
          />
          <div>
            <InfoPopover
              title="Show Only No Stock"
              content="Order placed but no stock in StoreKing"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={onReset}
        >
          Reset
        </button>
        <button type="button" className="btn btn-primary" onClick={onApply}>
          Apply
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const paymentStatusOptions = [
  { label: "All", value: "" },
  { label: "Paid", value: "Paid" },
  { label: "Unpaid", value: "Unpaid" },
];

export default OrderListAdvFilterModal;
