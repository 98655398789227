import { Amount, InputErrorMsg, TableHeader, Toaster } from "@sk/uis";

import { memo, useCallback, useEffect, useMemo } from "react";

import { Offcanvas } from "react-bootstrap";

import { Controller, useForm } from "react-hook-form";

import * as yup from "yup";

import { listView } from "../constantService";
import { cloneDeep } from "lodash";

const defaultFormData = {
  selectedSnapShot: "",
};

const style = {
  offCanvasStyle: {
    width: "45%",
  },
  offCanvasHeaderStyle: {
    backgroundColor: "#e4edff",
  },
};

const barcodeTableHeader = listView.barcodeTableHeader;

const validationSchema = yup.object({
  selectedSnapShot: yup.string().required("Please select item snapshot"),
});

const getAvailableQty = (snapshot, scannedProduct) => {
  const warehouseQtyDetails = scannedProduct.warehouseQtyDetails;

  let availableQty = snapshot.reduce((acc, curr) => acc + curr.quantity, 0);

  let alreadyScannedSnapshot = warehouseQtyDetails.filter((x) =>
    snapshot.find((curr) => curr._id == x.id)
  );

  if (!alreadyScannedSnapshot.length) {
    return availableQty;
  }

  availableQty =
    availableQty -
    alreadyScannedSnapshot.reduce((acc, cur) => acc + cur.qty, 0);
  return availableQty;
};

const BarcodeDetailsModal = ({
  show,
  callback,
  barcodeList,
  barcode,
  products,
}) => {
  const {
    register,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormData,
  });

  useEffect(() => {
    if (show) {
      resetFormData();
    }
  }, [show, resetFormData]);

  const resetFormData = useCallback(() => {
    () => {
      reset(defaultFormData);
    };
  }, [reset]);

  const closeModal = () => {
    callback({ status: "closed" });
  };

  const scannedProductIndex = useMemo(
    () => products.findIndex((x) => x?.productId == barcodeList[0]?.productId),
    [products, barcodeList]
  );

  let groupedBarcode = useMemo(() => {
    let grouped = barcodeList.reduce((result, item) => {
      let key = `${item.location}-${item.area}-${item.rackId}-${item.binId}-${item.mrp}`;
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item);
      return result;
    }, {});

    return Object.keys(grouped).map((key) => ({
      _grouped: grouped[key].sort((a, b) => b.quantity - a.quantity),
      availableQty: getAvailableQty(
        grouped[key],
        products[scannedProductIndex]
      ),
      name: `${grouped[key][0].areaDoc[0].name} - ${grouped[key][0].area} -${grouped[key][0].rackId} -${grouped[key][0].binId}`,
      mrp: grouped[key][0].mrp,
    }));
  }, [barcodeList, products, scannedProductIndex]);

  const prepareCallbackPayload = useCallback(
    // Only barcode Api response need to send rest will handle in scan proforma
    (index) => {
      const selectedSnapShot = cloneDeep(groupedBarcode[index]._grouped);
      return selectedSnapShot; // api response payload
    },
    [groupedBarcode]
  );

  const onSubmit = useCallback(async () => {
    let errorMsg = "";
    let index = "";

    try {
      const values = getValues();

      await validationSchema.validate(values);

      index = values.selectedSnapShot;
    } catch (err) {
      errorMsg = err.message;
    }

    if (errorMsg) return Toaster.error(errorMsg);

    const payload = prepareCallbackPayload(index);

    callback({ status: "success", payload });
  }, [callback, getValues, prepareCallbackPayload]);

  // console.log(groupedBarcode, products[scannedProductIndex]);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={style.offCanvasStyle}
      >
        <Offcanvas.Header
          closeButton
          style={style.offCanvasHeaderStyle}
          closeVariant="white"
        >
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> Barcode Details #{barcode} </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="row p-4  bg-light border-bottom mb-4">
            <div className="col-12 fs-val-lg text-primary fw-semibold mb-1">
              {products[scannedProductIndex]?.productName} -
              {products[scannedProductIndex]?.productId}
            </div>
            <div className="col-12">
              <span className="fs-val-md fw-semibold me-2">Required Qty :</span>
              <span className="fs-val-md fw-semibold ">
                {products[scannedProductIndex]?.scanQty} /
                {products[scannedProductIndex]?.totalQty}
              </span>
            </div>
          </div>
          <div className="row p-4">
            <div className="col-12 fs-val-lg fw-semibold mb-1">
              Barcode Table
            </div>
          </div>
          <InputErrorMsg msg={errors?.selectedSnapShot?.message} />
          <table className="table table-bordered">
            <TableHeader data={barcodeTableHeader} />
            <tbody>
              {groupedBarcode.map((x, i) => (
                <tr className="fs-val-md" key={i}>
                  <td className="text-center">{i + 1}</td>
                  <td className="text-center">
                    <Amount value={x.mrp} decimalPlace={2} />
                  </td>
                  <td>{x.name}</td>
                  <td className="text-center">{x.availableQty}</td>
                  <td className="text-center">
                    <Controller
                      key={i}
                      control={control}
                      name="selectedSnapShot"
                      className="form-check-input"
                      render={({ field }) => (
                        <div>
                          <input
                            {...field}
                            type="radio"
                            value={i}
                            checked={field.value === String(i)}
                          />
                        </div>
                      )}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Offcanvas.Body>

        <Offcanvas.Header>
          <div className="col-12 text-end">
            <button className="btn btn-primary fs-val-md" onClick={onSubmit}>
              Submit
            </button>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
};

export default memo(BarcodeDetailsModal);
