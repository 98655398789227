import { Chip, KeyVal, Alert } from "@sk/uis";
import classNames from "classnames";
import { memo, useCallback, useMemo } from "react";
// const style = {
//   cardStyle: {
//     // height: "65vh",
//     // overflowY: "scroll",
//   },
// };

const preparePayload = (d) => {
  return {
    index: d.uniqKey,
  };
};
const PackScanSelectedBlock = ({
  data,
  dispatch,
  openMeasurementModal,
  boxDetails,
}) => {
  const canSubmitAllocation = useMemo(
    () => boxDetails.isDetailsVisible && data.length,
    [boxDetails.isDetailsVisible, data.length]
  );

  const submitConfirmation = useCallback(async () => {
    // asking confirm to submit
    let res = await Alert.confirm({
      title: "Please confirm",
      text: `Do you want to Submit Allocation for selected packages IDs ? `,
      icon: "info",
      okText: "Yes",
      cancelText: "No",
    });

    return res.isConfirmed;
  }, []);

  const onSubmitAllocation = useCallback(async () => {
    const isConfirmed = await submitConfirmation();

    if (canSubmitAllocation && isConfirmed) {
      dispatch({ type: "SUBMIT_ALLOCATION" });
    }
  }, [canSubmitAllocation, dispatch, submitConfirmation]);

  const onSelectedPackageRemove = useCallback(
    (d) => {
      if (d.action == "remove") {
        dispatch({
          type: "REMOVE_PACKAGE",
          payload: preparePayload(d),
        });
      }
    },
    [dispatch]
  );

  return (
    <div className={"card rounded mx-0"}>
      <div className="card-header">
        <h4 className="card-title row align-items-center py-1">
          <div className="col-auto">Selected Scanned Packages</div>
          <div className="col-auto">
            <span className="badge badge-soft-primary"> {data.length}</span>
          </div>
          {!canSubmitAllocation ? (
            <div className="col-auto ms-auto">
              <button
                className="btn btn-sm btn-outline-primary"
                onClick={openMeasurementModal}
              >
                Allocate Box Dimension
              </button>
            </div>
          ) : (
            <div className="col-auto ms-auto">
              <button
                className="btn btn-primary fs-val-md"
                onClick={onSubmitAllocation}
              >
                Submit Allocation
              </button>
            </div>
          )}
        </h4>
      </div>
      <div className={classNames("card-body p-4")}>
        {boxDetails.isDetailsVisible && (
          <div className="row mb-2 border-bottom">
            <div className="col-12 fs-val-lg fw-semibold text-primary mb-2">
              Box Measurement Details
            </div>
            <div className="col-3 mb-2">
              <KeyVal template="col" label="Length (cm)">
                {boxDetails.length}
              </KeyVal>
            </div>
            <div className="col-3 mb-2">
              <KeyVal template="col" label="Height (cm)">
                {boxDetails.height}
              </KeyVal>
            </div>
            <div className="col-3 mb-2">
              <KeyVal template="col" label="Width (cm)">
                {boxDetails.width}
              </KeyVal>
            </div>
            <div className="col-3 mb-2">
              <KeyVal template="col" label="Weight (kg)">
                {boxDetails.weight}
              </KeyVal>
            </div>
          </div>
        )}

        {data.length == 0 && (
          <div className="fs-val-lg text-center pt-5 gw-semibold">
            No Package Selected Yet
          </div>
        )}
        {data.length
          ? data.map((x, i) => (
              <Chip
                key={i}
                label={x}
                uniqKey={i}
                showRemove={true}
                callback={onSelectedPackageRemove}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default memo(PackScanSelectedBlock);
