import {
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  DateFormatter,
} from "@sk/uis";
import { containerStyle, tableStyle } from "../constants";

const headers = [
  { label: "", width: "3%" },
  { label: "Sl No", width: "3%" },
  { label: "Product Name", width: "20%", isSticky: true },
  { label: "Product ID", width: "8%" },
  { label: "Order Qty", width: "5%", isCentered: true },
  { label: "Reserve Qty", width: "5%", isCentered: true },
  { label: "Open PO", width: "5%", isCentered: true },
  { label: "Partial PO", width: "5%", isCentered: true },
  { label: "Recent Order Date", width: "8%" },
  { label: "Vendor", width: "12%" },
  { label: "Menu", width: "12%" },
  { label: "Category", width: "12%" },
  { label: "Brand", width: "12%" },
];

const stickyColumnStyle = {
  position: "sticky",
  left: 0,
  backgroundColor: "white",
  zIndex: 1,
};

const bgCellStyle = {
  openPo: {
    backgroundColor: "rgba(255, 99, 71, 0.1)", // Very light red color
  },
  partialPo: {
    backgroundColor: "rgba(255, 193, 7, 0.1)", // Very light warning color
  },
  orderQty: {
    backgroundColor: "rgba(255, 99, 71, 0.1)", // Very light red color
  },
  reserveQty: {
    backgroundColor: "rgba(255, 193, 7, 0.1)", // Very light warning color
  },
};

const PurchaseOrderTable = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  onFilterClick,
  selectedProducts,
  onProductSelect,
}) => {
  return (
    <>
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-2"
        style={containerStyle}
      >
        <table className="table table-sm bg-white" style={tableStyle}>
          <TableHeader data={headers} noBg={true} isSticky={true} />
          <tbody className="fs-val-md">
            {loading ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={paginationConfig.rowsPerPage}
              />
            ) : null}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No purchase orders found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {data.map((item, index) => (
              <tr key={item.productId}>
                <td className="text-center">
                  <div className="d-flex justify-content-center">
                    <input
                      type="checkbox"
                      className="form-check-input cursor-pointer m-0"
                      checked={selectedProducts.some(
                        (p) => p.productId === item.productId
                      )}
                      onChange={(e) => onProductSelect(item, e.target.checked)}
                    />
                  </div>
                </td>
                <td>{paginationConfig.startSlNo + index}</td>
                <td style={stickyColumnStyle}>
                  <button
                    className="btn btn-link text-dark text-start fs-val-md p-0 text-wrap"
                    onClick={() =>
                      onFilterClick?.("product", {
                        name: item.productName,
                        _id: item.productId,
                      })
                    }
                  >
                    {item.productName}
                  </button>
                </td>
                <td>{item.productId}</td>
                <td className="text-center" style={bgCellStyle.orderQty}>
                  {item.orderQty || 0}
                </td>
                <td className="text-center" style={bgCellStyle.reserveQty}>
                  {item.reserveQty || 0}
                </td>
                <td className="text-center" style={bgCellStyle.openPo}>
                  {item.openPOQty || 0}
                </td>
                <td className="text-center" style={bgCellStyle.partialPo}>
                  {item.partialPOQty || 0}
                </td>
                <td>
                  <DateFormatter
                    date={item.recentOrderDate}
                    format="dd MMM yyyy"
                  />
                </td>
                <td>Ram Distributors Pvt Ltd</td>
                <td>
                  <button
                    className="btn btn-link text-dark text-start fs-val-md p-0"
                    onClick={() =>
                      onFilterClick?.("menu", {
                        name: item.menuName,
                        _id: item.menuId,
                      })
                    }
                  >
                    {item.menuName}
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-link text-dark text-start fs-val-md p-0"
                    onClick={() =>
                      onFilterClick?.("category", {
                        name: item.categoryName,
                        _id: item.categoryId,
                      })
                    }
                  >
                    {item.categoryName}
                  </button>
                </td>
                <td>
                  <button
                    className="btn btn-link text-dark text-start fs-val-md p-0"
                    onClick={() =>
                      onFilterClick?.("brand", {
                        name: item.brandName,
                        _id: item.brandId,
                      })
                    }
                  >
                    {item.brandName}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-3">
        <PaginationBlock
          paginationConfig={paginationConfig}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
          size="sm"
        />
      </div>
    </>
  );
};

export default PurchaseOrderTable;
