import { Amount, HighlightText, KeyVal } from "@sk/uis";

const OrderDetailsBasic = ({ data }) => {
  return (
    <>
      <div className="row">
        <div className="col-4">
          <div className="fs-val-normal fw-bold mb-2">
            <i className="bi bi-file-earmark-text"></i> Order Summary
          </div>
          <KeyVal label="Order Amount">
            <span className="fw-bold text-danger">
              <Amount value={data?.orderAmount} />
            </span>
          </KeyVal>

          <KeyVal label="Order Type">
            <span>{data?.orderType}</span>
          </KeyVal>
          <KeyVal label="Type of Order">
            <span>{data?.typeOfOrder}</span>
          </KeyVal>
          <KeyVal label="Platform Type">
            <span>{data?.platformType}</span>
          </KeyVal>
        </div>

        <div className="col-6">
          <div className="fs-val-normal fw-bold mb-2">
            <i className="bi bi-credit-card"></i> Payment Summary
          </div>
          <KeyVal label="Payment Mode">
            <span className="me-2">{data?.paymentMode}</span>
            <HighlightText type="success" template={2}>
              {data?.paymentStatus}
            </HighlightText>
          </KeyVal>
          <KeyVal label="Wallet Type">
            <span className="text-uppercase">{data?.walletType}</span>
          </KeyVal>
          <KeyVal label="Provided By">
            <span>{data?.fulfilledBy}</span>
          </KeyVal>
        </div>
      </div>

      <hr className="my-4" />

      <div>
        <KeyVal label="Allocation Status" labelCol="col-auto" contentCol="col">
          <span className="fw-semibold">{data?.stockAllocation}</span>
        </KeyVal>
      </div>

      <hr className="my-4" />

      <div className="row">
        <div className="col-4">
          <div className="fs-val-normal fw-bold mb-2">
            <i className="bi bi-house"></i> Franchise Info
          </div>
          <div className="fw-bold">
            {data?.franchise?.name} - {data?.franchise?.id}
          </div>
          <div className="text-black-50">
            {data?.franchise?.address?.full_address}{" "}
            {data?.franchise?.address?.street}
          </div>
          <div className="text-black-50">
            {data?.franchise?.town}, {data?.franchise?.district},{" "}
            {data?.franchise?.state} - {data?.franchise?.pincode}
          </div>
        </div>

        <div className="col-4">
          <div className="fs-val-normal fw-bold mb-2">
            <i className="bi bi-person"></i> Billing Info
          </div>
          {data?.billToShipDiff ? (
            <>
              <div className="text-black-50">
                {data?.billingAddress?.line1} {data?.billingAddress?.line2}{" "}
                {data?.billingAddress?.landmark}
              </div>
              <div className="text-black-50">
                {data?.billingAddress?.town}, {data?.billingAddress?.district},
                {data?.billingAddress?.state} - {data?.billingAddress?.pincode}
              </div>
            </>
          ) : (
            <>
              <div className="text-black-50">
                {data?.franchise?.address?.full_address}{" "}
                {data?.franchise?.address?.street}
              </div>
              <div className="text-black-50">
                {data?.franchise?.town}, {data?.franchise?.district},{" "}
                {data?.franchise?.state} - {data?.franchise?.pincode}
              </div>
            </>
          )}
        </div>

        <div className="col-4">
          <div className="fs-val-normal fw-bold mb-2">
            <i className="bi bi-box"></i> Warehouse Info
          </div>
          <div className="fw-bold">{data?.warehouseAddress?.name}</div>
          <div className="text-black-50">
            {data?.warehouseAddress?.street} {data?.warehouseAddress?.landmark}
          </div>
          <div className="text-black-50">
            {data?.warehouseAddress?.city}, {data?.warehouseAddress?.district},
            {data?.warehouseAddress?.state} - {data?.warehouseAddress?.pincode}
          </div>
        </div>
      </div>

      <hr className="my-4" />

      <div>
        <div className="fs-val-normal fw-bold mb-2">
          <i className="bi bi-cash"></i> Commission
        </div>
        <div className="row">
          <div className="col">
            <KeyVal label="Logistics" labelCol="col-auto" contentCol="col">
              <Amount value={data?.commission?.logistics} />
            </KeyVal>
          </div>
          <div className="col">
            <KeyVal label="Sk Commission" labelCol="col-auto" contentCol="col">
              <Amount value={data?.commission?.skCommission} />
            </KeyVal>
          </div>
          <div className="col">
            <KeyVal
              label="Order Pass Through"
              labelCol="col-auto"
              contentCol="col"
            >
              <Amount value={data?.commission?.orderPassThrough} />
            </KeyVal>
          </div>
        </div>
        <div className="fs-val-md fw-semibold">
          Total Commission: <Amount value={data?.commission?.total} />
        </div>
      </div>
    </>
  );
};

export default OrderDetailsBasic;
