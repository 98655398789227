import { yupResolver } from "@hookform/resolvers/yup";
import {
  AuthService,
  CommonService,
  OmsService,
  UtilityService,
} from "@sk/services";
import {
  BooleanCheckboxInput,
  DatePickerInput,
  EntitySearchInput,
  InputErrorMsg,
  SelectInput,
  Spinner,
  TextInput,
  TextareaInput,
  Toaster,
} from "@sk/uis";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Offcanvas } from "react-bootstrap";

import { Controller, useForm, useWatch } from "react-hook-form";

import { produce } from "immer";

import * as yup from "yup";

const categoryStyle = {
  height: "300px",
  width: "100%",
  overflowY: "scroll",
};

const franchiseSearchFilter = {
  filter: {
    "sk_franchise_details.franchise_type": { $in: ["RF", "RMF", "WMF"] },
  },
};

const orderTillConfig = {
  maxDate: new Date(),
};

const defaultFormData = {
  warehouse: AuthService.getDefaultWhId(),
  batchBy: "",
  orderType: "",
  orderNumber: "",
  remarks: "",
  isPartialOrders: false,
  category: [],
  franchise: [],
  orderIds: "",
  orderTill: [],
  state: "",
  district: "",
  franchiseLevel: "",
};

const batchOptions = [
  { label: "Select Batching Type", value: "" },
  { label: "Category", value: "category" },
  { label: "Franchise", value: "franchise" },
  { label: "Order ID", value: "orderId" },
  { label: "Order Till", value: "orderTill" },
  { label: "State-wise", value: "stateWise" },
];

const orderTypeOptions = [
  { label: "Select Type", value: "" },
  { label: "B2B", value: "b2b" },
  { label: "B2C", value: "b2c" },
];

const WarehouseOptions = AuthService.getLoggedInEmpWh().map((x) => ({
  label: x.name,
  value: x._id,
}));

const defaultStateOptions = [
  { label: "Select State", value: "" },
  { label: "All States", value: "all" },
];

const defaultDistrictOptions = [
  { label: "Select District", value: "" },
  { label: "All States", value: "all" },
];

const franchiseLevelOptions = [
  { label: "Select level", value: "" },
  { label: "All Levels", value: "All Levels" },
  { label: "RMF", value: "All RMFs" },
  { label: "RF", value: "All RFs" },
  { label: "WHF", value: "All WHFs" },
  { label: "LMF", value: "All LMFs" },
];

const canvasStyle = { width: "35%" };

const getPendingOrderbyCategory = async (whId) => {
  const r = await OmsService.getOrderByCategory(whId);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        return { label: x.name, value: x, checked: false };
      })
    : [];
};

const validationSchema = yup.object({
  warehouse: yup.string().required("Warehouse is Required"),
  batchBy: yup.string().required("Batch By is Required"),
  orderType: yup.string().required("Order Type is required"),
  orderNumber: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .required("Order Qty is Required"),
  remarks: yup.string(),
  franchise: yup.array().when("batchBy", {
    is: (batchBy) => batchBy == "franchise",
    then: (schema) =>
      schema.label("Franchise").min(1, "Please Select Franchise"),
  }),
  orderIds: yup
    .string()
    .trim()
    .when("batchBy", {
      is: (batchBy) => batchBy == "orderId",
      then: (schema) =>
        schema
          .label("Order Ids")
          .required("Order Ids are Required")
          .test("Ids check", "Invalid Ids Entered", function (value) {
            const l = value.split(",");
            return l.every((order) => /^OR\d+/.test(order));
          })
          .test("length check", "Ids cannot be more than 20", function (value) {
            const l = value.split(",").length;
            return l;
          }),
    }),

  orderTill: yup.array().when("batchBy", {
    is: (batchBy) => batchBy == "orderTill",
    then: (schema) =>
      schema.label("Order Till Date").min(1, "Date is Required"),
  }),

  state: yup.string().when("batchBy", {
    is: (batchBy) => batchBy == "stateWise",
    then: (schema) => schema.label("State").required("State is Required"),
  }),
  district: yup.string().when("batchBy", {
    is: (batchBy) => batchBy == "stateWise",
    then: (schema) => schema.label("District").required("District is Required"),
  }),
  franchiseLevel: yup.string().when("batchBy", {
    is: (batchBy) => batchBy == "stateWise",
    then: (schema) =>
      schema.label("Franchise Level").required("Franchise Level is Required"),
  }),
});

const ManageBatchProcessing = ({ show, callback }) => {
  // React Hook Form To Handle Advance Filter Label

  const [categoryOptions, setCategoryOptions] = useState([]);

  const [categoryLoading, setCategoryLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const [stateLoading, setStateLoading] = useState(false);

  const [districtLoading, setDistrictLoading] = useState(false);

  const [stateOptions, setStateOptions] = useState([...defaultStateOptions]);

  const [districtOptions, setDistrictOptions] = useState([
    ...defaultDistrictOptions,
  ]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormData,
    resolver: yupResolver(validationSchema),
  });

  const selectedCategories = useMemo(() => {
    return categoryOptions
      .filter((x) => x.checked)
      .reduce((acc, cur) => {
        return Array.from(new Set([...acc, ...cur.value.categories]));
      }, []);
  }, [categoryOptions]);

  const batchBy = useWatch({
    control,
    name: "batchBy",
  });

  useEffect(() => {
    if (show) {
      reset(defaultFormData);
      setCategoryOptions([]);
    }
  }, [show, setValue, reset]);

  const onOrderNumberChange = useCallback((e) => {
    let val = e.target.value;
    val = CommonService.roundedByDecimalPlace(val, 0);
    if (val <= 0) {
      val = "";
    }
    e.target.value = val;
  }, []);

  const onOrderIdsChange = useCallback(
    (e) => {
      let val = e.target?.value?.trim().toUpperCase();
      let ids = val.split(",");
      if (ids.length && ids.join("")) {
        setValue("orderNumber", ids.length);
      } else {
        setValue("orderNumber", "");
      }
      e.target.value = val;
    },
    [setValue]
  );

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  const onCheckboxChange = useCallback(
    (checked, index) => {
      clearErrors("category");
      setCategoryOptions((prevData) => {
        return produce(prevData, (draft) => {
          draft[index].checked = checked;
        });
      });
    },
    [clearErrors]
  );

  const preparePayload = useCallback(
    (data) => {
      const p = {
        whId: data.warehouse,
        count: data.orderNumber,
        isPartial: data.isPartialOrders,
        remarks: data.remarks,
        isSkWarehouse: true,
        type: data.orderType,
      };

      if (data.batchBy == "category") {
        p.category = selectedCategories;
      }

      if (data.batchBy == "orderId") {
        p.orderId = data.orderIds.split(",");
      }

      if (data.batchBy == "franchise") {
        p.tillDate = data.franchise[0].value._id;
      }

      if (data.batchBy == "stateWise") {
        p.levels = [data.franchiseLevel];
        p.state = [data.state];
        p.district = [data.district];
      }

      return p;
    },
    [selectedCategories]
  );

  const createdExemptedBatch = useCallback(async (payload, type) => {
    const r = await OmsService.createBatch(
      { ...payload, taxType: "EXEMPTED" },
      type
    );

    return {
      type: r.statusCode === 200 ? "success" : "error",
      msg: r.statusCode == 200 ? "Created Successfully" : r.resp.message,
      taxType: "EXEMPTED",
    };
  }, []);

  const createdNonExemptedBatch = useCallback(async (payload, type) => {
    const r = await OmsService.createBatch(
      { ...payload, taxType: "NONEXEMPTED" },
      type
    );

    return {
      type: r.statusCode === 200 ? "success" : "error",
      msg: r.statusCode == 200 ? "Created Successfully" : r.resp.message,
      taxType: "NONEXEMPTED",
    };
  }, []);

  const createBatch = useCallback(
    async (payload) => {
      setLoading(true);
      const { type, ...restPayload } = payload;

      const responses = await Promise.all([
        createdExemptedBatch(restPayload, type),
        createdNonExemptedBatch(restPayload, type),
      ]);

      setLoading(false);

      responses.forEach(({ type, taxType, msg }) => {
        const message = `For ${taxType} : ${msg}`;

        type === "error" ? Toaster.error(message) : Toaster.success(message);

        if (type !== "error") callback({ status: "submitted" });
      });
    },
    [createdExemptedBatch, createdNonExemptedBatch, callback]
  );

  const onSubmit = useCallback(
    (data) => {
      if (data.batchBy == "category" && !selectedCategories.length) {
        setError("category", { message: "Please select category" });
        return;
      }
      const p = preparePayload(data);
      createBatch(p);
    },
    [preparePayload, createBatch, setError, selectedCategories]
  );

  const loadStates = useCallback(async () => {
    if (stateOptions.length > 2) {
      return;
    }
    setStateLoading(true);

    const r = await UtilityService.getStateList();

    let list = Array.isArray(r.resp)
      ? r.resp.map((x) => ({ label: x.name, value: x.name }))
      : [];

    setStateOptions((prev) => [...prev, ...list]);

    setStateLoading(false);
  }, [stateOptions.length]);

  const loadDistricts = useCallback(async (state) => {
    setDistrictLoading(true);

    const r = await UtilityService.getDistrictList(state);

    const list = Array.isArray(r.resp)
      ? r.resp.map((x) => ({ label: x.name, value: x.value }))
      : [];

    setDistrictOptions([...defaultDistrictOptions, ...list]);

    setDistrictLoading(false);
  }, []);

  const onStateChange = useCallback(
    async (state) => {
      if (state == "" || state == "all") {
        setDistrictOptions([...defaultDistrictOptions]);
        setValue("district", "");
        return;
      }

      loadDistricts(state);
    },
    [setValue, loadDistricts]
  );

  const loadCategory = useCallback(async () => {
    if (categoryOptions.length) {
      return;
    }
    setCategoryLoading(true);

    const warehouse = getValues("warehouse");

    const list = await getPendingOrderbyCategory(warehouse);

    setCategoryOptions(list);

    setCategoryLoading(false);
  }, [categoryOptions.length, getValues]);

  const onBatchByChange = useCallback(
    (batchBy) => {
      if (batchBy == "category") {
        loadCategory();
      } else if (batchBy == "stateWise") {
        loadStates();
      }
    },
    [loadCategory, loadStates]
  );

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
        backdrop="static"
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> Create Batch </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <div className="row">
              <div className="col-12">
                <SelectInput
                  label="Warehouse"
                  register={register}
                  name="warehouse"
                  isMandatory={true}
                  error={errors?.warehouse?.message}
                  options={WarehouseOptions}
                />
              </div>

              <div className="col-6">
                <SelectInput
                  label="Order Type"
                  register={register}
                  name="orderType"
                  isMandatory={true}
                  error={errors?.orderType?.message}
                  options={orderTypeOptions}
                />
              </div>

              <div className="col-6">
                <TextInput
                  label="No of Orders"
                  register={register}
                  name="orderNumber"
                  type="number"
                  placeholder="Enter order number here"
                  isMandatory={true}
                  error={errors?.orderNumber?.message}
                  callback={onOrderNumberChange}
                />
              </div>

              <div className="col-12">
                <TextareaInput
                  label="Remarks"
                  register={register}
                  name="remarks"
                  isMandatory={false}
                  note="maximum 250 charter allowed"
                  rows={2}
                />
              </div>

              <div className="col-12">
                <Controller
                  control={control}
                  name="isPartialOrders"
                  render={({ field: { onChange, value } }) => (
                    <BooleanCheckboxInput
                      label="Allow partial orders?"
                      name="isPartialOrders"
                      callback={onChange}
                      value={value}
                    />
                  )}
                />
              </div>

              <div className="col-12">
                <SelectInput
                  label="Batch By"
                  register={register}
                  name="batchBy"
                  error={errors?.batchBy?.message}
                  callback={onBatchByChange}
                  isMandatory={true}
                  options={batchOptions}
                />
              </div>

              {/* Batching Categories */}
              {batchBy == "category" && (
                <>
                  <div className="fs-val-lg fw-semibold mb-2">
                    Categories
                    <InputErrorMsg msg={errors?.category?.message} />
                  </div>
                  <div className="border border-1" style={categoryStyle}>
                    {categoryLoading && (
                      <div className="text-center mt-5 pt-5">
                        <Spinner />
                      </div>
                    )}
                    {!categoryLoading && (
                      <table className="table">
                        <tbody>
                          {categoryOptions.map((x, i) => (
                            <tr className="fs-val-md" key={x.label}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={x.checked}
                                  onChange={(e) =>
                                    onCheckboxChange(e.target.checked, i)
                                  }
                                />
                              </td>
                              <td>{x.label}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </>
              )}

              {/* Batch by Franchise */}
              {batchBy == "franchise" && (
                <div className="col-12 ">
                  <Controller
                    control={control}
                    name="franchise"
                    render={({ field: { onChange, value } }) => (
                      <EntitySearchInput
                        type="franchise"
                        label="Search for Smart Stores"
                        placeholder="Search by Name/ID"
                        value={value}
                        callback={onChange}
                        uid="franchise"
                        isMultiple={false}
                        isMandatory={true}
                        error={errors?.franchise?.message}
                        filterParams={franchiseSearchFilter}
                        emptyLabel="No Smart Stores found"
                      />
                    )}
                  />
                </div>
              )}

              {/* Batch By Order Ids  */}
              {batchBy == "orderId" && (
                <div className="col-12">
                  <TextareaInput
                    label="Order Ids"
                    register={register}
                    name="orderIds"
                    placeholder={"enter comma separated(,) order ids"}
                    isMandatory={true}
                    callback={onOrderIdsChange}
                    error={errors?.orderIds?.message}
                    note="Please enter 20 at one time"
                    rows={3}
                  />
                </div>
              )}
            </div>

            {/* Batch by Order Till */}
            {batchBy == "orderTill" && (
              <div className="col-12">
                <div className="col-12 mb-1">
                  <label className="mb-1 fs-val-md">Order Till</label>
                  <Controller
                    name="orderTill"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DatePickerInput
                        placeholder="Choose Order Date"
                        value={value}
                        inpChange={onChange}
                        config={orderTillConfig}
                      />
                    )}
                  />
                </div>
                <InputErrorMsg msg={errors?.orderTill?.message} />
              </div>
            )}

            {/* Batch By State-Wise */}

            {batchBy == "stateWise" && (
              <>
                <div className="col-12">
                  <div className="row">
                    <div className="col-4">
                      <SelectInput
                        label="Select State"
                        register={register}
                        name="state"
                        callback={onStateChange}
                        isMandatory={true}
                        error={errors?.state?.message}
                        options={stateOptions}
                        disabled={stateLoading}
                      />
                    </div>

                    <div className="col-4">
                      <SelectInput
                        label="Select District"
                        register={register}
                        name="district"
                        isMandatory={true}
                        error={errors?.district?.message}
                        disabled={districtLoading}
                        options={districtOptions}
                      />
                    </div>

                    <div className="col-4">
                      <SelectInput
                        label="Franchise Level"
                        register={register}
                        name="franchiseLevel"
                        isMandatory={true}
                        error={errors?.franchiseLevel?.message}
                        options={franchiseLevelOptions}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </Offcanvas.Body>
        <Offcanvas.Header>
          <div className="col-12 text-end w-100 mt-4">
            <button
              className="btn btn-primary fs-val-md"
              type="button"
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
            >
              Sumbit {loading ? <Spinner isSmall={true} /> : null}
            </button>
          </div>
        </Offcanvas.Header>
      </Offcanvas>
    </>
  );
};

export default memo(ManageBatchProcessing);
