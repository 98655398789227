import { sub } from "date-fns";
import { OmsService } from "@sk/services";

export const defaultFilter = {
  orderedOn: [sub(new Date(), { days: 30 }), new Date()],
  status: "",
  openSearch: "",
  franchise: [],
};

export const statusOptions = OmsService.getOrderGroupStatus();

export const defaultStatusSummary = OmsService.getOrderGroupStatus().map(
  (x) => ({
    ...x,
    loading: true,
  })
);
