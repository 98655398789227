const listView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Bulk Order Imports Tracker",
    },
  ],
  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  summaryData: [
    {
      label: "No active sub order found for processing invoice",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: "",
    },
    {
      label: "Invalid order status to process invoice",
      value: 0,
      loading: true,
      filter: {},
      color: "success",
      img: "",
    },
    {
      label: "Please choose valid order Ids",
      value: 0,
      loading: true,
      filter: {},
      color: "danger",
      img: "",
    },
    {
      label: "Sorry! Order alreday reeceived",
      value: 0,
      loading: true,
      filter: {},
      color: "danger",
      img: "",
    },
    {
      label: "Entered Qty for",
      value: 0,
      loading: true,
      filter: {},
      color: "danger",
      img: "",
    },
    {
      label: "Order details still not updated the delivery",
      value: 0,
      loading: true,
      filter: {},
      color: "danger",
      img: "",
    },
  ],
  formLabels: {
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    orderId: {
      label: "Order Id",
    },
    batchId: {
      label: "Batch Id",
    },
    isActive: {
      label: "Active",
    },
    isInvoiced: {
      label: "Invoiced",
    },
    codPaid: {
      label: "Cod",
    },
    delivered: {
      label: "Delivered",
    },
    shipped: {
      label: "Shipped",
    },
    packed: {
      label: "Packed",
    },
    processedAt: {
      label: "Processed at",
    },
  },
};

export default listView;
