import { NavService } from "@sk/services";
import {
  TableHeader,
  HighlightText,
  Amount,
  DateFormatter,
  BtnLink,
} from "@sk/uis";

const styles = {
  total: {
    backgroundColor: "#fffbf8",
  },
  qty: {
    backgroundColor: "#f3fff3",
  },
};

const headers = [
  { label: "Sl No", width: "30px" },
  { label: "Suborder ID", width: "70px" },
  {
    label: "Deal Name",
    width: "120px",
    isSticky: true,
    stickyPosition: "left",
  },
  { label: "Deal ID", width: "60px" },
  { label: "Order Qty", width: "50px", isCentered: true },
  { label: "Status", width: "70px" },
  { label: "Stock Allocated", width: "70px" },
  { label: "MRP (Rs.)", width: "50px", isCentered: true },
  { label: "B2B Price (Rs.)", width: "50px", isCentered: true },
  { label: "Coupon Disc.", width: "60px", isCentered: true },
  { label: "Commission", width: "60px", isCentered: true },
  { label: "B2B After Disc. (Rs.)", width: "70px", isCentered: true },
  { label: "Total (Rs.)", width: "60px", isCentered: true },
  { label: "Reserve Shipping Date", width: "80px" },
  { label: "Invoice No", width: "70px" },
  { label: "Product Name", width: "120px" },
  { label: "Product ID", width: "70px" },
  { label: "Category", width: "80px" },
  { label: "Brand", width: "80px" },
];

// Add the containerStyle and tableStyle definitions
const containerStyle = {
  maxHeight: "600px",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "2800px",
};

const OrderDetailsItems = ({ data }) => {
  const viewProduct = (id) => {
    NavService.openInNewTab("/cms/product/view", {
      id,
    });
  };

  return (
    <div
      className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-3 fixed-width-table rounded border"
      style={containerStyle}
    >
      <table className="table table-sm table-hover" style={tableStyle}>
        <TableHeader data={headers} isSticky={true} />
        <tbody className="fs-val-md">
          {data.map((item, index) => (
            <tr key={item._id}>
              <td>{index + 1}</td>
              <td>{item._id}</td>
              <td className="sticky-column start-0 bg-white">
                <div className="text-wrap">{item.name}</div>
              </td>
              <td>{item.id}</td>
              <td className="text-center fw-semibold" style={styles.qty}>
                {item.quantity}
              </td>
              <td>
                <HighlightText type={item.statusType || "success"} template={2}>
                  {item.status}
                </HighlightText>
              </td>
              <td
                className={
                  item.reservationStatus === "Closed"
                    ? "text-success"
                    : "text-danger"
                }
              >
                {item.reservationStatus === "Closed"
                  ? "Allocated"
                  : "Not Allocated"}
              </td>
              <td className="text-center">
                <Amount value={item.mrp} decimalPlace={2} hideSymbol={true} />
              </td>
              <td className="text-center">
                <Amount
                  value={item.b2bPrice}
                  decimalPlace={2}
                  hideSymbol={true}
                />
              </td>
              <td className="text-center">{item.couponDisc}</td>
              <td className="text-center">
                <Amount value={item.commission.amount} decimalPlace={2} />
              </td>
              <td className="text-center text-danger">
                <Amount
                  value={item.price - item.couponDisc}
                  decimalPlace={2}
                  hideSymbol={true}
                />
              </td>
              <td className="text-center fw-semibold" style={styles.total}>
                <Amount
                  value={item.price * item.quantity}
                  decimalPlace={2}
                  hideSymbol={true}
                />
              </td>

              <td>
                {item.reserveShippingDate ? (
                  <DateFormatter date={item.reserveShippingDate} />
                ) : (
                  "--"
                )}
              </td>
              <td>{item.invoiceNo ? item.invoiceNo : "--"}</td>
              <td>
                <BtnLink
                  className="fs-val-md text-wrap"
                  onClick={() => viewProduct(item.products[0]?.id)}
                >
                  {item.products[0]?.name || "N/A"}
                </BtnLink>
              </td>
              <td>{item.products[0]?.id || "N/A"}</td>
              <td className="text-wrap" style={{ width: "150px" }}>
                {item.category?.name || "N/A"}
              </td>
              <td className="text-wrap" style={{ width: "150px" }}>
                {item.brand?.name || "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderDetailsItems;
