import { DateInput, EntitySearchInput, SelectInput, TextInput } from "@sk/uis";
import { add, sub } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { defaultFilter, statusOptions } from "../constants"; // Import constants
import OrderListAdvFilterModal from "../modals/OrderListAdvFilterModal";

const dateConfig = {
  mode: "range",
  maxDate: add(new Date(), {
    years: 3,
  }),
};

const defaultFormData = {
  ...defaultFilter,
};

const OrderFilter = ({ callback, view }) => {
  const { control, register, getValues, setValue } = useForm({
    defaultValues: { ...defaultFormData },
  });

  const [showAdvFilter, setShowAdvFilter] = useState(false);

  const triggerCb = (data = {}) => {
    if (callback) {
      callback({ formData: { ...getValues(), ...data } });
    }
  };

  const onSearch = useCallback(
    debounce(() => {
      const searchValue = getValues("openSearch");

      // Clear date when search has value
      if (searchValue) {
        setValue("orderedOn", []);
      }
      // Reset date to default when search is cleared
      else {
        setValue("orderedOn", [
          sub(new Date(), { days: 90 }),
          add(new Date(), { days: 30 }),
        ]);
      }

      triggerCb();
    }, 800),
    [triggerCb, setValue, getValues]
  );

  const advFilterCb = ({ status, data }) => {
    setShowAdvFilter(false);

    if (status === "apply" || status === "reset") {
      triggerCb(data);
    }
  };

  return (
    <>
      <div className="row g-2">
        {/* First row - essential filters */}
        <div className="col-3">
          <TextInput
            label="Search"
            placeholder="Search by Order ID/Name"
            name="openSearch"
            register={register}
            callback={onSearch}
            gap={0}
          />
        </div>

        <div className="col-2">
          <Controller
            control={control}
            name="orderedOn"
            render={({ field }) => (
              <DateInput
                label="Ordered On"
                config={dateConfig}
                callback={(value) => {
                  field.onChange(value);
                  triggerCb();
                }}
                value={field.value}
                template={2}
                hideClose={true}
                gap={0}
              />
            )}
          />
        </div>

        {view !== "franchise" && (
          <div className="col-3">
            <Controller
              control={control}
              name="franchise"
              render={({ field }) => (
                <EntitySearchInput
                  label="Franchise"
                  callback={(value) => {
                    field.onChange(value);
                    triggerCb();
                  }}
                  value={field.value}
                  placeholder="Search by Franchise Name/ID"
                  type="franchise"
                  uid="orderFranchise"
                  gap={0}
                />
              )}
            />
          </div>
        )}

        <div className="col-2">
          <SelectInput
            label="Status"
            name="status"
            register={register}
            callback={triggerCb}
            options={statusOptions}
            defaultValue=""
            gap={0}
          />
        </div>

        {/* Footer with filter button */}
        <div className="col-auto align-self-end">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => setShowAdvFilter(true)}
          >
            <i className="bi bi-funnel"></i> More Filters
          </button>
        </div>
      </div>

      <OrderListAdvFilterModal show={showAdvFilter} callback={advFilterCb} />
    </>
  );
};

export default OrderFilter;
