import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Order ID", width: "10%" },
  { label: "Sub Order ID", width: "10%" },
  { label: "Product Name", width: "15%", enableSort: true },
  { label: "Product ID", width: "10%" },
  { label: "Order Qty", width: "8%", enableSort: true, isCentered: true },
  { label: "Order Value", width: "10%", enableSort: true, isCentered: true },
  { label: "Reserve Qty", width: "8%", enableSort: true, isCentered: true },
  { label: "Order Status", width: "10%" },
  { label: "Ageing", width: "5%", enableSort: true, isCentered: true },
  { label: "Order Date", width: "10%", enableSort: true },
  { label: "Action", width: "5%" },
  { label: "Retailer Name", width: "10%", enableSort: true },
  { label: "Retailer ID", width: "10%", enableSort: true },
  { label: "Menu", width: "10%", enableSort: true },
  { label: "Brand", width: "10%", enableSort: true },
  { label: "Category", width: "10%", enableSort: true },
];

const tableStyle = {
  minWidth: "2000px",
};

function OrderReserveTable({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  callback,
}) {
  const handleAction = (id) => {
    callback({ action: "view", id });
  };

  return (
    <>
      <div className="mb-2">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>
      <div className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-2">
        <table
          className="table table-sm mb-0 bg-white table-hover"
          style={tableStyle}
        >
          <TableHeader data={headers} />
          <tbody>
            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loading ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={40}
              />
            ) : null}

            {data.map((x, index) => (
              <tr key={x.orderId} className="fs-val-md">
                <td>{paginationConfig.startSlNo + index}</td>
                <td>{x._id}</td>
                <td>
                  {x._id}_{index}
                </td>
                <td>
                  <div style={{ width: "250px" }} className="text-wrap">
                    <button
                      className="btn btn-link p-0 fs-val-md text-dark text-start"
                      onClick={() => handleAction(x.subOrders?.[0]?.id)}
                    >
                      {x.subOrders?.[0]?.name}
                    </button>
                  </div>
                </td>
                <td>{x.subOrders?.[0]?.id}</td>
                <td className="text-center text-danger">
                  {x.subOrders?.[0]?.quantity}
                </td>
                <td className="text-center">
                  <Amount value={x.subOrders?.[0]?.price} />
                </td>
                <td className="text-center text-primary">
                  {x.subOrders?.[0]?.quantity}
                </td>
                <td>
                  <HighlightText type="success" template={2}>
                    {x.status}
                  </HighlightText>
                </td>
                <td>2 days</td>
                <td>
                  <DateFormatter date={x.createdAt} format="dd MMM yyyy" />
                </td>
                <td>
                  <button
                    className="btn btn-outline-danger fs-val-sm btn-sm"
                    onClick={() => handleAction(x.orderId)}
                  >
                    Cancel Order
                  </button>
                </td>
                <td>{x.franchise?.name}</td>
                <td>{x.franchise?.id}</td>
                <td>{x.menu || "-"}</td>
                <td>{x.brand || "-"}</td>
                <td>{x.category || "-"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
}

export default OrderReserveTable;
