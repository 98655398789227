import { memo } from "react";
import { Modal } from "react-bootstrap";

const PackageDetailsModal = ({ show, callback }) => {
  const triggerClose = (status = "closed") => {
    callback({ status });
  };

  return (
    <>
      <Modal show={show} onHide={triggerClose}>
        <Modal.Header closeButton>
          <div className="modal-title m-0 text-white"> Package Details </div>
        </Modal.Header>
        <Modal.Body className="rounded-bottom">
          Print Package Pick Slip Details
        </Modal.Body>
        <Modal.Footer>
          <div className="col-12 text-end">
            <button
              className="btn btn-outline-dark me-2"
              onClick={triggerClose}
            >
              Cancel
            </button>

            <button
              className="btn btn-outline-dark me-2"
              onClick={() => triggerClose("print")}
            >
              Print Package Scan
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PackageDetailsModal;
