import React from "react";
import {
  Amount,
  DateFormatter,
  HighlightText,
  InfoPopover,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { NavService } from "@sk/services";
import OrderStatusSummary from "./OrderStatusSummary";

const headers = [
  { label: "SL No", width: "5%" },
  { label: "Ordered On", enableSort: true, width: "8%", key: "createdAt" },
  { label: "Order Time", width: "6%" },
  { label: "Order ID", width: "10%" },
  { label: "Status", width: "10%" },
  { label: "Stock Allocation", width: "12%" },
  { label: "Order Amount (Rs.)", width: "10%" },
  { label: "Total Items", width: "10%" },
  { label: "Franchise", width: "20%" },
  { label: "Franchise ID", width: "10%" },
  { label: "Franchise Subtype", width: "10%" },
  { label: "Sold By", width: "10%" },
  { label: "Warehouse", width: "16%" },
  { label: "Payment Mode", width: "10%" },
  { label: "Payment Status", width: "10%" },
  { label: "PDP Date", width: "10%" },
  { label: "Action", width: "5%" },
];

const containerStyle = {
  maxHeight: "600px",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "2400px",
};

const OrderTable = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  const viewOrder = (orderId) => {
    NavService.openInNewTab(`/oms/orders/details?id=${orderId}`);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <PaginationSummary
          loadingTotalRecords={loadingTotalRecords}
          paginationConfig={paginationConfig}
        />
        {/* <div>
          <button className="btn btn-primary">Download</button>
        </div> */}
      </div>
      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-3 fixed-width-table border rounded"
        style={containerStyle}
      >
        <table
          className="table table-sm bg-white table-hover"
          style={tableStyle}
        >
          <TableHeader
            data={headers}
            isSticky={true}
            sort={sort}
            sortCb={sortCb}
          />
          <tbody className="fs-val-md">
            {loading ? (
              <TableSkeletonLoader rows={10} cols={headers.length} />
            ) : null}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>
                    <div>No orders found</div>
                  </NoDataFound>
                </td>
              </tr>
            ) : null}
            {data.map((order, index) => (
              <tr key={order._id}>
                <td>{paginationConfig.startSlNo + index}</td>
                <td>
                  <DateFormatter date={order.createdAt} format="dd MMM yyyy" />
                </td>
                <td>
                  <DateFormatter date={order.createdAt} format="hh:mm a" />
                </td>
                <td>
                  <button
                    className="btn btn-link p-0 fs-val-md text-dark text-start"
                    onClick={() => viewOrder(order._id)}
                  >
                    {order._id}
                  </button>
                </td>
                <td>
                  <HighlightText type={order.statusType} template={2}>
                    {order.status}
                  </HighlightText>
                  <InfoPopover
                    content={
                      <OrderStatusSummary summary={order.statusSummary} />
                    }
                  />
                </td>
                <td className="text-uppercase">{order.stockAllocation}</td>
                <td className="text-primary">
                  <Amount value={order.orderAmount} hideSymbol={true} />
                </td>
                <td>{order.subOrders?.length} items</td>
                <td>
                  <div className="text-wrap">{order.franchise?.name}</div>
                </td>
                <td>{order.franchise?.id}</td>
                <td>{order.franchise?.subType}</td>
                <td>{order.fulfilledBy}</td>
                <td>
                  <div className="text-wrap">
                    {order.warehouseAddress?.name}
                  </div>
                </td>
                <td>{order.paymentMode}</td>
                <td>{order.paymentStatus}</td>
                <td>
                  {order.pdpDate ? (
                    <DateFormatter
                      date={order.pdpDate}
                      format={"dd MMM yyyy"}
                    />
                  ) : (
                    "--"
                  )}
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary fs-val-sm me-2"
                    onClick={() => viewOrder(order._id)}
                  >
                    View
                  </button>
                  {/* Add more actions as needed */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

export default OrderTable;
