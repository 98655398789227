import { NavService } from "@sk/services";
import {
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  Rbac,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import classNames from "classnames";
import each from "lodash/each";
import orderBy from "lodash/orderBy";
import { memo, useEffect, useState } from "react";

const defaultHeaders = [
  {
    label: "Sl No",
    width: "auto",
    isCentered: true,
  },
  { label: "Order Id", isCentered: true, enableSort: true, key: "orderId" },
  { label: "Batch Id", isCentered: true, enableSort: true, key: "batchId" },

  { label: "Active", isCentered: true, enableSort: true, key: "active" },
  { label: "Invoiced", isCentered: true, enableSort: true, key: "invoiced" },
  {
    label: "Remarks",
    isCentered: true,
    enableSort: true,
    key: "remarks",
  },
  { label: "CodPaid", isCentered: true, enableSort: true, key: "codPaid" },
  {
    label: "Delivered",
    isCentered: true,
    enableSort: true,
    key: "deliveredAt",
  },
  { label: "Shipped", isCentered: true, enableSort: true, key: "shippedAt" },
  { label: "Packed", isCentered: true, enableSort: true, key: "packedAt" },
  {
    label: "Created At",
    isCentered: true,
    enableSort: true,
    key: "createdAt",
  },
  {
    label: "Processed At",
    isCentered: true,
    enableSort: true,
    key: "processedAt",
  },
];

const Table = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
  sort,
  sortCb,
}) => {
  // const [headers, setHeaders] = useState([...defaultHeaders]);

  // useEffect(() => {
  //   let t = [];
  //   each(moreColumns || {}, (x) => {
  //     t.push(x);
  //   });
  //   setHeaders(orderBy([...defaultHeaders, ...t], ["priority"], ["asc"]));
  // }, [moreColumns]);

  return (
    <>
      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationConfig}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      <table className="table table-bordered bg-white">
        <TableHeader data={defaultHeaders} sort={sort} sortCb={sortCb} />
        <tbody className="fs-val-md">
          {/* Table Loader */}
          {loading && (
            <TableSkeletonLoader
              rows={10}
              cols={defaultHeaders.length}
              height={40}
            />
          )}

          {/* No Data */}
          {!loading && !data.length ? (
            <tr>
              <td colSpan={defaultHeaders.length}>
                <NoDataFound>No Data Found</NoDataFound>
              </td>
            </tr>
          ) : null}
          {/* List  */}
          {!loading &&
            data.map((x, index) => (
              <tr key={x._id}>
                {/* SL No */}
                <td className="text-center">
                  {paginationConfig.startSlNo + index}
                </td>

                {/* OrderId */}
                <td>{x?.orderId}</td>
                {/* BatchId */}
                <td className="text-center">{x?.batchId}</td>

                {/* is Active */}
                <td className="text-center">{x?.isActive ? "Yes" : "No"}</td>

                {/* isInvoiced */}
                <td className="text-center">
                  {x?.isInvoiceCreated ? "Yes" : "No"}
                </td>

                {/* remarks */}
                <td className="text-center" style={{ width: "120px" }}>
                  {x?.remarks?.length > 0
                    ? x.remarks.map((remark) => (
                        <span key={remark}>{remark}</span>
                      ))
                    : "No Remarks"}
                </td>
                {/* isCodPaid */}
                <td className="text-center">{x?.isCODPaid ? "Yes" : "No"}</td>
                {/* Delivered */}
                <td className="text-center">{x?.isDelivered ? "Yes" : "No"}</td>

                {/* shipped */}
                <td className="text-center">{x?.isShipped ? "Yes" : "No"}</td>

                {/* packed */}
                <td className="text-center">{x?.isPacked ? "Yes" : "No"}</td>

                {/* CreatedAt */}
                <td className="text-center">
                  <DateFormatter date={x?.createdAt} />
                </td>

                {/* Processed At */}
                <td className="text-center">
                  <DateFormatter date={x?.processedAt} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        showSummary={false}
      />
    </>
  );
};

export default memo(Table);
