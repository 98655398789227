import { SummaryCard } from "@sk/uis";

const PurchaseOrderSummary = () => {
  return (
    <div className="row g-3">
      <div className="col-auto">
        <SummaryCard
          template={3}
          title="Open PO"
          value={150}
          valueColor="success"
          icon="bi bi-box-seam"
          noShadow={true}
        />
      </div>
      <div className="col-auto">
        <SummaryCard
          template={3}
          title="Partial PO"
          value={75}
          valueColor="warning"
          icon="bi bi-boxes"
          noShadow={true}
        />
      </div>
      <div className="col-auto">
        <SummaryCard
          template={3}
          title="No PO"
          value={25}
          valueColor="danger"
          icon="bi bi-box"
          noShadow={true}
        />
      </div>
    </div>
  );
};

export default PurchaseOrderSummary;
