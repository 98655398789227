import { ThemeInit } from "@sk/theme";
import React from "react";
import Router from "./Router";

import { ToasterContainer } from "@sk/uis";
import { LoadUserRoles } from "@sk/features";

export default function Root() {
  return (
    <React.StrictMode>
      <LoadUserRoles />
      <ThemeInit />
      <Router />
      <ToasterContainer />
    </React.StrictMode>
  );
}
