export const defaultFilterData = {
  search: "",
  poStatus: "",
  product: [],
  menu: [],
  category: [],
  brand: [],
};

export const defaultPaginationConfig = {
  activePage: 1,
  rowsPerPage: 25,
  totalRecords: 0,
  startSlNo: 1,
  endSlNo: 25,
};

export const tableStyle = {
  minWidth: "1800px",
};

export const containerStyle = {
  maxHeight: "500px",
  overflowY: "auto",
};
