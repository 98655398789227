import {
  BooleanCheckboxInput,
  DatePickerInput,
  RadioInput,
  TextInput,
} from "@sk/uis";
import { memo, useRef } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const canvasStyle = { width: "30%" };
const createdOnDateConfig = { mode: "range" };

const statusOptions = [
  { label: "All", value: "" },
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

const AdvanceFilterModal = ({ show, closeModal, filterData }) => {
  const { handleSubmit, reset, control, getValues, register } = useForm({
    defaultValues: {
      createdAt: "",
      orderId: "",
      batchId: "",
      active: "",
      invoiceCreated: "",
      codPaid: "",
      delivered: "",
      shipped: "",
      packed: "",
    },
  });

  const onSubmit = (d) => {
    filterData.current = { ...filterData.current, ...d };
    closeModal({ formData: d, status: "submit" });
  };

  const resetForm = () => {
    filterData.current = {};
    reset();
    closeModal({ formData: getValues(), status: "reset" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={() => {
          closeModal({ status: "close" });
        }}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <Offcanvas.Title>
            <div className="page-title"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="p-4  border-top">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {/* {createdAt} */}
              <div className="col-12 mb-4">
                <label className="mb-1 fs-val-md">Created On</label>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Choose"
                      value={value}
                      inpChange={onChange}
                      config={createdOnDateConfig}
                    />
                  )}
                />
              </div>
              {/* OrderId */}
              <div className="col-12 mb-4 pt-1">
                {
                  <TextInput
                    name="orderId"
                    type="text"
                    register={register}
                    placeholder="Search By order id"
                    label="Order"
                  />
                }
              </div>
              {/* BatchId */}
              <div className="col-12 mb-4 pt-1">
                {
                  <TextInput
                    name="batchId"
                    type="text"
                    register={register}
                    placeholder="Search By batch id"
                    label="Batch"
                  />
                }
              </div>
              {/* Active */}

              <div className="col-12 mb-4">
                <Controller
                  control={control}
                  name="active"
                  render={({ field: { onChange, value } }) => (
                    <RadioInput
                      label="Active"
                      gap={1}
                      name="active"
                      options={statusOptions}
                      isMandatory={false}
                      value={value}
                      cols="3"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              {/* Invoiced */}

              <div className="col-12 mb-4">
                <Controller
                  control={control}
                  name="invoiceCreated"
                  render={({ field: { onChange, value } }) => (
                    <RadioInput
                      label="Invoiced"
                      gap={1}
                      name="invoiceCreated"
                      options={statusOptions}
                      isMandatory={false}
                      value={value}
                      cols="3"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              {/* Codpaid*/}

              <div className="col-12 mb-4">
                <Controller
                  control={control}
                  name="codPaid"
                  render={({ field: { onChange, value } }) => (
                    <RadioInput
                      label="COD Paid"
                      gap={1}
                      name="codPaid"
                      options={statusOptions}
                      isMandatory={false}
                      value={value}
                      cols="3"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              {/* Delivered*/}

              <div className="col-12 mb-4">
                <Controller
                  control={control}
                  name="delivered"
                  render={({ field: { onChange, value } }) => (
                    <RadioInput
                      label="Delivered"
                      gap={1}
                      name="delivered"
                      options={statusOptions}
                      isMandatory={false}
                      value={value}
                      cols="3"
                      onChange={onChange}
                    />
                  )}
                />
              </div>

              {/* Shipped*/}

              <div className="col-12 mb-4">
                <Controller
                  control={control}
                  name="shipped"
                  render={({ field: { onChange, value } }) => (
                    <RadioInput
                      label="Shipped"
                      gap={1}
                      name="shipped"
                      options={statusOptions}
                      isMandatory={false}
                      value={value}
                      cols="3"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              {/* Packed*/}

              <div className="col-12 mb-4">
                <Controller
                  control={control}
                  name="packed"
                  render={({ field: { onChange, value } }) => (
                    <RadioInput
                      label="Packed"
                      gap={1}
                      name="packed"
                      options={statusOptions}
                      isMandatory={false}
                      value={value}
                      cols="3"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
          </form>
          {/* Action Buttons */}
          <div className="row mt-3">
            <div className="col-auto ms-auto">
              <button
                className="btn btn-warning me-3 px-4 fw-semibold"
                onClick={resetForm}
              >
                Reset
              </button>

              <button
                className="btn btn-primary px-4 fw-semibold"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(AdvanceFilterModal);
