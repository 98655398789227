import totalImg from "../../assets/imgs/total.svg";
import activeImg from "../../assets/imgs/active.svg";
import inactiveImg from "../../assets/imgs/inactive.svg";
import notLinkedImg from "../../assets/imgs/not-linked.svg";

export const listView = {
  summaryData: [
    {
      label: "Total",
      value: 0,
      loading: true,
      filter: {},
      color: "primary",
      img: totalImg,
    },
    {
      label: "Created",
      value: 0,
      loading: true,
      filter: { status: "Created" },
      color: "success",
      img: activeImg,
    },
    {
      label: "Processing",
      value: 0,
      loading: true,
      filter: { status: "Processing" },
      color: "danger",
      img: inactiveImg,
    },
    {
      label: "Pending",
      value: 0,
      loading: true,
      filter: { status: "Pending" },
      color: "danger",
      img: inactiveImg,
    },
    {
      label: "Completed",
      value: 0,
      loading: true,
      filter: { status: "Completed" },
      color: "danger",
      img: inactiveImg,
    },
    {
      label: "Cancelled",
      value: 0,
      loading: true,
      filter: { status: "Cancelled" },
      color: "danger",
      img: inactiveImg,
    },
  ],
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Batch Processing",
    },
  ],
  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },

  additionalTableDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_user",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { username: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "whId",
      api: "franchise",
      loadingKey: "warehouseLoading",
      dataKey: "_warehouse",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],

  formLabels: {
    createdAt: {
      label: "Created on",
      type: "dateRange",
    },
    status: {
      label: "Status",
    },
    taxType: {
      label: "Tax Type",
    },
    warehouse: {
      label: "Warehouse",
    },
  },

  filterFormData: {
    batchId: "",
    warehouse: "",
    status: "",
    createdAt: [],
    taxType: "",
  },
};

export const batchView = {
  breadcrumb: [
    {
      name: "Home",
      link: "/auth/init",
    },
    {
      name: "Batch List",
      link: "/oms/batch-processing",
    },
    {
      name: "View Batch",
    },
  ],
  additionalDataConfig: [
    {
      key: "createdBy",
      api: "user",
      loadingKey: "userLoading",
      dataKey: "_user",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { username: { $in: ids } },
        select: "name",
      }),
    },
    {
      key: "whId",
      api: "franchise",
      loadingKey: "warehouseLoading",
      dataKey: "_warehouse",
      filter: (ids) => ({
        page: 1,
        count: ids.length,
        filter: { _id: { $in: ids } },
        select: "name",
      }),
    },
  ],
  summaryData: [
    {
      label: "Total",
      value: 0,
      loading: true,
      color: "primary",
      img: totalImg,
      status: "",
    },

    {
      label: "Pending",
      value: 0,
      loading: true,
      color: "danger",
      img: inactiveImg,
      status: "Pending",
    },
    {
      label: "Completed",
      value: 0,
      loading: true,
      color: "danger",
      img: inactiveImg,
      status: "Completed",
    },
    {
      label: "Cancelled",
      value: 0,
      loading: true,
      color: "danger",
      img: inactiveImg,
      status: "Cancelled",
    },
  ],
  pagination: {
    totalRecords: 0,
    rowsPerPage: 10,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 10,
  },
  filterFormData: {
    proformaNo: "",
    state: "",
    status: "",
    franchise: "",
  },
};
