import { InfoPopover } from "@sk/uis";
import classNames from "classnames";
import { memo } from "react";

const PackScanSummaryCard = ({
  label,
  showInfo = false,
  infoContent,
  valueColor,
  value,
  bgColor,
}) => {
  return (
    <div
      className={classNames(
        "border rounded p-3 mb-2 d-flex flex-column  justify-content-between align-items-center"
      )}
      style={{ backgroundColor: bgColor, height: "105px" }}
    >
      <div className="fs-val-md fw-bold text-white text-center">
        {label}
        {showInfo ? (
          <span className="ms-1">
            <InfoPopover
              header={infoContent.title}
              content={infoContent.content}
            />
          </span>
        ) : null}
      </div>
      <div className={classNames("text-center font-22 fw-bold")}>
        <span className="text-white">{value}</span>
      </div>
    </div>
  );
};

export default memo(PackScanSummaryCard);
