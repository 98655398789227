import { AppCard, EntitySearchInput, SelectInput } from "@sk/uis";
import { memo, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const poStatusOptions = [
  { label: "All", value: "" },
  { label: "Open PO", value: "open" },
  { label: "Partial PO", value: "partial" },
  { label: "No PO", value: "no_po" },
];

const defaultFormValues = {
  search: "",
  poStatus: "",
  vendor: [],
  product: [],
  menu: [],
  category: [],
  brand: [],
};

const PurchaseOrderFilter = ({ callback, filterData }) => {
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const { register, control, getValues, setValue, reset } = useForm({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (filterData) {
      Object.keys(filterData).forEach((key) => {
        setValue(key, filterData[key]);
      });
    }
  }, [filterData, setValue]);

  const onInputChange = useCallback(() => {
    callback({ formData: getValues() });
  }, [callback, getValues]);

  const onSearch = useCallback(
    (chngFn) => (r) => {
      chngFn(r);
      callback({ action: "search", formData: getValues() });
    },
    [callback, getValues]
  );

  const onReset = () => {
    reset(defaultFormValues);
    callback({ action: "reset", formData: defaultFormValues });
  };

  return (
    <AppCard>
      <div className="row g-2">
        <div className="col-4">
          <Controller
            control={control}
            name="product"
            render={({ field: { value, onChange } }) => (
              <EntitySearchInput
                type="product"
                callback={onSearch(onChange)}
                label="Product"
                placeholder="Search by Product Name/ID"
                value={value}
                size="sm"
                gap={0}
              />
            )}
          />
        </div>

        <div className="col-4">
          <SelectInput
            label="PO Status"
            name="poStatus"
            options={poStatusOptions}
            register={register}
            callback={onInputChange}
            size="sm"
            gap={0}
          />
        </div>

        <div className="col-4">
          <Controller
            control={control}
            name="vendor"
            render={({ field: { value, onChange } }) => (
              <EntitySearchInput
                type="vendor"
                callback={onSearch(onChange)}
                label="Vendor"
                placeholder="Search by Vendor Name/ID"
                value={value}
                size="sm"
                gap={0}
              />
            )}
          />
        </div>

        {showMoreFilters && (
          <div className="col-12">
            <div className="row g-2">
              <div className="col-4">
                <Controller
                  control={control}
                  name="menu"
                  render={({ field: { value, onChange } }) => (
                    <EntitySearchInput
                      type="menu"
                      callback={onSearch(onChange)}
                      label="Menu"
                      placeholder="Search by Menu Name/ID"
                      value={value}
                      size="sm"
                      gap={0}
                      isMultiple={true}
                    />
                  )}
                />
              </div>

              <div className="col-4">
                <Controller
                  control={control}
                  name="category"
                  render={({ field: { value, onChange } }) => (
                    <EntitySearchInput
                      type="category"
                      callback={onSearch(onChange)}
                      label="Category"
                      placeholder="Search by Category Name/ID"
                      value={value}
                      size="sm"
                      gap={0}
                      isMultiple={true}
                    />
                  )}
                />
              </div>

              <div className="col-4">
                <Controller
                  control={control}
                  name="brand"
                  render={({ field: { value, onChange } }) => (
                    <EntitySearchInput
                      type="brand"
                      callback={onSearch(onChange)}
                      label="Brand"
                      placeholder="Search by Brand Name/ID"
                      value={value}
                      size="sm"
                      gap={0}
                      isMultiple={true}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        )}

        <div className="col-12">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <button
                type="button"
                className="btn btn-link text-primary p-0 fs-val-sm text-decoration-none"
                onClick={() => setShowMoreFilters(!showMoreFilters)}
              >
                {showMoreFilters ? (
                  <>
                    <i className="bi bi-chevron-up me-1"></i>
                    Show Less Filters
                  </>
                ) : (
                  <>
                    <i className="bi bi-chevron-down me-1"></i>
                    Show More Filters
                  </>
                )}
              </button>
            </div>
            <div className="ps-3">
              <button
                className="btn btn-warning btn-sm px-3"
                type="button"
                onClick={onReset}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default memo(PurchaseOrderFilter);
