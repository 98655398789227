import { Amount, NoDataFound, TableHeader } from "@sk/uis";
import { memo } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = { width: "50%" };
const header = [
  { label: "Sl.no" },
  { label: "Order ID" },
  { label: "Deal Name" },
  { label: "Product Name" },
  { label: "Mrp" },
  { label: "Price" },
  { label: "Quantity" },
];

const SubOrderProductModal = ({ show, callback, batchId, data }) => {
  const closeModal = () => {
    callback({ status: "closed" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg">
              Sub Order Product Details Batch : #{batchId}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="row border-bottom bg-light">
            <div className="col-12 p-4 fs-val-lg"> Product List</div>
          </div>
          <table className="table">
            <TableHeader data={header} />
            <tbody>
              {!data.length ? (
                <tr>
                  <td colSpan={header.length}>
                    <NoDataFound> No Products Found </NoDataFound>
                  </td>
                </tr>
              ) : null}

              {data.length
                ? data.map((x, i) => (
                    <tr className="fs-val-md" key={i}>
                      <td>{i + 1}</td>
                      <td>{x.orderId}</td>
                      <td>{x.dealName}</td>
                      <td>{x.productName}</td>
                      <td>
                        <Amount value={x.mrp} decimalPlace={2} />
                      </td>
                      <td>
                        <Amount value={x.price} decimalPlace={2} />
                      </td>
                      <td>{x.qty}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(SubOrderProductModal);
