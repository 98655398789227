import { yupResolver } from "@hookform/resolvers/yup";
import { OmsService } from "@sk/services";
import { Spinner, TextInput, Toaster } from "@sk/uis";
import { memo, useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
const validationSchema = yup.object({
  batchId: yup.string().trim().required("Please Enter Batch ID"),
});

const defaultFormValues = {
  message: "",
};

const RemarksModal = ({ show, callback }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = useCallback(
    async (data) => {
      const r = await OmsService.removeDummyBatch(data);
      setSubmitting(true);
      if (r.statusCode == 200) {
        Toaster.success("Removed Successfully");
        callback({ action: "submitted" });
        return;
      }
      Toaster.error("Unable to remove");
      callback({ action: "submitted" });
      setSubmitting(false);
    },
    [callback]
  );

  const triggerCloseModal = useCallback(() => {
    reset(defaultFormValues);
    callback({ status: "closed" });
  }, [callback, reset]);

  return (
    <Modal show={show} backdrop="static" onHide={triggerCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title className="mb-0 pb-0">Remove Batch ID</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TextInput
          name="batchId"
          error={errors?.batchId?.message}
          register={register}
          isMandatory={true}
          placeholder={`Enter Batch Id here`}
        />
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-sm btn-primary"
          onClick={handleSubmit(onSubmit)}
          disabled={submitting}
        >
          Submit {submitting && <Spinner isSmall={true} />}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(RemarksModal);
