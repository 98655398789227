import { useAttachAdditionalData } from "@sk/hooks";

import { CommonService, OmsService } from "@sk/services";

import { useCallback, useEffect, useRef, useState } from "react";

import { listView } from "../constantService";

import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";

import merge from "lodash/merge";

import debounce from "lodash/debounce";

import { produce } from "immer";

import {
  AppliedFilterLabel,
  PageInfo,
  PaginationSummary,
  SummaryCard,
  TextInput,
} from "@sk/uis";
import { set } from "date-fns";

import Table from "./components/Table";

import AdvanceFilterModal from "./modals/AdvanceFilterModal";

import ManageBatchProcessingModal from "./modals/ManageBatchProcessingModal";
import RemoveDummyBatchModal from "./modals/RemoveDummyBatchModal";

const getData = async (params) => {
  const r = await OmsService.getBatchProcessingList(params);
  return Array.isArray(r.resp)
    ? r.resp.map((x) => {
        x.warehouseLoading = true;
        x.userLoading = true;
        return x;
      })
    : [];
};

const getCount = async (params) => {
  try {
    const r = await OmsService.getBatchProcessingCount(params);
    return { count: r.statusCode == 200 && r.resp ? r.resp : 0 };
  } catch (error) {
    return new Promise((resolve) => resolve({ count: 0 }));
  }
};

const prepareFilterParams = (pagination = {}, filter = {}, sort = {}) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
  };
  const batchId = filter.batchId?.trim();

  if (batchId) {
    const batchRegex = { $regex: batchId, $options: "gi" };
    p.filter.$or = [{ _id: batchRegex }];
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }

  if (filter.warehouse) {
    p.filter.whId = filter.warehouse;
  }

  if (filter.taxType) {
    p.filter.taxType = filter.taxType.toUpperCase();
  }

  if (filter.createdAt.length) {
    p.filter.createdAt = {
      $gte: set(filter.createdAt[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.createdAt[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }
  return p;
};

const BatchProcessing = () => {
  const [data, setData] = useState([]);

  const [loadingData, setLoadingData] = useState(true);

  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [showManageModal, setShowManageModal] = useState(false);

  const [showRemoveBatchModal, setShowRemoveBatchModal] = useState(false);

  const [showAdvFilterModal, setShowAdvFilterModal] = useState(false);

  const [summaryCard, setSummaryCard] = useState([...listView.summaryData]);

  const [filterLabels, setFilterLabels] = useState([]);

  const [setAdditionalData, attachAllData] = useAttachAdditionalData();

  const filterDataRef = useRef({ ...listView.filterFormData });

  const paginationRef = useRef({ ...listView.pagination });

  const { register, getValues } = useForm({
    defaultValues: listView.filterFormData,
  });

  const router = useNavigate();

  useEffect(() => {
    init();
  }, [init]);

  const openAdvFilterModal = useCallback(() => setShowAdvFilterModal(true), []);

  const openManageModal = useCallback(() => setShowManageModal(true), []);

  const openRemoveBatchModal = useCallback(
    () => setShowRemoveBatchModal(true),
    []
  );

  const removeBatchCb = useCallback((action) => {
    if (action.type == "submit") {
      // Submit Form
    }
    setShowRemoveBatchModal(false);
  }, []);

  const getFilterParams = useCallback(() => {
    return prepareFilterParams(
      paginationRef.current,
      filterDataRef.current,
      {}
    );
  }, []);

  const prepareFilterLabels = useCallback(() => {
    const v = { ...(filterDataRef.current || {}) };
    delete v.batchId;

    const l = CommonService.prepareAppliedFilterLabels(listView.formLabels, v);
    setFilterLabels(l);
  }, []);

  const loadSummary = useCallback(async () => {
    // Setting loading all card to Loading State
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );
    // getting Filter Params
    const filterParams = getFilterParams();
    let promises = [];

    // Calling  Count API To Summary Card
    summaryCard.forEach((e) => {
      const params = merge({}, filterParams, { filter: e.filter }); // preparing params for Count
      // Removing unnecessary
      delete params.count;
      delete params.page;
      delete params.select;

      promises.push(getCount(params));
    });
    // Get Result of all Api and Setting count Value for Each Card
    const r = await Promise.all(promises);
    setSummaryCard(
      produce((draft) => {
        draft.forEach((x, k) => {
          x.loading = false;
          x.value = r[k].count || 0;
        });
      })
    );
  }, [getFilterParams, summaryCard]);

  const loadList = useCallback(async () => {
    setLoadingData(true);

    const p = getFilterParams();
    const d = await getData(p);
    setData(d);

    // To Attach Additional Data
    let tmp = [];
    setAdditionalData(d, listView.additionalTableDataConfig, (x) => {
      tmp.push(x);
      if (tmp.length == listView.additionalTableDataConfig.length) {
        setData([...attachAllData(d, tmp)]);
      }
    });
    setLoadingData(false);
  }, [attachAllData, getFilterParams, setAdditionalData]);

  const loadCount = useCallback(async () => {
    // for total records
    setLoadingTotalRecords(true);
    const p = getFilterParams();
    const c = await getCount(p);
    paginationRef.current.totalRecords = c.count;

    setLoadingTotalRecords(false);
  }, [getFilterParams]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const applyFilter = useCallback(
    async (isSummaryRequired = false) => {
      // Resetting pagination
      paginationRef.current = { ...listView.pagination };
      if (isSummaryRequired) {
        loadSummary();
      }

      loadList();
      loadCount();

      prepareFilterLabels();
    },
    [prepareFilterLabels, loadList, loadSummary, loadCount]
  );

  const filterCb = useCallback(
    debounce(() => {
      filterDataRef.current = {
        ...filterDataRef.current,
        batchId: getValues("batchId"),
      };
      applyFilter();
    }, 700),
    [applyFilter, getValues]
  );

  const init = useCallback(async () => {
    applyFilter(true);
  }, [applyFilter]);

  const manageBatchProcessingCb = useCallback(
    (action) => {
      if (action.status == "submitted") {
        init();
      }
      setShowManageModal(false);
    },
    [init]
  );
  const advFilterCb = useCallback(
    (data) => {
      if (data.status == "applied" || data.status == "reset") {
        filterDataRef.current = { ...filterDataRef.current, ...data.formData };

        applyFilter();
      }
      setShowAdvFilterModal(false);
    },
    [applyFilter]
  );

  return (
    <>
      <PageInfo
        title="Batch Processing"
        breadcrumbs={listView.breadcrumb}
        navigate={router}
      />

      <div className="row align-self-center mb-3">
        {summaryCard.map((e, k) => (
          <div className="col-2" key={k}>
            <SummaryCard
              value={e.value}
              title={e.label}
              loading={e.loading}
              valueColor={e.color}
              img={e.img}
              template={2}
            />
          </div>
        ))}
      </div>

      <div className="row align-items-center">
        <div className="col-6">
          <div className="row">
            {/* Brand Search Input */}
            <div className="col-8 align-self-center">
              <TextInput
                name="batchId"
                type="text"
                register={register}
                callback={filterCb}
                placeholder="Search By Batch ID"
              />
            </div>

            {/* Filter Button */}
            <div className="col-auto">
              <button
                className="btn app-filter-btn"
                type="button"
                onClick={openAdvFilterModal}
              >
                <i className="bi bi-funnel"></i> FILTER
              </button>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="col-auto ms-auto align-self-center">
          <button
            className="btn btn-primary btn-sm fs-val-md me-1"
            onClick={openManageModal}
          >
            Create Batch
          </button>

          <button
            className="btn btn-warning btn-sm fs-val-md me-1"
            onClick={openRemoveBatchModal}
          >
            Remove Dummy Batch
          </button>
        </div>
      </div>

      {/* Filter selected Label */}
      <div className="my-3">
        {filterLabels.length > 0 ? (
          <AppliedFilterLabel labels={filterLabels} />
        ) : null}
      </div>

      <div className="mb-3">
        <PaginationSummary
          paginationConfig={paginationRef.current}
          loadingTotalRecords={loadingTotalRecords}
        />
      </div>

      <Table
        data={data}
        loading={loadingData}
        paginationConfig={paginationRef.current}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
      />

      <AdvanceFilterModal
        callback={advFilterCb}
        show={showAdvFilterModal}
        formData={filterDataRef.current}
      />

      <ManageBatchProcessingModal
        show={showManageModal}
        callback={manageBatchProcessingCb}
      />

      <RemoveDummyBatchModal
        show={showRemoveBatchModal}
        callback={removeBatchCb}
      />
    </>
  );
};

export default BatchProcessing;
