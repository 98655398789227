/* eslint-disable jsx-a11y/label-has-associated-control */
import { OmsService } from "@sk/services";
import { memo, useCallback } from "react";
import { useForm } from "react-hook-form";
import { packScanInputView } from "../constantService";

const getData = async (params) => {
  const r = await OmsService.getInvoiceList(params);
  return Array.isArray(r.resp) ? r.resp : [];
};

const prepareParams = (val) => {
  return {
    select: "_id,deals.products,status",
    filter: {
      "deals.products.packages.packageNo": val,
    },
  };
};

const statusLabels = packScanInputView.statusLabels;

const PackScanInput = ({ dispatch, setShowBusyLoader }) => {
  const { register, getValues, setValue } = useForm();

  const getStatus = useCallback(
    (resp) => {
      const packageId = getValues("packageNo"); // Old Code return resp[0].status
      for (let invoice of resp) {
        for (let deal of invoice.deals) {
          for (let product of deal.products) {
            for (let packages of product.packages) {
              if (packages.packageNo === packageId) {
                return packages.status;
              }
            }
          }
        }
      }
      return null; // return null if no package is found with the target ID
    },
    [getValues]
  );

  const onScanInputChange = useCallback(
    (e) => {
      let val = e.target.value || "";
      val = val.toUpperCase().trim();
      setValue("packageNo", val);
    },
    [setValue]
  );

  const prepareDispatchPayload = useCallback(
    (status) => {
      return {
        status,
        label: statusLabels[status].label,
        bgColor: statusLabels[status].bgColor,
        packageNo: getValues("packageNo")?.trim() || "",
      };
    },
    [getValues]
  );

  const prepareAction = useCallback(
    (resp) => {
      let action = {
        type: "ADD_PACKAGE",
      };
      if (!resp.length) {
        action.payload = prepareDispatchPayload("NotFound");
        return action;
      }

      const status = getStatus(resp);

      if (status in statusLabels) {
        action.payload = prepareDispatchPayload(status);
        return action;
      }

      action.payload = prepareDispatchPayload("NotFound");

      return action;
    },
    [prepareDispatchPayload, getStatus]
  );

  const loadPackage = useCallback(async () => {
    setShowBusyLoader(true);
    const val = getValues("packageNo")?.trim();

    const params = prepareParams(val);

    const r = await getData(params);

    const action = prepareAction(r);

    dispatch(action);

    setValue("packageNo", "");

    setShowBusyLoader(false);
  }, [getValues, setShowBusyLoader, prepareAction, dispatch, setValue]);

  const onScanInputEnter = useCallback(
    (e) => {
      if (e.key == "Enter") {
        loadPackage();
      }
    },
    [loadPackage]
  );

  return (
    <>
      <div className="row  align-items-center">
        <div className="col-3 fs-val-md mb-3 me-auto">
          <label className="mb-1 fs-val-md">Package ID</label>
          <input
            className="form-control"
            type="text"
            autoComplete="off"
            placeholder="Scan here Package ID"
            {...register("packageNo", {
              onChange: onScanInputChange,
            })}
            onWheelCapture={(e) => e.target.blur()}
            onKeyDown={onScanInputEnter}
          />
        </div>
      </div>
    </>
  );
};

export default memo(PackScanInput);
