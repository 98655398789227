import { AuthService } from "@sk/services";
import { DatePickerInput, SelectInput } from "@sk/uis";
import { memo, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

const defaultFormData = {
  warehouse: "",
  status: "",
  createdAt: [],
  taxType: "",
};

const createdOnDateConfig = { mode: "range" };
const taxTypeOptions = [
  { label: "All", value: "" },
  { label: "Exempted", value: "Exempted" },
  { label: "Non-Exempted", value: "NonExempted" },
];

const statusOptions = [
  { label: "All", value: "" },
  { label: "Created", value: "Created" },
  { label: "Processing", value: "Processing" },
  { label: "Pending", value: "Pending" },
  { label: "Completed", value: "Completed" },
  { label: "Cancelled", value: "Cancelled" },
];

const WarehouseOptions = [
  {
    label: "All",
    value: "",
  },
  ...AuthService.getLoggedInEmpWh().map((x) => ({
    label: x.name,
    value: x._id,
  })),
];

const canvasStyle = { width: "30%" };

const AdvanceFilterModal = ({ show, callback, formData = {} }) => {
  // React Hook Form To Handle Advance Filter Label
  const { register, handleSubmit, reset, control, setValue } = useForm({
    defaultValues: defaultFormData,
  });

  useEffect(() => {
    if (show) {
      const keys = Object.keys(formData);
      if (keys.length > 0) {
        keys.forEach((x) => {
          setValue(x, formData[x]);
        });
      } else {
        // reset the form if there is no value selected earlier
        reset(defaultFormData);
      }
    }
  }, [show, setValue, formData, reset]);

  const onSubmit = (data) => {
    callback({ formData: data, status: "applied" });
  };

  // To Reset Filter Form Data
  const resetFormData = () => {
    reset(defaultFormData);
    callback({ status: "reset", formData: defaultFormData });
  };

  // To Close Filter Modal
  const closeModal = () => {
    callback({ status: "closed" });
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closeModal}
        placement="end"
        style={canvasStyle}
      >
        <Offcanvas.Header closeButton className="bg-light">
          {/* Modal Title */}
          <Offcanvas.Title>
            <div className="fs-val-lg"> Advance Filter </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-3  border-top">
          <>
            <div className="row">
              {/* Status */}
              <div className="col-12">
                <SelectInput
                  label="Warehouse"
                  register={register}
                  name="warehouse"
                  options={WarehouseOptions}
                />
              </div>
              <div className="col-6">
                <SelectInput
                  label="Status"
                  register={register}
                  name="status"
                  options={statusOptions}
                />
              </div>

              <div className="col-6">
                <SelectInput
                  label="Tax Type"
                  register={register}
                  name="taxType"
                  options={taxTypeOptions}
                />
              </div>

              <div className="col-12">
                <label className="fw-semibold mb-1 fs-val-md">Created On</label>
                <Controller
                  name="createdAt"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePickerInput
                      placeholder="Choose"
                      value={value}
                      inpChange={onChange}
                      config={createdOnDateConfig}
                    />
                  )}
                />
              </div>
            </div>
            {/*  Action Button */}
            <div className="text-end w-100 mt-4">
              <button
                className="btn me-1 btn-warning btn-sm"
                type="button"
                onClick={resetFormData}
              >
                Reset
              </button>
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Apply
              </button>
            </div>
          </>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default memo(AdvanceFilterModal);
