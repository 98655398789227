import { OmsService } from "@sk/services";
import { BusyLoader, PageInfo, Toaster } from "@sk/uis";
import produce from "immer";
import { useCallback, useState } from "react";
import {
  PackScanInput,
  PackScanSelectedBlock,
  PackScanStatusBlock,
  PackScanSummaryBlock,
} from "./components";
import PackScanMeasurementModal from "./modal/PackScanMeasurementModal";
import { packScanView } from "./constantService";
import { useNavigate } from "react-router-dom";

const summaryCardData = packScanView.summaryCard;

const defaultBoxDetails = packScanView.measurementBoxDetails;
const defaultPageData = {
  summaryData: [...summaryCardData],
  scannedPackages: [],
  selectedPackages: [],
};

function PackScan() {
  const [ShowMeasurement, setShowMeasurementModal] = useState(false);

  const [boxDetails, setBoxDetails] = useState({ ...defaultBoxDetails });

  const [showBusyLoader, setShowBusyLoader] = useState(false);

  const [data, setData] = useState({ ...defaultPageData });

  const navigate = useNavigate();

  const measurementModalCb = useCallback(({ status, data }) => {
    if (status == "submit") {
      setBoxDetails({ ...data, isDetailsVisible: true });
    }

    setShowMeasurementModal(false);
  }, []);

  const openMeasurementModal = useCallback(() => {
    setShowMeasurementModal(true);
  }, []);

  const addPackage = useCallback((payload) => {
    // Scanned Packages
    setData((prevData) => {
      const c = prevData.scannedPackages.find(
        (x) => x.packageNo == payload.packageNo
      );
      // Check is Exist or not
      if (c) {
        Toaster.error("Package already in list");
        return prevData;
      }
      return produce(prevData, (draft) => {
        // Add Count Summary Card
        draft.summaryData.forEach((x) => {
          if (x.status == payload.status) {
            x.value += 1;
            draft.summaryData[0].value += 1;
          }
        });

        // Adding Scanned Package Status
        draft.scannedPackages.unshift(payload);

        // Adding to Selected Packages
        if (payload.status == "New") {
          draft.selectedPackages.unshift(payload.packageNo);
        }
      });
    });
  }, []);

  const removePackage = useCallback((payload) => {
    let selectedPackagesLength;

    setData((prevData) => {
      const packageNo = prevData.selectedPackages[payload.index];

      // getting index
      const index = prevData.scannedPackages.findIndex(
        (x) => x.packageNo == packageNo
      );

      //  if Not Get Index
      if (index == -1) {
        Toaster.error("Unable to remove please try Later");
        return prevData;
      }

      return produce(prevData, (draft) => {
        // Removed from scanned packages
        draft.scannedPackages.splice(index, 1);

        // Removed From Selected Packages
        draft.selectedPackages.splice(payload.index, 1);

        // getting Length
        selectedPackagesLength = draft.selectedPackages.length;

        // Removed From Summary Card
        draft.summaryData.forEach((x) => {
          if (x.status == prevData.scannedPackages[index].status) {
            x.value -= 1;
            draft.summaryData[0].value -= 1;
          }
        });
      });
    });

    //  If No Selected Packages resetting box details
    if (!selectedPackagesLength) {
      setBoxDetails({ ...defaultBoxDetails });
    }
  }, []);

  const resetPageData = useCallback(() => {
    setData({ ...defaultPageData });
    setBoxDetails({ ...defaultBoxDetails });
  }, []);

  const prepareSubmitAllocationParams = useCallback(() => {
    let p = {
      packages: data.selectedPackages,
      boxDetails: { ...boxDetails },
    };
    // deleting
    delete p.boxDetails.isDetailsVisible;
    delete p.boxDetails.mode;

    return p;
  }, [boxDetails, data.selectedPackages]);

  const submitAllocation = useCallback(async () => {
    setShowBusyLoader(true);
    const p = prepareSubmitAllocationParams();

    const r = await OmsService.createScannedPackage(p);
    if (r.statusCode != 200) {
      Toaster.error(r.resp.message);
    }
    if (r.statusCode == 200) {
      Toaster.success(r.resp.message);
      resetPageData();
    }
    setShowBusyLoader(false);
  }, [prepareSubmitAllocationParams, resetPageData]);

  const dispatch = useCallback(
    (action) => {
      if (action.type == "ADD_PACKAGE") {
        addPackage(action.payload);
      }
      if (action.type == "REMOVE_PACKAGE") {
        removePackage(action.payload);
      }
      if (action.type == "SUBMIT_ALLOCATION") {
        submitAllocation();
      }
    },
    [submitAllocation, addPackage, removePackage]
  );

  return (
    <>
      <PageInfo
        title="INVOICE/PACKAGE PACK SCAN"
        breadcrumbs={packScanView.breadcrumb}
        navigate={navigate}
      />

      <PackScanInput
        dispatch={dispatch}
        setShowBusyLoader={setShowBusyLoader}
      />

      <PackScanSummaryBlock summaryCardData={data.summaryData} />

      <div className="row">
        <div className="col-12">
          <PackScanStatusBlock
            data={data.scannedPackages}
            key={data.scannedPackages.length}
          />
        </div>
        {data.selectedPackages.length ? (
          <div className="col-12">
            <PackScanSelectedBlock
              data={data.selectedPackages}
              dispatch={dispatch}
              openMeasurementModal={openMeasurementModal}
              boxDetails={boxDetails}
            />
          </div>
        ) : null}
      </div>

      <PackScanMeasurementModal
        show={ShowMeasurement}
        callback={measurementModalCb}
        modalData={boxDetails}
      />

      <BusyLoader show={showBusyLoader} />
    </>
  );
}

export default PackScan;
