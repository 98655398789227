import { Amount } from "@sk/uis";

const OrderDetailSummary = ({ summary }) => {
  return (
    <table className="table table-bordered table-sm table-striped">
      <thead>
        <tr>
          <th></th>
          <th className="text-center">Total</th>
          <th className="text-center">Allocated</th>
          <th className="text-center">Not Allocated</th>
          <th className="text-center">Packed</th>
          <th className="text-center">Not Packed</th>
          <th className="text-center">Shipped</th>
          <th className="text-center">Delivered</th>
          <th className="text-center">Cancelled</th>
          <th className="text-center">Returned</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Items</td>
          <td className="text-center">{summary.total.items}</td>
          <td className="text-center">{summary.allocated.items}</td>
          <td className="text-center">{summary.notAllocated.items}</td>
          <td className="text-center">{summary.packed.items}</td>
          <td className="text-center">{summary.notPacked.items}</td>
          <td className="text-center">{summary.shipped.items}</td>
          <td className="text-center">{summary.delivered.items}</td>
          <td className="text-center">{summary.cancelled.items}</td>
          <td className="text-center">{summary.returned.items}</td>
        </tr>
        <tr>
          <td>Qty</td>
          <td className="text-center">{summary.total.qty}</td>
          <td className="text-center">{summary.allocated.qty}</td>
          <td className="text-center">{summary.notAllocated.qty}</td>
          <td className="text-center">{summary.packed.qty}</td>
          <td className="text-center">{summary.notPacked.qty}</td>
          <td className="text-center">{summary.shipped.qty}</td>
          <td className="text-center">{summary.delivered.qty}</td>
          <td className="text-center">{summary.cancelled.qty}</td>
          <td className="text-center">{summary.returned.qty}</td>
        </tr>
        <tr>
          <td>Value</td>
          <td className="text-center">
            <Amount value={summary.total.value} />
          </td>
          <td className="text-center">
            <Amount value={summary.allocated.value} />
          </td>
          <td className="text-center">
            <Amount value={summary.notAllocated.value} />
          </td>
          <td className="text-center">
            <Amount value={summary.packed.value} />
          </td>
          <td className="text-center">
            <Amount value={summary.notPacked.value} />
          </td>
          <td className="text-center">
            <Amount value={summary.shipped.value} />
          </td>
          <td className="text-center">
            <Amount value={summary.delivered.value} />
          </td>
          <td className="text-center">
            <Amount value={summary.cancelled.value} />
          </td>
          <td className="text-center">
            <Amount value={summary.returned.value} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OrderDetailSummary;
