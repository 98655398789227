import { TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { memo, useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import AdvancedFilterModal from "../modals/AdvancedFilterModal";

const defaultFieldValues = {
  search: "",
  createdAt: "",
  orderId: "",
  batchId: "",
  active: "",
  invoiceCreated: "",
  codPaid: "",
  delivered: "",
  shipped: "",
  packed: "",
};

const FilterForm = ({ callback }) => {
  const { handleSubmit, getValues, register, setValue, reset } = useForm({
    defaultValues: { ...defaultFieldValues },
  });

  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const filterRef = useRef({});

  const onSubmit = (data) => {
    callback({ formData: data });
  };

  const viewAdvanceFilter = () => {
    setShowAdvancedFilter(true);
  };

  const closeModalCb = (d) => {
    if (d?.status && (d.status == "submit" || d.status == "reset")) {
      if (d.status == "reset") {
        reset();
      }
      onSubmit({ ...getValues(), ...d.formData });
    }
    setShowAdvancedFilter(false);
  };

  const onSearch = useCallback(
    debounce((ev) => {
      callback({ formData: { ...getValues(), ...filterRef.current } });
    }, 700),
    []
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row">
          {/* Category */}
          <div className="col-8">
            <TextInput
              register={register}
              placeholder="Search For Order Id / Batch Id"
              isMandatory={false}
              name="search"
              callback={onSearch}
            />
          </div>

          {/* Filter Button */}
          <div className="col-auto">
            <button
              className="btn app-filter-btn"
              type="button"
              onClick={viewAdvanceFilter}
            >
              <i className="bi bi-funnel"></i> FILTER
            </button>
          </div>
        </div>
      </form>

      <AdvancedFilterModal
        show={showAdvancedFilter}
        closeModal={closeModalCb}
        values={defaultFieldValues}
        filterData={filterRef}
      />
    </>
  );
};

export default memo(FilterForm);
