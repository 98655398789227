import { AppCard, DateFormatter, HighlightText, KeyVal } from "@sk/uis";
import { memo, useMemo } from "react";

const BasicDetails = ({ batchDetails, warehouseDetails }) => {
  const address = useMemo(() => {
    return `${warehouseDetails.doorNo}, ${warehouseDetails.street}, ${warehouseDetails.landmark},${warehouseDetails.city}, ${warehouseDetails.district}, ${warehouseDetails.state}, ${warehouseDetails.pincode} `;
  }, [warehouseDetails]);
  return (
    <AppCard noShadow={true}>
      <div className="row">
        {/* Batch Details */}
        <div className="col-6 border-end">
          <div className="fw-semibold fs-val-lg mb-2"> Batch Details </div>
          <KeyVal label="ID">
            <span className="fs-val-md fw-semibold">#{batchDetails.id}</span>
          </KeyVal>
          <KeyVal label="Status">
            <span className="fw-semibold">
              <HighlightText isBadge={true} status={batchDetails.status} />
            </span>
          </KeyVal>

          <KeyVal label="Date">
            <span className="fs-val-md fw-semibold">
              <DateFormatter date={batchDetails.date} />
            </span>
          </KeyVal>

          <KeyVal label="Created By">
            <span className="fs-val-md fw-semibold">
              {batchDetails.createdBy}
            </span>
          </KeyVal>
        </div>

        {/* Warehouse Details */}
        <div className="col-6 ps-4">
          <div className="fw-semibold fs-val-lg mb-2">Warehouse Details</div>
          <KeyVal label="Name">
            <span className="fs-val-md fw-semibold">
              {warehouseDetails.name}
            </span>
          </KeyVal>
          <KeyVal label="Address">
            <span className="fs-val-md fw-semibold">{address}</span>
          </KeyVal>
        </div>
      </div>
    </AppCard>
  );
};

export default memo(BasicDetails);
