import React from "react";
import { Amount, AppCard, TableHeader } from "@sk/uis";

const headers = [
  { label: "Total Orders", key: "total", width: "20%", isCentered: true },
  { label: "Open Orders", key: "open", width: "20%", isCentered: true },
  { label: "Invoiced Orders", key: "invoiced", width: "20%", isCentered: true },
  { label: "Shipped", key: "shipped", width: "20%", isCentered: true },
  { label: "Delivered", key: "delivered", width: "20%", isCentered: true },
];

const OrderReserveSummary = ({ summaryData, loading }) => {
  const renderLoadingPlaceholder = () => (
    <div className="placeholder-glow">
      <span className="placeholder col-8"></span>
    </div>
  );

  return (
    <AppCard title="Summary">
      <div className="tbl-scroll-container custom-scrollbar thin-scrollbar">
        <table className="table table-sm table-bordered mb-0 bg-white">
          <TableHeader data={headers} fw="medium" />
          <tbody>
            <tr>
              {/* Order Count Row */}
              {headers.map((header) => (
                <td key={header.key} className="text-center">
                  <div className="d-flex flex-column">
                    <span className="fs-val-md fw-medium">
                      {loading
                        ? renderLoadingPlaceholder()
                        : summaryData[header.key]?.count || 0}
                    </span>
                    <div className="text-muted fs-val-sm">Orders</div>
                  </div>
                </td>
              ))}
            </tr>
            <tr>
              {/* Order Value Row */}
              {headers.map((header) => (
                <td key={header.key} className="text-center">
                  <div className="d-flex flex-column">
                    <span
                      className={`fs-val-md fw-medium ${getValueColor(
                        header.key
                      )}`}
                    >
                      {loading ? (
                        renderLoadingPlaceholder()
                      ) : (
                        <Amount value={summaryData[header.key]?.value || 0} />
                      )}
                    </span>
                    <div className="text-muted fs-val-sm">Value</div>
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </AppCard>
  );
};

const getValueColor = (key) => {
  switch (key) {
    case "total":
      return "text-dark";
    case "open":
      return "text-primary";
    case "invoiced":
      return "text-info";
    case "shipped":
    case "delivered":
      return "text-success";
    default:
      return "text-dark";
  }
};

export default OrderReserveSummary;
