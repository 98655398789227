import React from "react";
import { Modal, Button } from "react-bootstrap";

const ResetConfirmationModal = ({ show, onHide, onConfirm, modalData }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Reset Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>{`Are you sure you want to reset "${modalData?.title}" - ${modalData?.value} ?`}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => onConfirm(modalData?.title)}>
          Reset
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResetConfirmationModal;
