import React, { useCallback, useEffect, useRef, useState } from "react";
import { NavService, OmsService } from "@sk/services";
import { AppCard, PageInfo, Toaster } from "@sk/uis";
import OrderReserveTable from "./OrderReserveTable";
import OrderReserveSummary from "./OrderReserveSummary";
import OrderReserveAgeing from "./OrderReserveAgeing";
import { sub } from "date-fns";
import { ReservationTabs } from "@sk/features";
import { useNavigate } from "react-router-dom";
const breadcrumbs = [
  {
    name: "Home",
    link: "/auth/init",
  },
  {
    name: "Order Reserve",
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getData = async (params) => {
  const response = await OmsService.getOrderList(params);
  return Array.isArray(response.resp) ? response.resp : [];
};

const getCount = async (params) => {
  const response = await OmsService.getOrderCount(params);
  return response.resp || 0;
};

const prepareFilterParams = (pagination) => {
  return {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      createdAt: {
        $gte: sub(new Date(), { days: 60 }),
      },
    },
  };
};

const OrderReserve = () => {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);
  const [summaryData, setSummaryData] = useState({
    total: { count: 0, value: 0 },
    open: { count: 0, value: 0 },
    invoiced: { count: 0, value: 0 },
    shipped: { count: 0, value: 0 },
    delivered: { count: 0, value: 0 },
  });
  const [ageingData, setAgeingData] = useState({
    "0-7": { count: 0, value: 0 },
    "8-15": { count: 0, value: 0 },
    "16-30": { count: 0, value: 0 },
    "30+": { count: 0, value: 0 },
  });
  const [loadingSummary, setLoadingSummary] = useState(true);
  const [loadingAgeing, setLoadingAgeing] = useState(true);

  const paginationRef = useRef({ ...defaultPaginationConfig });

  const loadOrderData = useCallback(async () => {
    setLoadingData(true);
    try {
      const params = prepareFilterParams(paginationRef.current);
      const orders = await getData(params);
      setOrderData(orders);
    } catch (error) {
      Toaster.error("Failed to load order data");
    } finally {
      setLoadingData(false);
    }
  }, []);

  const loadTotalRecords = useCallback(async () => {
    setLoadingTotalRecords(true);
    try {
      const count = await getCount(prepareFilterParams(paginationRef.current));
      paginationRef.current.totalRecords = count;
    } catch (error) {
      Toaster.error("Failed to load total records");
    } finally {
      setLoadingTotalRecords(false);
    }
  }, []);

  const loadSummaryData = useCallback(async () => {
    setLoadingSummary(true);
    try {
      // Simulate fetching summary data
      const summary = {
        total: { count: 100, value: 1000000 },
        open: { count: 40, value: 400000 },
        invoiced: { count: 30, value: 300000 },
        shipped: { count: 20, value: 200000 },
        delivered: { count: 10, value: 100000 },
      };
      setSummaryData(summary);
    } catch (error) {
      Toaster.error("Failed to load summary data");
    } finally {
      setLoadingSummary(false);
    }
  }, []);

  const loadAgeingData = useCallback(async () => {
    setLoadingAgeing(true);
    try {
      // Simulate fetching ageing data
      const ageing = {
        "0-7": { count: 25, value: 250000 },
        "8-15": { count: 15, value: 150000 },
        "16-30": { count: 10, value: 100000 },
        "30+": { count: 5, value: 50000 },
      };
      setAgeingData(ageing);
    } catch (error) {
      Toaster.error("Failed to load ageing data");
    } finally {
      setLoadingAgeing(false);
    }
  }, []);

  const handlePaginationChange = useCallback(
    (paginationData) => {
      paginationRef.current = {
        ...paginationRef.current,
        ...paginationData,
      };
      loadOrderData();
    },
    [loadOrderData]
  );

  useEffect(() => {
    loadOrderData();
    loadTotalRecords();
    loadSummaryData();
    loadAgeingData();
  }, [loadOrderData, loadTotalRecords, loadSummaryData, loadAgeingData]);

  const handleTabChange = (tab) => {
    NavService.to(navigate, tab.redirect.path);
  };

  return (
    <>
      <PageInfo title="Order Reserve" breadcrumbs={breadcrumbs} />

      <div className="mb-2">
        <ReservationTabs tabKey={"orders"} onTabChange={handleTabChange} />
      </div>

      <div className="row">
        <div className="col-6">
          <OrderReserveSummary
            summaryData={summaryData}
            loading={loadingSummary}
          />
        </div>
        <div className="col-6">
          <OrderReserveAgeing ageingData={ageingData} loading={loadingAgeing} />
        </div>
      </div>

      <AppCard>
        <OrderReserveTable
          data={orderData}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={handlePaginationChange}
          loadingTotalRecords={loadingTotalRecords}
          callback={(e) => {
            if (e.action === "view") {
              // Handle view action
              console.log("View order:", e.id);
            }
          }}
        />
      </AppCard>
    </>
  );
};

export default OrderReserve;
