import { Amount } from "@sk/uis";

const OrderStatusSummary = ({ summary }) => {
  return (
    <table className="table table-bordered table-sm">
      <thead className="thead-light">
        <tr>
          <th>Status</th>
          <th>Items</th>
          <th>Qty</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Total</td>
          <td>{summary.total.items}</td>
          <td>{summary.total.qty}</td>
          <td>
            <Amount value={summary.total.value} />
          </td>
        </tr>
        <tr>
          <td>Allocated</td>
          <td>{summary.allocated.items}</td>
          <td>{summary.allocated.qty}</td>
          <td>
            <Amount value={summary.allocated.value} />
          </td>
        </tr>
        <tr>
          <td>Not Allocated</td>
          <td>{summary.notAllocated.items}</td>
          <td>{summary.notAllocated.qty}</td>
          <td>
            <Amount value={summary.notAllocated.value} />
          </td>
        </tr>
        <tr>
          <td>Packed</td>
          <td>{summary.packed.items}</td>
          <td>{summary.packed.qty}</td>
          <td>
            <Amount value={summary.packed.value} />
          </td>
        </tr>
        <tr>
          <td>Not Packed</td>
          <td>{summary.notPacked.items}</td>
          <td>{summary.notPacked.qty}</td>
          <td>
            <Amount value={summary.notPacked.value} />
          </td>
        </tr>
        <tr>
          <td>Shipped</td>
          <td>{summary.shipped.items}</td>
          <td>{summary.shipped.qty}</td>
          <td>
            <Amount value={summary.shipped.value} />
          </td>
        </tr>
        <tr>
          <td>Delivered</td>
          <td>{summary.delivered.items}</td>
          <td>{summary.delivered.qty}</td>
          <td>
            <Amount value={summary.delivered.value} />
          </td>
        </tr>
        <tr>
          <td>Cancelled</td>
          <td>{summary.cancelled.items}</td>
          <td>{summary.cancelled.qty}</td>
          <td>
            <Amount value={summary.cancelled.value} />
          </td>
        </tr>
        <tr>
          <td>Returned</td>
          <td>{summary.returned.items}</td>
          <td>{summary.returned.qty}</td>
          <td>
            <Amount value={summary.returned.value} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OrderStatusSummary;
