import { PageNotFound, ProtectedRoute } from "@sk/uis";
import { memo } from "react";
import { createHashRouter, Navigate, RouterProvider } from "react-router-dom";
import BatchProcessing from "./pages/batch-processing/list/BatchProcessing";
import ViewBatch from "./pages/batch-processing/view/ViewBatch";
import BulkOrderImportTracker from "./pages/bulk-order-import-tracker/list/BulkOrderImportTracker";
import Layout from "./pages/Layout";
import OrderDetails from "./pages/orders/details/OrderDetails";
import OrdersList from "./pages/orders/list/OrdersList";
import PackScan from "./pages/pack-scan/PackScan";
import OrderReserve from "./pages/reserve/order-reserve/OrderReserve";
import PurchaseOrderList from "./pages/reserve/purchase-order/PurchaseOrderList";
import ScanInvoiceProforma from "./pages/sca-invoice-proforma/ScanInvoiceProforma";
import ViewInvoice from "./pages/sca-invoice-proforma/view/ViewInvoice";

const router = createHashRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    children: [
      {
        path: "oms",
        element: <Layout />,
        children: [
          {
            path: "",
            element: <Navigate to="/oms/pack-scan"></Navigate>,
          },
          {
            path: "/oms/pack-scan",
            element: (
              <ProtectedRoute>
                <PackScan />
              </ProtectedRoute>
            ),
          },
          {
            path: "/oms/batch-processing",
            element: (
              <ProtectedRoute>
                <BatchProcessing />
              </ProtectedRoute>
            ),
          },
          {
            path: "/oms/view-batch",
            element: (
              <ProtectedRoute>
                <ViewBatch />
              </ProtectedRoute>
            ),
          },
          {
            path: "/oms/scan-invoice-proforma",
            element: (
              <ProtectedRoute>
                <ScanInvoiceProforma />
              </ProtectedRoute>
            ),
          },

          {
            path: "/oms/view-invoice",
            element: (
              <ProtectedRoute>
                <ViewInvoice />
              </ProtectedRoute>
            ),
          },
          {
            path: "/oms/bulk-order-tracker",
            element: (
              <ProtectedRoute>
                <BulkOrderImportTracker />
              </ProtectedRoute>
            ),
          },
          {
            path: "/oms/reserve/order-reserve",
            element: (
              <ProtectedRoute>
                <OrderReserve />
              </ProtectedRoute>
            ),
          },
          {
            path: "/oms/reserve/purchase-order",
            element: (
              <ProtectedRoute>
                <PurchaseOrderList />
              </ProtectedRoute>
            ),
          },
          {
            path: "/oms/orders",
            element: (
              <ProtectedRoute>
                <OrdersList />
              </ProtectedRoute>
            ),
          },
          {
            path: "/oms/orders/details",
            element: (
              <ProtectedRoute>
                <OrderDetails />
              </ProtectedRoute>
            ),
          },
          // {
          //   path: "/oms/invoice/view",
          //   element: (
          //     <ProtectedRoute>
          //       <InvoiceView />
          //     </ProtectedRoute>
          //   ),
          // },
        ],
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default memo(Router);
