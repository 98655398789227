import { ReservationTabs } from "@sk/features";
import { DashboardService, NavService, appConfigs } from "@sk/services";
import { AppCard, PageInfo, PageLoader, Toaster } from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PurchaseOrderFilter from "./components/PurchaseOrderFilter";
import PurchaseOrderSummary from "./components/PurchaseOrderSummary";
import PurchaseOrderTable from "./components/PurchaseOrderTable";
import { defaultFilterData, defaultPaginationConfig } from "./constants";

const getData = async (params) => {
  const response = await DashboardService.fetchStockAnalytics(params);
  return {
    data: Array.isArray(response.resp) ? response.resp : [],
    raw: response.raw,
  };
};

const getCount = async (params) => {
  const p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;
  const response = await DashboardService.fetchStockAnalytics(p);
  return response?.resp?.[0]?.totalDocs || 0;
};

const prepareFilterParams = (filterData, pagination, sort) => {
  const params = DashboardService.prepareStockNotAllocatedSummaryParams(
    filterData,
    "getUnallocatedStockProductWise"
  );

  const search = (filterData.search || "").trim();
  if (search) {
    params.filter.$or = [
      { "productInfo.name": { $regex: search, $options: "i" } },
      { productId: search },
    ];
  }

  if (pagination) {
    params.page = pagination.activePage;
    params.count = pagination.rowsPerPage;
  }

  if (sort) {
    params.sort = {
      [sort.key]: sort.value === "asc" ? 1 : -1,
    };
  }

  return params;
};

const PurchaseOrderList = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [batchDetails, setBatchDetails] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);

  const filterRef = useRef({ ...defaultFilterData });
  const paginationRef = useRef({ ...defaultPaginationConfig });
  const sortDataRef = useRef({ key: "recentOrderDate", value: "desc" });

  const loadList = useCallback(async () => {
    setLoading(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(
        filterRef.current,
        paginationRef.current,
        sortDataRef.current
      )
    );
    if (r.raw?.code == appConfigs.AJAX_REQ_CANCEL) {
      return;
    }
    setData(r.data);
    setLoading(false);
  }, []);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    loadList();

    setRecords({ loading: true, value: 0 });
    const count = await getCount(prepareFilterParams(filterRef.current));
    setRecords({ loading: false, value: count });
    paginationRef.current.totalRecords = count;
  }, [loadList]);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterCb = useCallback(
    (data) => {
      filterRef.current = {
        ...filterRef.current,
        ...data.formData,
      };
      applyFilter();
    },
    [applyFilter]
  );

  const handleFilterClick = useCallback(
    (type, value) => {
      const formData = {
        ...filterRef.current,
        [type]: [
          {
            label: value.name,
            value: {
              _id: value._id || "",
              name: value.name,
            },
          },
        ],
      };
      filterRef.current = formData;
      applyFilter();
    },
    [applyFilter]
  );

  const handleTabChange = useCallback((tab) => {
    NavService.to(navigate, tab.redirect.path);
  }, []);

  const handleProductSelect = useCallback((product, checked) => {
    setSelectedProducts((prev) => {
      if (checked) {
        return [...prev, product];
      }
      return prev.filter((p) => p.productId !== product.productId);
    });
  }, []);

  const handleCreatePO = useCallback(() => {
    console.log("Creating PO for:", selectedProducts);
  }, [selectedProducts]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await DashboardService.getBatchId();
        setBatchDetails(res?.resp);
        filterRef.current = {
          ...filterRef.current,
          batchId: res?.resp?.batchId,
        };
        await applyFilter();
      } catch (error) {
        console.error("Error fetching batch details:", error);
        Toaster.error("Error fetching batch details");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [applyFilter]);

  if (!batchDetails.batchId) {
    return <PageLoader />;
  }

  return (
    <>
      <PageInfo
        title="Purchase Orders"
        breadcrumbs={[{ name: "Home", link: "/" }, { name: "Purchase Orders" }]}
      />

      <div className="mb-3">
        <ReservationTabs tabKey={"create_po"} onTabChange={handleTabChange} />
      </div>

      <PurchaseOrderFilter filterData={filterRef.current} callback={filterCb} />

      <PurchaseOrderSummary />

      <AppCard>
        <PurchaseOrderTable
          data={data}
          loading={loading}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={records.loading}
          onFilterClick={handleFilterClick}
          selectedProducts={selectedProducts}
          onProductSelect={handleProductSelect}
        />
      </AppCard>

      {selectedProducts.length > 0 && (
        <div
          className="position-sticky bottom-0 start-0 end-0 bg-white border-top py-3 px-4 shadow-sm"
          style={{ zIndex: 1000 }}
        >
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col">
                <span className="fw-medium">
                  {selectedProducts.length} Product
                  {selectedProducts.length !== 1 ? "s" : ""} Selected
                </span>
              </div>
              <div className="col-auto">
                <button className="btn btn-primary" onClick={handleCreatePO}>
                  Create Purchase Order
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PurchaseOrderList;
