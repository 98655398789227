/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from "@hookform/resolvers/yup";
import { CommonService, ProductService } from "@sk/services";
import {
  AppTitle,
  InputErrorMsg,
  KeyVal,
  SelectInput,
  TextInput,
  Toaster,
} from "@sk/uis";

import { debounce } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

const style = {
  modalHeaderStyle: {
    backgroundColor: "#e4edff",
  },
};

const modeOptions = [
  { label: "Manual", value: "manual" },
  { label: "Scan Barcode", value: "barcode" },
];

const defaultFormValues = {
  mode: "manual",
  length: "",
  width: "",
  height: "",
  weight: "",
};

const validationSchema = yup.object({
  length: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .min(0.1, "Cannot Be less than 0.1 cm")
    .required(" Length is Required"),

  width: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))

    .min(0.1, "Cannot Be less than 0.1 cm")
    .required("Width is Required"),

  height: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .min(0.1, "Cannot Be less than 0.1 cm")
    .required("Height is Required"),

  weight: yup
    .number()
    .nullable()
    .transform((v) => (isNaN(v) ? null : v))
    .min(0.1, "Cannot Be less than 0.1 cm")
    .required("Weight is Required"),
});

const getBoxBarcodeDetails = async (params) => {
  const r = await ProductService.getBoxBarcodeDetails(params);
  return r;
};

const PackScanMeasurementModal = ({ show = true, callback, modalData }) => {
  const {
    register,
    control,
    reset,
    clearErrors,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });

  const [submitting, setSubmitting] = useState(false);

  const closeTriggerModal = () => {
    callback({ status: "close" });
  };

  const mode = useWatch({
    name: "mode",
    control,
  });

  const boxDetails = useWatch({
    name: ["length", "width", "height"],
    control,
  });

  useEffect(() => {
    if (show && modalData.isDetailsVisible) {
      Object.keys(modalData).forEach((key) => {
        if (key in defaultFormValues) {
          setValue(key, modalData[key]);
        }
      });
    } else {
      reset(defaultFormValues);
    }
  }, [show, modalData, setValue, reset]);

  const onManualSubmit = useCallback(
    (data) => {
      callback({ status: "submit", data });
    },
    [callback]
  );

  const onBarcodeSubmit = useCallback(async () => {
    try {
      const values = getValues();

      await validationSchema.validate(values);

      callback({ status: "submit", data: getValues() });
    } catch (error) {
      Toaster.error(error.message);
    }
  }, [getValues, callback]);

  const onModeChange = useCallback(
    (e) => {
      clearErrors();
      reset({ ...defaultFormValues, mode: getValues("mode") });
    },
    [clearErrors, reset, getValues]
  );

  const onNumberInputChange = useCallback(
    debounce((e) => {
      let val = e.target.value * 1;
      if (val <= 0) {
        val = "";
      }
      if (val) {
        val = CommonService.roundedByDecimalPlace(val, 2);
      }

      e.target.value = val;
    }, 800),
    []
  );

  const OnBarcodeChange = useCallback(
    debounce((e) => {
      e.target.value = e.target.value?.trim();
    }, 700),
    []
  );

  const prepareMeasurementValuesFromBarcode = useCallback(
    (boxDetails) => {
      Object.keys(modalData).forEach((key) => {
        if (key in boxDetails) {
          setValue(key, boxDetails[key]);
        } else if (key == "width") {
          setValue(key, boxDetails["breadth"]);
        } else if (key == "weight") {
          setValue(key, boxDetails["box_weight"]);
        }
      });
    },

    [setValue, modalData]
  );

  const loadBoxDetailsFromBarcode = useCallback(async () => {
    setSubmitting(true);
    let p = {
      id: getValues("barcode"),
    };
    const r = await getBoxBarcodeDetails(p);

    if (r.statusCode != 200) {
      Toaster.error(r.resp.message);
      setSubmitting(false);
      return;
    }

    setSubmitting(false);
    prepareMeasurementValuesFromBarcode(r.resp.boxDetails);
  }, [prepareMeasurementValuesFromBarcode, getValues]);

  const onBarcodeEnter = useCallback(
    (e) => {
      if (e.key == "Enter") {
        loadBoxDetailsFromBarcode();
      }
    },
    [loadBoxDetailsFromBarcode]
  );

  return (
    <Modal onHide={closeTriggerModal} size="lg" show={show}>
      <Modal.Header
        closeButton
        closeVariant="white"
        style={style.modalHeaderStyle}
      >
        <AppTitle
          title={"Enter Measurement Details for Box"}
          className="fs-val-lg text-dark px-2"
        />
      </Modal.Header>
      <Modal.Body className="p-4">
        <div className="row ">
          <div className="col-6 mb-2">
            <SelectInput
              name="mode"
              register={register}
              label="Mode for Measurement of Box"
              options={modeOptions}
              isMandatory={true}
              callback={onModeChange}
            />
          </div>

          <div className="col-6 mb-2">
            <TextInput
              type="number"
              isMandatory={true}
              name="weight"
              label="Box Dead Weight (Kg)"
              placeholder="Enter weight here"
              register={register}
              error={errors?.weight?.message}
              callback={onNumberInputChange}
            />
          </div>

          {mode == "barcode" && (
            <div className="col-6 mb-2">
              {/* <TextInput
                isMandatory={true}
                name="barcode"
                label="Scan Box Barcode"
                placeholder="Enter Barcode here"
                register={register}
                callback={OnBarcodeChange}
              /> */}

              <label className="mb-1 fs-val-md">Scan Box Barcode</label>
              <input
                className="form-control mb-1"
                type="text"
                placeholder="Enter Barcode here"
                {...register("barcode", {
                  onChange: OnBarcodeChange,
                })}
                onWheelCapture={(e) => e.target.blur()}
                onKeyDown={onBarcodeEnter}
              />
              <InputErrorMsg msg={errors?.barcode?.message} />
            </div>
          )}

          {mode == "manual" && (
            <>
              <div className="col-4 mb-2">
                <TextInput
                  type="number"
                  isMandatory={true}
                  name="length"
                  label="Length (cm)"
                  placeholder="Enter length here"
                  register={register}
                  error={errors?.length?.message}
                  callback={onNumberInputChange}
                />
              </div>
              <div className="col-4 mb-2">
                <TextInput
                  type="number"
                  isMandatory={true}
                  name="width"
                  label="Width (cm)"
                  placeholder="Enter width here"
                  error={errors?.width?.message}
                  register={register}
                  callback={onNumberInputChange}
                />
              </div>
              <div className="col-4 mb-2">
                <TextInput
                  type="number"
                  isMandatory={true}
                  name="height"
                  error={errors?.height?.message}
                  label="Height (cm)"
                  placeholder="Enter height here"
                  register={register}
                  callback={onNumberInputChange}
                />
              </div>
            </>
          )}
        </div>

        <div className="row">
          {mode == "barcode" && (
            <div className="row mb-2">
              <div className="col-12 fs-val-lg fw-semibold text-primary mb-2">
                Box Measurement Details
              </div>
              <div className="col-4 mb-2">
                <KeyVal template="col" label="Length (cm)">
                  {boxDetails[0] || "N/A"}
                </KeyVal>
              </div>
              <div className="col-4 mb-2">
                <KeyVal template="col" label="Width (cm)">
                  {boxDetails[1] || "N/A"}
                </KeyVal>
              </div>
              <div className="col-4 mb-2">
                <KeyVal template="col" label="Height (cm)">
                  {boxDetails[2] || "N/A"}
                </KeyVal>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 text-end">
          <button
            className="btn btn-primary fs-val-md"
            onClick={
              mode == "manual" ? handleSubmit(onManualSubmit) : onBarcodeSubmit
            }
            disabled={submitting}
          >
            Submit
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(PackScanMeasurementModal);
