import React, { memo, useMemo } from "react";
import PackScanSummaryCard from "./PackScanSummaryCard";

const PackScanSummaryBlock = ({ summaryCardData }) => {
  const total = useMemo(() => summaryCardData[0], [summaryCardData]);
  return (
    <div className="row align-items-center mb-2">
      {summaryCardData.slice(1).map((e, k) => (
        <div className="col-1" key={k}>
          <PackScanSummaryCard
            value={e.value}
            label={e.label}
            valueColor={e.color}
            bgColor={e.bgColor}
          />
        </div>
      ))}

      <div className="col-auto fs-val-lg">=</div>

      <div className="col-1" key={total.value}>
        <PackScanSummaryCard
          value={total.value}
          label={total.label}
          valueColor={total.color}
          bgColor={total.bgColor}
        />
      </div>
    </div>
  );
};

export default memo(PackScanSummaryBlock);
